<div class="container-fluid m-0 py-5">
    @if (!trackingLevel) {
        <h1 class="text-center my-5">Statistiques de récompenses de vos opérations</h1>
    }
    @if (statsForm) {
        <div class="row">
            <div class="col col-md-10 mx-auto">

                <form [formGroup]="statsForm">
                    <div class="row justify-content-center gy-2">
                        @if (!trackingLevel) {
                            <mat-form-field appearance="fill" class="col-sm-6 col-lg-3">
                                <mat-label>Operation</mat-label>
                                <mat-select formControlName="operationId">
                                    @for (item of operations; track item.id) {
                                        <mat-option [value]="item.id">
                                            {{item.name}}
                                        </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        } @else {
                            <mat-form-field appearance="fill" class="col-sm-6 col-lg-3">
                                <mat-label>Sélectionner un période prédéfinie</mat-label>
                                <mat-select formControlName="preDefinedPeriod">
                                    <mat-option value="">Depuis le début de l’opération jusqu’à ce jour</mat-option>
                                    <mat-option [value]="'custom'">du ….au….</mat-option>
                                </mat-select>
                            </mat-form-field>
                        }

                        <mat-form-field appearance="fill" class="col-sm-6 col-lg-3">
                            <mat-label>Date début</mat-label>
                            <input matInput [matDatepicker]="start" formControlName="start" id="start">
                            <mat-datepicker-toggle matIconSuffix [for]="start" />
                            <mat-datepicker #start />
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="col-sm-6 col-lg-3">
                            <mat-label>Date fin</mat-label>
                            <input matInput [matDatepicker]="end" formControlName="end" id="end">
                            <mat-datepicker-toggle matIconSuffix [for]="end" />
                            <mat-datepicker #end />
                        </mat-form-field>
                    </div>

                    <div class="row justify-content-center text-center gy-2">
                        @if (expiredOperation && !trackingLevel) {
                            <mat-checkbox  formControlName="useAllOperations"
                                           id="disabledOperation"
                                           class="col-sm-12">
                                Inclure les opérations inactives
                            </mat-checkbox>
                        }
                        <mat-checkbox  formControlName="anonymizeConsumersData"
                                       id="anonymize"
                                       class="col-sm-12">
                            Anonymiser les données bénéficiaires
                        </mat-checkbox>
                    </div>

                    <div class="my-1 row text-center justify-content-center">
                        <div class="col">
                            <button (click)="getStats()" class="btn btn-validate m-3 px-4 py-2" data-cy="button-research" style="min-width: 130px">
                                @if (!loading) {Recherche}
                                @else {
                                    <em class="fas fa-spinner fa-spin"></em>
                                }
                            </button>
                            @if (!trackingLevel) {
                                <button (click)="resetForm()" class="btn btn-black m-3 px-4 py-2">
                                    Reset
                                </button>
                            }
                        </div>
                    </div>

                    @if (!trackingLevel && dataSource?.data.length > 0 && !loading) {
                        <div class="row">
                            <div class="col mx-auto text-center">
                                <button (click)="download()" class="btn btn-validate m-3 px-4 py-2">
                                    <i aria-hidden="true" class="fas fa-file-excel mx-2"></i> Export Excel
                                </button>
                            </div>
                        </div>
                    }
                </form>
            </div>
        </div>
    }


    <section [hidden]="!(dataSource?.data.length > 0)" class="my-5">
        <div class="responsive-table mt-5" [hidden]="!!trackingLevel">
            <div class="responsive-table-body">
                <table #sort1="matSort" [dataSource]="dataSource" mat-table matSort>
                    @for (col of displayedColumns; track col; let index = $index) {
                        <ng-container [matColumnDef]="col">
                            <th *matHeaderCellDef mat-header-cell mat-sort-header>{{colNames[index]}}</th>
                            <td *matCellDef="let element" mat-cell>{{element[col]}}</td>
                        </ng-container>
                    }

                    <tr *matHeaderRowDef="columnsToDisplay" mat-header-row></tr>
                    <tr *matRowDef="let row; columns: columnsToDisplay;" mat-row></tr>
                </table>
            </div>
            <mat-paginator #paginator1 [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons />
        </div>

        @if (trackingLevel && operation && formattedStat.length && budget !== undefined) {
            <app-tracking (downloadEvent)="downLoadFromTracking($event)"
                          [budget]="budget"
                          [formattedStat]="formattedStat"
                          [operation]="operation"
                          [showBudgetData]="showBudgetData"
                          [trackingLevel]="trackingLevel" />
        }
    </section>

    @if (dataSource?.data.length === 0) {
        <div class="text-center my-5">
            Pas de Statistiques de récompenses trouvé
        </div>
    }
</div>
