<div class="container-fluid m-0 py-3 py-md-5 px-5">
    @if (loading) {
        <div class="text-center py-5">
            <em [style.font-size]="'30px'" aria-hidden="true" class="fas fa-spinner fa-spin"></em>
        </div>
    } @else {
        <div class="my-5">
            @if (createMode) {<h1>Créer une agence</h1>}
            @else {<div [innerHTML]="getBranchTitle()" class="fs-1 fw-medium"></div>}
        </div>

        <div class="row justify-content-center">
            <form (ngSubmit)="save()" [formGroup]="branchForm"
                  class="text-center col-lg-6">
                <mat-form-field appearance="fill">
                    <mat-label>Code agence</mat-label>
                    <input matInput
                           formControlName="externalId"
                           id="externalId"
                           type="text"/>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Nom :</mat-label>
                    <input matInput
                           (focusout)="upperCaseControl('name')"
                           formControlName="name"
                           id="name"
                           type="text"/>
                </mat-form-field>

                <div class="mb-3">
                    <mat-form-field appearance="fill">
                        <mat-label>Code agence responsable</mat-label>
                        <input matInput
                               (change)="selectParentBranch()"
                               formControlName="parentBranchExternalId"
                               id="parentBranchExternalId"
                               type="text"/>
                    </mat-form-field>
                    @if (parentBranch) {
                        <div>
                            {{ parentBranch.externalId + ' - ' + parentBranch.name }}
                        </div>
                    }
                    @if (parentBranchMsg) {
                        <div [innerHTML]="parentBranchMsg" [style.color]="'#ff4242'"></div>
                    }
                </div>

                <mat-form-field appearance="fill">
                    <mat-label>Échelon hiérarchique</mat-label>
                    <mat-select formControlName="levelId">
                        <mat-option [value]="''">Aucun</mat-option>
                        @for (level of clientLevels; track level.id) {
                            <mat-option [value]="level.id">
                                {{level.entity}}
                            </mat-option>
                        }
                    </mat-select>
                </mat-form-field>

                @if (createMode) {
                    <mat-form-field appearance="fill">
                        <mat-label>Adresse 1</mat-label>
                        <input matInput
                               (focusout)="upperCaseControl('address1')"
                               formControlName="address1"
                               id="address1"
                               type="text"/>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Adresse 2</mat-label>
                        <input matInput
                               formControlName="address2"
                               id="address2"
                               type="text"/>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Code postal</mat-label>
                        <input matInput
                               formControlName="zipCode"
                               id="zipCode"
                               type="text"/>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Ville</mat-label>
                        <input matInput
                               (focusout)="upperCaseControl('city')"
                               formControlName="city"
                               id="city"
                               type="text"/>
                    </mat-form-field>
                }


                <mat-form-field appearance="fill">
                    <mat-label>Adresse email</mat-label>
                    <input matInput
                           formControlName="email"
                           id="email"
                           type="email"/>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>N° de portable</mat-label>
                    <input matInput
                           formControlName="phone"
                           id="phone"
                           maxlength="20"
                           type="tel"/>
                </mat-form-field>

                <mat-form-field appearance="fill">
                    <mat-label>Statut</mat-label>
                    <mat-select formControlName="status">
                        <mat-option [value]="''">Aucun</mat-option>
                        <mat-option [value]="'active'">Active</mat-option>
                        <mat-option [value]="'merged'">Fusionnée</mat-option>
                        <mat-option [value]="'deleted'">Supprimée</mat-option>
                    </mat-select>
                </mat-form-field>

                <div class="row mt-4">
                    <div class="col">
                        @if (!loading) {
                            <button class="btn btn-black me-3" routerLink="/admin/network/branches"
                                    type="button">Retour
                            </button>
                            <button [innerHTML]="createMode ? 'Créer':'Mettre à jour'"
                                    class="btn btn-validate" type="submit">
                                Créer / Mettre à jour
                            </button>
                        } @else {
                            <button class="btn btn-validate mt-2" disabled>
                                <i aria-hidden="true" class="fas fa-spinner fa-spin"></i>
                            </button>
                        }
                    </div>
                </div>
            </form>
        </div>
    }
</div>

