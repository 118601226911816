import {ChangeDetectorRef, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MsServicesGiftService, News, UserService} from '@isifid/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {OperationsService} from '../../../../../shared/services/operations.service';
import {DialogRewardConfirmationOperationComponent} from '../../../reward/reward.component';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {finalize, forkJoin} from 'rxjs';
import {Router} from '@angular/router';

@Component({
    selector: 'app-list',
    templateUrl: './news-list.component.html'
})
export class NewsListComponent implements OnInit {
    displayedColumns = ['title', 'content', 'status', 'publicationAt', 'actions'];
    dataSource: MatTableDataSource<News>;
    loading: boolean = true;
    editing: boolean = false;
    @ViewChild(MatSort) sortNews: MatSort;

    constructor(
        private readonly userService: UserService,
        private cdRef: ChangeDetectorRef,
        private dialog: MatDialog,
        private readonly router: Router,
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly operationsService: OperationsService,
        private readonly _snackBar: MatSnackBar
    ) {
    }

    ngOnInit() {
        this.init();
    }

    getStatus(status: string) {
        switch (status) {
            case 'draft':
                return 'Brouillon';
            case 'active':
                return 'Publié';
            case 'inactive':
                return 'Masqué';
        }
    }

    editNews(news: News): void {
        if (news.clientId !== this.userService.getUser().clientId) this.createReplicaOfNews(news);
        else this.router.navigate(['/admin/news', news.id]).then();
    }

    delete(newsId) {
        this.msServicesGiftService.deleteNews(newsId)
            .subscribe({
                next: () => {
                    this.getAllNews();
                    this._snackBar.open('Suppression de l\'actualité effectuée', 'X');
                },
                error: () => this._snackBar.open('Erreur', 'X')
            });
    }

    confirmDeleteNews(news: News) {
        if (this.loading) return;
        const dialogRef = this.dialog.open(DialogDeleteConfirmationOperationComponent, {data: {news}});
        dialogRef.afterClosed().subscribe({
            next: (result) => {
                if (result) {
                    if (!news.clientId) this.toggleClientFromNews(news);
                    else if (this.userService.hasRole('GIFT_ISIFID')) this.delete(news.id);
                    else this.setNewsInactive(news.id);
                }
            }
        });
    }

    private init() {
        this.dataSource = new MatTableDataSource<News>([]);
        this.getAllNews();
    }

    private filterNews(news: News[]): News[] {
        const operations = this.operationsService.getOperations(true, true, true);
        const operationsTypes = [...new Set(operations.reduce((acc, ope) => acc.concat(ope.operationType.id), []))];
        // Filter news to keep active operation with matching operation type
        return news.filter(n => n.operationTypeId && n.operationTypeId !== -1 ? operationsTypes.includes(n.operationTypeId) : true);
    }

    private getAllNews(): void {
        this.loading = true;
        this.msServicesGiftService.getAllNews({clientId: this.userService.getUser().clientId})
            .pipe(finalize(() => this.loading = false))
            .subscribe({
                next: (news) => {
                    // Sort by id
                    news.sort((a, b) => Number(a.id < b.id));
                    this.dataSource.data = this.filterNews(news);
                    this.dataSource.sort = this.sortNews;
                    this.cdRef.detectChanges();
                }
            });
    }

    private createReplicaOfNews(news: News): void {
        if (this.editing) return;
        this.editing = true;
        const updatedNews = {
            title: news.title,
            content: news.content,
            clientId: this.userService.getUser().clientId,
            status: 'draft',
            levelId: news.levelId,
            entityExternalId: news.entityExternalId.map(number => number.toString()),
            operationTypeId: news.operationTypeId
        };

        forkJoin([
            this.msServicesGiftService.createNews(updatedNews),
            this.msServicesGiftService.toggleClientFromNews(news.id, updatedNews.clientId)
        ])
            .pipe(finalize(() => this.editing = false))
            .subscribe({
                next: response => {
                    this.router.navigate(['/admin/news', response[0].id]).then();
                },
                error: () => this._snackBar.open('Erreur lors de la mise à jour de la news', 'X')
            });
    }

    private toggleClientFromNews(news: News): void {
        this.msServicesGiftService.toggleClientFromNews(news.id, this.userService.getUser().clientId)
            .subscribe({
                next: () => {
                    this.getAllNews();
                    this._snackBar.open('Actualités supprimées', 'X');
                },
                error: () => this._snackBar.open('Erreur', 'X')
            });
    }

    private setNewsInactive(newsId) {
        this.msServicesGiftService.updateNews(newsId, {status: 'inactive'})
            .subscribe({
                next: () => {
                    this.getAllNews();
                    this._snackBar.open('Actualité désactivée', 'X');
                },
                error: () => this._snackBar.open('Erreur', 'X')
            });
    }
}

export interface DialogData {
    news: News;
}

@Component({
    selector: 'app-dialog-delete-confirmation',
    templateUrl: 'dialog-delete-confirmation.html'
})
export class DialogDeleteConfirmationOperationComponent {
    constructor(
        private dialogRef: MatDialogRef<DialogRewardConfirmationOperationComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {
    }

    closeDialog(confirm: boolean) {
        this.dialogRef.close(confirm);
    }
}
