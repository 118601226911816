import {Component} from '@angular/core';

import {GiftService} from '../../../../shared/services/gift.service';
import {HierarchicalLevel, MsServicesGiftService, UserService} from '@isifid/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UIService} from '../../../../shared/services/ui.service';

@Component({
    selector: 'app-hierarchical-levels',
    templateUrl: './hierarchical-levels.component.html'
})
export class HierarchicalLevelsComponent {
    levels: Array<HierarchicalLevel> = [];
    form: FormGroup;

    constructor(
        public readonly giftService: GiftService,
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly formBuilder: FormBuilder,
        public readonly userService: UserService,
        public readonly uiService: UIService,
        private readonly _snackBar: MatSnackBar
    ) {
        this.initHierarchicalLevels();
    }

    initHierarchicalLevels() {
        this.msServicesGiftService.getHierarchicalLevelsByClientId(this.giftService.client.id).subscribe({
            next: (levels) => {
                levels.sort((a, b) => b.position - a.position);
                this.levels = levels;
            }
        });
    }

    initForm(level?: HierarchicalLevel) {
        if (level) {
            this.form = this.formBuilder.group({
                id: level.id,
                title: level.title,
                entity: level.entity,
                role: level.role,
                position: level.position
            });
        } else {
            this.form = this.formBuilder.group({
                id: 0,
                title: '',
                entity: '',
                role: 'GIFT_ADVISOR',
                position: 0
            });
        }
    }

    submit() {
        const id = this.form.get('id').value;
        const data = {
            title: this.form.get('title').value,
            role: this.form.get('role').value,
            entity: this.form.get('entity').value,
            position: this.form.get('position').value,
            clientId: this.giftService.client.id
        };

        const observer = id !== 0 ?
            this.msServicesGiftService.updateHierarchicalLevels(id, data) :
            this.msServicesGiftService.createHierarchicalLevels(data);
        observer.subscribe({
            next: () => {
                this.initHierarchicalLevels();
                this.giftService.initHierarchicalLevels(this.giftService.client.id).subscribe();
                this.form = null;
            },
            error: () => this._snackBar.open('Une erreur s\'est produite', 'X')
        });
    }

    delete(id) {
        this.msServicesGiftService.deleteHierarchicalLevels(id).subscribe({
            next: () => {
                this._snackBar.open('Échelon supprimé', 'X');
                this.initHierarchicalLevels();
            }
        });
    }

    getRole(role) {
        switch (role) {
            case 'GIFT_ADVISOR':
                return 'Conseiller';
            case 'GIFT_MANAGER':
                return 'Manager';
            case 'GIFT_DIRECTOR':
                return 'Directeur';
            case 'GIFT_HQ':
                return 'Siège';
            default:
                return '';
        }
    }
}
