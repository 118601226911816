<div class="container-fluid min-vh-100 pt-5">
    @if (loading) {<mat-spinner class="mx-auto" />}
    @else {
        <div class="text-center">
            <h1 class="my-5">Mes emails</h1>
            <div class="row my-3">
                <h2>Jetez un coup d’œil à l’email qui sera envoyé automatiquement à vos bénéficiaires</h2>
            </div>
            <div class="row my-5">
                <div class="col text-center">
                    <span>Email</span>
                    <select (change)="previewEmail($event.target['value'])" class="form-select form-select-lg">
                        <option [value]="'access'">access</option>
                        <option [value]="'reminder'">reminder</option>
                        <option [value]="'reminder_last'">reminder_last</option>
                        <option [value]="'order'">order</option>
                    </select>
                </div>
            </div>
        </div>
    }
</div>
