import {Component} from '@angular/core';
import {Budget, MsServicesGiftService, Operation} from '@isifid/core';
import {GiftService} from '../../../../shared/services/gift.service';
import {OperationsService} from '../../../../shared/services/operations.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-budgets-details',
    templateUrl: './details.component.html',
    styleUrls: ['./details.component.scss']

})
export class BudgetsDetailsComponent {
    loading: boolean;
    budget: Budget;
    operation: Operation;

    constructor(
        private giftService: GiftService,
        private operationsService: OperationsService,
        private msServicesGiftService: MsServicesGiftService,
        private router: Router
    ) {
        this.loading = true;
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        if (!id) this.router.navigate(['/admin/budgets/list']).then();
        this.msServicesGiftService.getBudget(id).subscribe({
            next: budget => {
                this.budget = budget;
                const operations = this.operationsService.getOperations(false, false).filter(o => o.id === this.budget.operationId);
                if (!operations.length) this.router.navigate(['/admin/budgets/list']).then();
                this.operation = operations[0];
                this.loading = false;
            }
        });
    }

    getNumberOfMonth() {
        const start = new Date(this.budget.startAt);
        const end = new Date(this.budget.endAt);
        return (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
    }

    getNumberOfMonthFirstPeriod() {
        const start = new Date(this.budget.startAt);
        const end = new Date(this.budget.firstPeriodEndAt);
        return (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
    }

    getNumberOfMonthSecondPeriod() {
        const start = new Date(this.budget.firstPeriodEndAt);
        const end = new Date(this.budget.endAt);
        return (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
    }

    getElapsedTime() {
        const start = new Date(this.budget.startAt);
        const end = new Date(this.budget.endAt);
        const now = new Date();
        let string = Math.round((now.getTime() - start.getTime()) / (end.getTime() - start.getTime()) * 100) + '%';
        if (start.getTime() > now.getTime()) string = string + ' (l\'opération n\'a pas encore commencé)';
        return string;
    }

    isFirstPeriodOver() {
        return (new Date()).getTime() > (new Date(this.budget.firstPeriodEndAt)).getTime();
    }
}
