@if (operations) {
    @for (operation of operations; track operation.id) {
        <div>
            <a [routerLink]="'/admin/operations/manage/' + operation.id" class="clickable">
                {{operation.name}}</a>
            <br>

            <span>Récompense {{ uiService.getRewardType(operation.rewardTypeId) }} - </span>
            <span>
                @if (operation.endDate) {
                    Jusqu'au {{ (operation.endDate | dateBefore: 1) | date:'dd/MM/yyyy' }}
                } @else {
                    Sans échéance
                }
            </span>

            <br>
            @if (budgetConsumption(operation.id) !== -1) {
                <span>
                    Budget {{ getEntityName(operation) }}
                    @if (getBudgetId(operation.id) === null) {
                        consommée à {{ budgetConsumption(operation.id) | number:'1.0-0' }} %
                    } @else {
                        <a [queryParams]="{id: getBudgetId(operation.id)}" [routerLink]="'/admin/budgets/tracking'" class="clickable">
                            consommée à {{ budgetConsumption(operation.id) | number:'1.0-0'}} %
                        </a>
                    }
                </span>
            }
            <br>
            <br>
        </div>
    }
}
