<div class="container-fluid py-5">

    <h1 class="text-center my-5">Stats Parrainage</h1>

    @if (statsForm) {
        <div class="row">
            <div class="col col-md-6 mx-auto">
                <form [formGroup]="statsForm" class="text-center">
                    <div class="d-flex flex-row justify-content-around">
                        <mat-form-field appearance="fill">
                            <mat-label>Démarre le</mat-label>
                            <input matInput formControlName="start" id="start" [matDatepicker]="start"/>
                            <mat-datepicker-toggle matIconSuffix [for]="start" />
                            <mat-datepicker #start />
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Se termine le</mat-label>
                            <input matInput formControlName="end" id="end" [matDatepicker]="end"/>
                            <mat-datepicker-toggle matIconSuffix [for]="end" />
                            <mat-datepicker #end />
                        </mat-form-field>
                    </div>

                    <div class="row my-5">
                        <div class="col mx-auto">
                            <input (change)="toggleAnonymizeConsumersData()" id="anonymizeConsumersData" type="checkbox">
                            <label class="ms-2 check-box" for="anonymizeConsumersData">
                                Anonymiser les données bénéficiaires
                            </label>
                        </div>
                    </div>

                    <div class="my-1 row text-center justify-content-center">
                        <div class="col">
                            @if (giftNetworkService.giftUsers?.length) {
                                <button (click)="getStats()" class="btn btn-validate m-3 px-4 py-2" data-cy="button-research">
                                    Recherche
                                </button>
                            } @else {
                                <button class="btn btn-validate my-2 px-4 py-2" disabled>
                                    <mat-spinner color="primary" diameter="30" />
                                </button>
                            }
                            <button (click)="resetForm()" class="btn btn-black m-3 px-4 py-2"> Reset</button>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    }

    <div class="row">
        @if (!loading && stats) {
            <div class="col mx-auto text-center">
                <button (click)="download()" class="btn btn-validate px-4 py-2">
                    <i aria-hidden="true" class="fas fa-file-excel mx-2"></i> Export Excel
                </button>
            </div>
        } @else if (loading) {
            <div class="col mx-auto text-center">
                <button class="btn btn-validate my-2 px-4 py-2" disabled>
                    <mat-spinner color="primary" diameter="30" />
                </button>
            </div>
        }
    </div>

    <section class="my-5">
        <mat-tab-group [hidden]="!stats" class="my-4">
            <mat-tab label="Récapitulatif">
                @if (stats) {
                    <div class="p-5">
                        <b>
                            <p>Totaux :</p>
                            <p>Nombre total d'inscriptions de parrains potentiels : {{sponsors.length}}
                            </p>
                            <p>Nombre total d'ouvertures de comptes filleuls : {{sponsored.length}}</p>
                            <p>Taux de transformation :
                                {{(sponsored.length * 100 / sponsors.length) || 0 | number: '1.0-2'}}
                            </p>
                        </b>
                        <br>
                        <b><p>- Dont résultats de l'action des conseillers : </p></b>
                        <p>Nombre d'inscriptions de parrains potentiels : {{sponsorsFromGift.length}}</p>
                        <p>Nombre d'ouvertures de comptes filleuls : {{sponsoredFromGift.length}}</p>
                        <p>Taux de transformation : {{(sponsoredFromGift.length * 100 / sponsorsFromGift.length) || 0 | number: '1.0-2'}}</p>
                        <p matTooltip="ayant enregistré un parrain potentiel" matTooltipPosition="right">
                            Nombre de conseillers actifs : {{giftUsersActive}}
                        </p>
                        <p matTooltip="ayant obtenu l'ouverture d'au moins 1 compte" matTooltipPosition="right">
                            Nombre de conseillers efficaces : {{giftUsersEfficient}}
                        </p>
                        <p matTooltip="ayant enregistré un parrain potentiel" matTooltipPosition="right">
                            Nombre d'agences actives : {{branchesActive}}
                        </p>
                        <p matTooltip="ayant obtenu l'ouverture d'au moins 1 compte" matTooltipPosition="right">
                            Nombre d'agences efficaces : {{branchesEfficient}}
                        </p>
                        <br>
                        <b><p>- Dont résultats de l'action online : </p></b>
                        <p>Nombre d'inscriptions de parrains potentiels : {{sponsorsFromDirect.length}}</p>
                        <p>Nombre d'ouvertures de comptes filleuls : {{sponsoredFromDirect.length}}</p>
                        <p>Taux de transformation : {{(sponsoredFromDirect.length * 100 / sponsorsFromDirect.length) || 0 | number: '1.0-2'}}</p>
                        <br>
                        <b><p>- Dont résultats d'une autre action : </p></b>
                        <p>Nombre d'inscriptions de parrains potentiels : {{sponsorsFromOthers.length}}</p>
                        <p>Nombre d'ouvertures de comptes filleuls : {{sponsoredFromOthers.length}}</p>
                        <p>Taux de transformation : {{(sponsoredFromOthers.length * 100 / sponsorsFromOthers.length) || 0 | number: '1.0-2'}}</p>
                        @if (askForExpectedSponsoredType) {
                            <br>
                            <b><p>Type de parrainages attendus : </p></b>
                            @for (sponsoredType of sponsorshipService.expectedSponsoredType; track sponsoredType) {
                                <p>{{ sponsoredType }} : {{ expectedSponsoredTypeMap.get(sponsoredType) }}</p>
                            }
                            @if (expectedSponsoredTypeMap.get(expectedSponsoredTypeNullValue)) {
                                <p>{{ expectedSponsoredTypeNullValue }} : {{ expectedSponsoredTypeMap.get(expectedSponsoredTypeNullValue) }}</p>
                            }
                        }
                    </div>
                }
            </mat-tab>
            @if (displayedColumns) {
                <mat-tab label="Conseillers agrégés">
                    <div class="responsive-table">
                        <div class="responsive-table-body">
                            <table #giftUserSort="matSort" [dataSource]="dataSourceGiftUsers" aria-describedby="Conseillers agrégés"
                                   class="mat-elevation-z8 w-100"
                                   mat-table matSort>
                                @for (stat of displayedColumns[5]; track i; let i = $index) {
                                    <ng-container [matColumnDef]="stat">
                                        <th *matHeaderCellDef [mat-sort-header]="stat" mat-header-cell scope="col">
                                            {{colNames[5][i]}}
                                        </th>
                                        <td *matCellDef="let element" mat-cell> {{element[stat]}} </td>
                                    </ng-container>
                                }

                                <tr *matHeaderRowDef="displayedColumns[5]" mat-header-row></tr>
                                <tr *matRowDef="let row; columns: displayedColumns[5];" mat-row></tr>
                            </table>
                        </div>
                        <mat-paginator #giftUserPaginator
                                       [pageSizeOptions]="[10, 20, 50, 100]"
                                       showFirstLastButtons />
                    </div>
                </mat-tab>
                <mat-tab label="Agences agrégées">
                    <div class="responsive-table">
                        <div class="responsive-table-body">
                            <table #branchSort="matSort" [dataSource]="dataSourceBranches" aria-describedby="Agences agrégées"
                                   class="mat-elevation-z8 w-100"
                                   mat-table matSort>
                                @for (stat of displayedColumns[6]; track i; let i = $index) {
                                    <ng-container [matColumnDef]="stat">
                                        <th *matHeaderCellDef [mat-sort-header]="stat" mat-header-cell scope="col">
                                            {{colNames[6][i]}}
                                        </th>
                                        <td *matCellDef="let element" mat-cell> {{element[stat]}} </td>
                                    </ng-container>
                                }

                                <tr *matHeaderRowDef="displayedColumns[6]" mat-header-row></tr>
                                <tr *matRowDef="let row; columns: displayedColumns[6];" mat-row></tr>
                            </table>
                        </div>
                        <mat-paginator #branchPaginator
                                       [pageSizeOptions]="[10, 20, 50, 100]"
                                       showFirstLastButtons />
                    </div>
                </mat-tab>
                <mat-tab label="Listing des parrains inscrits">
                    <div class="responsive-table">
                        <div class="responsive-table-body">
                            <table #sponsorSort="matSort" [dataSource]="dataSourceSponsor" aria-describedby="Listing des parrains inscrits"
                                   class="mat-elevation-z8 w-100"
                                   mat-table matSort>
                                @for (col of displayedColumns[1]; track col; let index = $index) {
                                    <ng-container [matColumnDef]="col">
                                        <th *matHeaderCellDef [mat-sort-header]="col" mat-header-cell scope="col">
                                            {{colNames[1][index]}}
                                        </th>
                                        @if (col === 'sponsorCodeSentAt') {
                                            <td *matCellDef="let element" mat-cell>{{element[col] | date: 'yyyy-MM-dd'}}</td>
                                        } @else {
                                            <td *matCellDef="let element" mat-cell> {{element[col]}} </td>
                                        }
                                    </ng-container>
                                }

                                <tr *matHeaderRowDef="columnsToDisplay[1]" mat-header-row></tr>
                                <tr *matRowDef="let row; columns: columnsToDisplay[1];" mat-row></tr>
                            </table>
                        </div>
                        <mat-paginator #sponsorPaginator [pageSizeOptions]="[10, 20, 50, 100]"
                                       showFirstLastButtons />
                    </div>
                </mat-tab>
                <mat-tab label="Listing des parrains récompensés">
                    <div class="responsive-table">
                        <div class="responsive-table-body">
                            <table #sponsorRewardedSort="matSort" [dataSource]="dataSourceSponsorRewarded" aria-describedby="Listing des parrains récompensés"
                                   class="mat-elevation-z8 w-100"
                                   mat-table matSort>
                                @for (col of displayedColumns[2]; track col; let index = $index) {
                                    <ng-container [matColumnDef]="col">
                                        <th *matHeaderCellDef [mat-sort-header]="col" mat-header-cell scope="col">
                                            {{colNames[2][index]}}
                                        </th>
                                        @if (col === 'sponsorCodeSentAt' || col === 'rewardCreatedAt') {
                                            <td *matCellDef="let element" mat-cell>{{element[col] | date: 'yyyy-MM-dd'}}</td>
                                        } @else {
                                            <td *matCellDef="let element" mat-cell> {{element[col]}} </td>
                                        }
                                    </ng-container>
                                }

                                <tr *matHeaderRowDef="columnsToDisplay[2]" mat-header-row></tr>
                                <tr *matRowDef="let row; columns: columnsToDisplay[2];" mat-row></tr>
                            </table>
                        </div>
                        <mat-paginator #sponsorRewardedPaginator [pageSizeOptions]="[10, 20, 50, 100]"
                                       showFirstLastButtons />
                    </div>
                </mat-tab>
                <mat-tab label="Listing des filleuls">
                    <div class="responsive-table">
                        <div class="responsive-table-body">
                            <table #sponsoredSort="matSort" [dataSource]="dataSourceSponsored" aria-describedby="listing des filleuls"
                                   class="mat-elevation-z8 w-100"
                                   mat-table matSort>
                                @for (stat of displayedColumns[3]; track stat; let i = $index) {
                                    <ng-container [matColumnDef]="stat">
                                        <th *matHeaderCellDef [mat-sort-header]="stat" mat-header-cell scope="col">
                                            {{colNames[3][i]}}
                                        </th>
                                        <td *matCellDef="let element" mat-cell> {{element[stat]}} </td>
                                    </ng-container>
                                }

                                <tr *matHeaderRowDef="displayedColumns[3]" mat-header-row></tr>
                                <tr *matRowDef="let row; columns: displayedColumns[3];" mat-row></tr>
                            </table>
                        </div>
                        <mat-paginator #sponsoredPaginator [pageSizeOptions]="[10, 20, 50, 100]"
                                       showFirstLastButtons />
                    </div>
                </mat-tab>
                <mat-tab label="Listing des invitations">
                    <div class="responsive-table">
                        <div class="responsive-table-body">
                            <table #inviteSort="matSort" [dataSource]="dataSourceInvites" aria-describedby="listing des invitations"
                                   class="mat-elevation-z8 w-100"
                                   mat-table matSort>
                                @for (stat of displayedColumns[4]; track stat; let i = $index) {
                                    <ng-container [matColumnDef]="stat">
                                        <th *matHeaderCellDef [mat-sort-header]="stat" mat-header-cell scope="col">
                                            {{colNames[4][i]}}
                                        </th>
                                        @if (stat !== 'createdAt') {
                                            <td *matCellDef="let element" mat-cell> {{element[stat]}} </td>
                                        } @else {
                                            <td *matCellDef="let element" mat-cell> {{element[stat] | date : 'yyyy-MM-dd'}} </td>
                                        }
                                    </ng-container>
                                }

                                <tr *matHeaderRowDef="displayedColumns[4]" mat-header-row></tr>
                                <tr *matRowDef="let row; columns: displayedColumns[4];" mat-row></tr>
                            </table>
                        </div>
                        <mat-paginator #invitedPaginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons />
                    </div>
                </mat-tab>
            }
        </mat-tab-group>
    </section>

</div>
