@if (!loading) {
    <div class="container-fluid py-3">

        <div class="row">
            <div class="col px-5">

                <div class="d-flex flex-column bg-light-blue p-4">
                    <h1 class="mb-3">Objectifs de la campagne</h1>
                    <p [innerHTML]="operation.description" class="my-2 px-2"></p>
                </div>

                <div class="d-flex flex-column mt-5">

                    <h2 class="text-start px-3">{{ operation.name }}</h2>

                    <ul class="mt-2">
                        <li>
                            @if (operation.rewardTypeId < 4) {
                                Cartes Cadeaux Isifid {{ uiService.getRewardType(operation.rewardTypeId) }}
                            } @else if (operation.rewardTypeId >= 4) {
                                Type de récompense : {{ uiService.getRewardType(operation.rewardTypeId) }}
                            }
                        </li>

                        @for (offer of operation.offers; track offer.id) {
                            <li>{{ offer.name }} – Récompense {{ offer.amount }} €</li>
                        }

                        <li>Date de départ : {{ operation.startDate | date:'dd/MM/yyyy' }}</li>

                        @if (operation.endDate) {
                            <li>Date de fin : {{ operation.endDate | date:'dd/MM/yyyy' }}</li>
                        }

                        <li>
                            <span>
                                @if (budget?.attributionMethod === 'manual') {
                                    Attribution sélective à l’initiative du conseiller
                                } @else {
                                    Attribution systématique
                                }
                            </span>
                        </li>

                        <li>Budget Agence : {{ budget?.amountRemaining ? 'Oui' : 'Non' }}</li>

                        @if (budget) {
                            <li>Plafond de l’engagement budgétaire global HT :
                                {{ budget.amountTotal * (+operation.chargeRate / 100) | number:'':'fr-FR' }} €
                            </li>

                            <li>Valeur faciale distribuable :
                                {{ budget.amountTotal | number:'':'fr-FR' }} €
                            </li>

                            <li>Budget réparti par : {{ getLevelEntityName(budget.levelId) }}</li>

                            @if (budget.amountPerUser) {
                                <li>
                                    Budget par {{ getLevelEntityName(budget.levelId) }}
                                    : {{ budget.amountPerUser | number:'':'fr-FR' }} €
                                </li>
                            }
                        }
                    </ul>
                </div>
            </div>

            <div class="col px-5">
                @if (budget) {
                    <div class="d-flex flex-column bg-light-blue p-4">

                        <div class="text-center mb-3">
                            <h1 class="d-inline me-2">Résultats globaux</h1><span>(à ce jour)</span>
                        </div>
                        @if (operation.offers?.length > 0) {
                            @for (offer of groupOffersByAmount(operation.offers); track offer.id) {
                                <div>
                                    Nombre de récompenses {{ offer.amount }}€ distribuées : {{ getOfferCount(+offer.amount) }}
                                </div>
                            }
                        }

                        <div>Nombre de récompenses distribuées : {{ getNumberReward(creditsHistory) }}</div>
                        <div>Montant total distribué (en valeur faciale) :
                            {{ budget.amountTotal - budget.amountRemaining | currency:'EUR':'symbol':'1.0-0':'fr-FR' }}
                        </div>
                        <div>Prix de revient HT des récompenses distribuées :
                            {{
                                (budget.amountTotal - budget.amountRemaining) *
                                (+operation.chargeRate / 100)  | currency:'EUR':'symbol':'1.0-0':'fr-FR'
                            }}
                        </div>

                        <h2 class="my-3">Budget Agence</h2>

                        <ul>
                            <li>% distribué :
                                {{ (budget.amountTotal - budget.amountRemaining) / budget.amountTotal * 100 | number:'1.0-0' }}
                                % du Budget Agence
                            </li>
                            <li>Solde disponible :
                                {{ budget.amountRemaining | currency:'EUR':'symbol':'1.0-0':'fr-FR' }}
                            </li>
                            <li>% de temps écoulé depuis le début de l’opération :  {{ getElapsedTime() }}</li>
                        </ul>
                    </div>
                }
            </div>
        </div>

        <div class="row my-5">
            <div class="col">

                <div class="text-center">
                    <button class="btn btn-validate" routerLink="/admin">Retour</button>
                </div>

            </div>

            <div class="col">
                <div class="col text-center">
                    <h3 class="mb-3">Suivi des attributions</h3>
                    <!-- Operations other than sponsorship   -->
                    @if (operation.operationType.id !== 2) {
                        <div class="col-7 ms-auto ps-2 text-start">
                            @for (trackingLevel of trackingLevels; track trackingLevel.id) {
                                @if (checkLevel(trackingLevel.id)) {
                                    <a [queryParams]="{level: trackingLevel.value, operation: operation.id}"
                                       routerLink="/admin/stats/operations">
                                        {{ trackingLevel.name }} <br>
                                    </a>
                                }
                            }
                        </div>
                    }
                    <!--  Sponsorship operation    -->
                    @if (operation.operationType.id === 2) {
                        <div class="col-7 ms-auto ps-2 text-start">
                            <a [queryParams]="{trackingLevel: 'global', operationId: operation.id}"
                               routerLink="/admin/stats/sponsorship">Global
                            </a><br>
                        </div>
                    }
                </div>
            </div>

            <div class="col"></div>
        </div>
    </div>
}
