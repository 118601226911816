<div class="container-fluid min-vh-100 pt-5">
    @if (loading) {<mat-spinner class="mx-auto" />}
    @else {
        <div class="px-5">
            <h1>Suivi du budget agence de l'opération <br> {{operation.name}}</h1>
            <div class="row mt-5 pt-5">
                <div class="col stats-card">
                    <p>Budget</p>
                    <h3 class="pt-3">
                        {{budget.amountTotal | currency:'EUR':'symbol':'1.0-0':'fr-FR' }}
                    </h3>
                </div>
                <div class="col stats-card">
                    <p>Durée totale</p>
                    <h3 class="pt-3">
                        {{getNumberOfMonth()}} mois
                    </h3>
                </div>
                <div class="col stats-card">
                    <p>Budget consommé</p>
                    <h3 class="pt-3">
                        {{budget.amountTotal - budget.amountRemaining | currency:'EUR':'symbol':'1.0-0':'fr-FR' }} /
                        {{(budget.amountTotal - budget.amountRemaining) / budget.amountTotal * 100 | number:'1.0-0'}}%
                    </h3>
                </div>
                <div class="col stats-card">
                    <p>Durée écoulée</p>
                    <h3 class="pt-3">{{getElapsedTime()}}</h3>
                </div>
            </div>
        </div>
    }

    @if (!loading) {
        <table [dataSource]="dataSource" [hidden]="!dataSource" aria-describedby="tracking"
               class="my-5 w-100" mat-table matSort>

            <ng-container class="py-5" matColumnDef="entityId">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Code {{ level.title }}</th>
                <td *matCellDef="let row" class="text-secondary" mat-cell>
                    {{row.entityId}}
                </td>
            </ng-container>

            <ng-container class="py-5" matColumnDef="branch">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>{{ level.entity }}</th>
                <td *matCellDef="let row" class="text-secondary" mat-cell>
                    {{row.branch}}
                </td>
            </ng-container>

            <ng-container class="py-5" matColumnDef="amountGranted">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Montant distribué</th>
                <td *matCellDef="let row" class="text-secondary" mat-cell>
                    {{row.amountGranted}} €
                </td>
            </ng-container>

            <ng-container class="py-5" matColumnDef="amountRemaining">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Montant restant</th>
                <td *matCellDef="let row" class="text-secondary" mat-cell>
                    {{row.amountRemaining}} €
                </td>
            </ng-container>

            <ng-container class="py-5" matColumnDef="amountUsed">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Montant utilisé</th>
                <td *matCellDef="let row" class="text-secondary" mat-cell>
                    {{row.amountUsed}} € / {{row.amountUsedP}}%
                </td>
            </ng-container>

            <ng-container class="py-5" matColumnDef="updatedAt">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Dernière utilisation</th>
                <td *matCellDef="let row" class="text-secondary" mat-cell>
                    {{row.updatedAt | date:'dd/MM/yyyy' }}
                </td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
        </table>
    }
</div>
