<div class="p-5">
    <h2>Êtes vous sûr de vouloir supprimer cette news ?</h2>

    <div>
        <p>Titre : {{data.news.title}}</p>
        <p [innerHTML]="data.news.content" class="m-3 p-3 border"></p>
    </div>

    <div class="justify-content-center" mat-dialog-actions>
        <button (click)="closeDialog(true)"
                class="btn btn-validate mx-auto px-5 py-3" mat-dialog-close="true" type="button">
            Oui supprimer
        </button>
        <button (click)="closeDialog(false)"
                class="btn btn-validate mx-auto px-5 py-3" mat-dialog-close="true" type="button">
            Non annuler
        </button>
    </div>

</div>
