<div>
    @if (data.consumersToRewardFromFileStats && data.consumersToRewardFromFileStats) {
        <p>Êtes vous sûr de vouloir
            récompenser {{data.consumersToRewardFromFileStats.valid}} bénéficiaire(s) ?<br>
        </p>
    }

    <div class="justify-content-center" mat-dialog-actions>
        <button (click)="rewardBatch()" class="btn btn-validate px-5 py-3" type="button"> Oui</button>
        <button class="btn btn-validate ms-3 px-5 py-3" mat-dialog-close mat-raised-button type="button">
            Non
        </button>
    </div>
</div>
