import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {authGuardChildFn, trackGuardChildFn} from '@isifid/core';

import {BillingComponent} from './billing/billing.component';
import {OperationsCustomFieldsComponent} from './operations/custom-fields/custom-fields.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {StatsOperationsComponent} from './stats/operations/stats-operations.component';
import {StatsSponsorshipComponent} from './stats/sponsorship/stats-sponsorship.component';
import {ParametersComponent} from './clients/parameters/parameters.component';
import {SettingsComponent} from './sponsorship/settings/settings.component';
import {EmailComponent} from './clients/email/email.component';
import {PreviewComponent} from './clients/email/preview/preview.component';
import {RewardComponent} from './reward/reward.component';
import {UsersListComponent} from './network/users/list/list.component';
import {UsersManageComponent} from './network/users/manage/manage.component';
import {BranchListComponent} from './network/branches/list/list.component';
import {BranchManageComponent} from './network/branches/manage/manage.component';
import {BranchImportComponent} from './network/branches/import/import.component';
import {BudgetsListComponent} from './budgets/list/list.component';
import {BudgetsManageComponent} from './budgets/manage/manage.component';
import {BudgetsDetailsComponent} from './budgets/details/details.component';
import {HierarchicalLevelsComponent} from './network/hierarchical-levels/hierarchical-levels.components';
import {OperationsManageComponent} from './operations/manage/manage.component';
import {OperationsRequestComponent} from './operations/request/request.component';
import {BudgetsSpreadComponent} from './budgets/spread/spread.component';
import {NewsListComponent} from './clients/news/list/news-list.component';
import {NewsManageComponent} from './clients/news/manage/manage.component';
import {BudgetTrackingComponent} from './budgets/tracking/tracking.component';
import {RequestComponent} from './sponsorship/request/request.component';
import {BannersListComponent} from './clients/banners/list/list.component';
import {NetworkControlComponent} from './network/control/control.component';
import {AdminRewardProgramsListComponent} from './reward-programs/list/list.component';
import {AdminRewardProgramsManageComponent} from './reward-programs/manage/manage.component';
import {AutomationOperationsListComponent} from './automation/list/list.component';
import {AutomationOperationRulesListComponent} from './automation/operations/list/list.component';
import {AutomationRuleManageComponent} from './automation/operations/manage/manage.component';
import {BudgetsReallocationEmailingComponent} from './budgets/reallocation/reallocation-emailing.component';

const routes: Routes = [
    {
        path: 'admin/network',
        canActivateChild: [authGuardChildFn, trackGuardChildFn],
        data: {roles: 'GIFT_HQ'},
        children: [
            {
                path: 'branches',
                component: BranchListComponent
            },
            {
                path: 'branches/manage',
                component: BranchImportComponent
            },
            {
                path: 'branches/import',
                component: BranchImportComponent
            },
            {
                path: 'branches/:id',
                component: BranchManageComponent
            },
            {
                path: 'branches/check',
                component: BranchManageComponent
            },
            {
                path: 'hierarchical-levels',
                component: HierarchicalLevelsComponent
            },
            {
                path: 'users',
                component: UsersListComponent
            },
            {
                path: 'users/manage',
                component: UsersManageComponent
            },
            {
                path: 'control',
                component: NetworkControlComponent
            }
        ]
    },
    {
        path: 'admin/stats',
        canActivateChild: [authGuardChildFn, trackGuardChildFn],
        children: [
            {
                path: 'sponsorship',
                data: {roles: 'GIFT_HQ'},
                component: StatsSponsorshipComponent
            },
            {
                path: 'operations',
                component: StatsOperationsComponent
            }
        ]
    },
    {
        path: 'admin/budgets',
        canActivateChild: [authGuardChildFn, trackGuardChildFn],
        children: [
            {
                path: 'spread',
                data: {roles: 'GIFT_HQ'},
                component: BudgetsSpreadComponent
            },
            {
                path: 'details',
                data: {roles: 'GIFT_HQ'},
                component: BudgetsDetailsComponent
            },
            {
                path: 'list',
                data: {roles: 'GIFT_HQ'},
                component: BudgetsListComponent
            },
            {
                path: 'manage',
                data: {roles: 'GIFT_HQ'},
                component: BudgetsManageComponent
            },
            {
                path: 'tracking',
                data: {roles: 'GIFT_HQ'},
                component: BudgetTrackingComponent
            },
            {
                path: 'reallocated-credit/:creditId',
                component: BudgetsReallocationEmailingComponent
            },
            {
                path: '',
                data: {roles: 'GIFT_HQ'},
                component: BudgetsListComponent
            }
        ]
    },
    {
        path: 'admin/operations',
        canActivateChild: [authGuardChildFn, trackGuardChildFn],
        data: {roles: 'GIFT_HQ'},
        children: [
            {
                path: 'custom-fields',
                data: {roles: 'GIFT_ISIFID'},
                component: OperationsCustomFieldsComponent
            },
            {
                path: 'manage/:id',
                component: OperationsManageComponent
            },
            {
                path: 'request',
                component: OperationsRequestComponent
            }
        ]
    },
    {
        path: 'admin/clients',
        canActivateChild: [authGuardChildFn, trackGuardChildFn],
        data: {roles: 'GIFT_HQ'},
        children: [
            {
                path: 'banners',
                component: BannersListComponent
            },
            {
                path: 'news',
                component: NewsListComponent
            },
            {
                path: 'parameters',
                component: ParametersComponent
            }
        ]
    },
    {
        path: 'admin/emails',
        canActivateChild: [authGuardChildFn, trackGuardChildFn],
        data: {roles: 'GIFT_ISIFID'},
        children: [
            {
                path: 'preview',
                component: PreviewComponent
            },
            {
                path: '',
                component: EmailComponent
            }
        ]
    },
    {
        path: 'admin/sponsorship',
        canActivateChild: [authGuardChildFn, trackGuardChildFn],
        data: {roles: 'GIFT_HQ'},
        children: [
            {
                path: 'settings',
                component: SettingsComponent
            },
            {
                path: 'request',
                component: RequestComponent
            },
            {
                path: '**',
                redirectTo: 'request'
            }
        ]
    },
    {
        path: 'admin/reward-programs',
        canActivateChild: [authGuardChildFn, trackGuardChildFn],
        data: {roles: 'GIFT_ISIFID'},
        children: [
            {
                path: '',
                component: AdminRewardProgramsListComponent
            },
            {
                path: 'create',
                component: AdminRewardProgramsManageComponent
            },
            {
                path: ':id',
                component: AdminRewardProgramsManageComponent
            }
        ]
    },
    {
        path: 'admin/automation',
        canActivateChild: [authGuardChildFn, trackGuardChildFn],
        data: {roles: 'GIFT_ISIFID'},
        children: [
            {
                path: 'operations',
                component: AutomationOperationsListComponent
            },
            {
                path: 'operations/:id',
                component: AutomationOperationRulesListComponent
            },
            {
                path: 'operations/:operationId/rule/:ruleId',
                component: AutomationRuleManageComponent
            },
            {
                path: 'operations/:operationId/rule',
                component: AutomationRuleManageComponent
            }
        ]
    },
    {
        path: 'admin',
        canActivateChild: [authGuardChildFn, trackGuardChildFn],
        data: {roles: 'GIFT_HQ'},
        children: [
            {
                path: 'billing',
                data: {roles: 'GIFT_ISIFID'},
                component: BillingComponent
            },
            {
                path: 'dashboard',
                component: DashboardComponent
            },
            {
                path: 'news/:id',
                component: NewsManageComponent
            },
            {
                path: 'reward',
                data: {roles: 'GIFT_ISIFID'},
                component: RewardComponent
            },
            {
                path: '**',
                redirectTo: 'dashboard'
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AdminRoutingModule {
}
