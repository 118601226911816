<div class="container-fluid m-0 px-5">

    <div class="my-5">
        <h1>Contrôle de cohérence</h1>
    </div>

    @if (branches) {
        <mat-tab-group class="my-4" >
            <mat-tab label="Entités">
                <app-admin-network-control-branches
                        [branches]="branches"
                        [levels]="levels">
                </app-admin-network-control-branches>
            </mat-tab>
            <mat-tab label="Utilisateurs">
                <app-admin-network-control-users
                        [giftUsers]="giftUsers"
                        [users]="users"
                        [levels]="levels">
                </app-admin-network-control-users>
            </mat-tab>
            <mat-tab label="Réseau">
                <app-network-control-network
                        [giftUsers]="giftUsers"
                        [users]="users"
                        [levels]="levels"
                        [branches]="branches">
                </app-network-control-network>
            </mat-tab>
        </mat-tab-group>
    } @else {
        <div>
            <em class="fas fa-spinner fa-spin fs-2"></em>
        </div>
    }
</div>

