import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {Branch, GiftUser, HierarchicalLevel, User} from '@isifid/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {FormControl} from '@angular/forms';

interface DataSource {
    externalId?: string;
    email?: string;
    levelEntity?: string;
    filterValue?: string
}

@Component({
    selector: 'app-network-control-network',
    templateUrl: './network.component.html'
})
export class NetworkControlNetworkComponent implements OnInit, AfterViewInit {
    filterControl = new FormControl('branchesWithMultipleManagers');
    dataSource: MatTableDataSource<any>;
    displayedColumns: string[] = ['id', 'externalId', 'name', 'levelEntity'];
    levelsWithoutBranch: Array<HierarchicalLevel & DataSource> = [];
    levelsWithoutUser: Array<HierarchicalLevel & DataSource> = [];
    usersWithoutBranch: Array<GiftUser & DataSource> = [];
    branchesWithMultipleManagers: Array<Branch & DataSource> = [];
    branchesWithoutManager: Array<Branch & DataSource> = [];
    branchesWithoutUser: Array<Branch & DataSource> = [];
    private filteredBranches: Array<Branch & DataSource> = [];
    private filteredLevels: Array<HierarchicalLevel & DataSource> = [];
    @Input() private giftUsers: GiftUser[];
    @Input() private users: User[];
    @Input() private levels: HierarchicalLevel[];
    @Input() private branches: Branch[];
    @ViewChild(MatSort) private sort: MatSort;
    @ViewChild(MatPaginator) private paginator: MatPaginator;


    ngOnInit(): void {
        for (const s of this.branches) {
            s['levelEntity'] = this.levels.find(t => t.id === s.levelId)?.entity ?? '';

            const branchGiftUsers: GiftUser[] = this.getGiftUsersByBranchCode(s.externalId);
            let managerCount = 0;
            branchGiftUsers.forEach(u => {
                const level = this.levels.find(t => t.id === u.levelId);
                // We skip basic advisors
                if (level.position > 0) managerCount++;
            });

            if (managerCount > 1) {
                // Branch with more than 1 manager
                this.branchesWithMultipleManagers.push({...s, levelEntity: s['levelEntity'], filterValue: 'branchesWithMultipleManagers'});
            }
            if (managerCount === 0) {
                // Branch without manager
                this.branchesWithoutManager.push({...s, levelEntity: s['levelEntity'], filterValue: 'branchesWithoutManager'});
            }

            if (!branchGiftUsers.length) {
                // Branch without user
                this.branchesWithoutUser.push({...s, levelEntity: s['levelEntity'], filterValue: 'branchesWithoutUser'});
            }
        }

        this.setLevels();
        
        // Get unique elements
        this.branchesWithMultipleManagers = this.removeDuplicatedElement(this.branchesWithMultipleManagers);
        this.branchesWithoutManager = this.removeDuplicatedElement(this.branchesWithoutManager);
        this.branchesWithoutUser = this.removeDuplicatedElement(this.branchesWithoutUser);
        this.filteredBranches = this.removeDuplicatedElement([
            ...this.branchesWithMultipleManagers,
            ...this.branchesWithoutManager,
            ...this.branchesWithoutUser
        ]);
        this.filteredLevels = this.removeDuplicatedElement([...this.levelsWithoutBranch, ...this.levelsWithoutUser]);

        // User with a branch defined but the branch is not present in the list
        this.usersWithoutBranch = this.giftUsers
            .filter(s => s.branchList.some(t => this.branches.every(branch => parseInt(branch.externalId) !== t), ))
            .map(s => ({
                ...s,
                email: this.users.find(t => s.uuid === t.uuid)?.email ?? '',
                notFoundBranchCodes: this.getGiftUserNotFoundBranchCodes(s),
                filterValue: 'usersWithoutBranch'
            }));

        this.dataSource = new MatTableDataSource([
            ...this.filteredBranches,
            ...this.usersWithoutBranch,
            ...this.filteredLevels
        ]);
        this.dataSource.filter = this.filterControl.value;
        this.filterControl.valueChanges.subscribe(s => {
            this.dataSource.filter = s;
            switch (s) {
            case 'branchesWithMultipleManagers':
            case 'branchesWithoutManager':
            case 'branchesWithoutUser':
                this.displayedColumns = ['id', 'externalId', 'name', 'levelEntity'];
                break;
            case 'usersWithoutBranch':
                this.displayedColumns = ['id', 'uuid', 'email', 'notFoundBranchCodes'];
                break;
            case 'levelsWithoutBranch':
            case 'levelsWithoutUser':
                this.displayedColumns = ['id', 'levelEntity'];
                break;
            }
        });
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    private setLevels() {
        for (const s of this.levels) {
            s['levelEntity'] = this.levels.find(t => t.id === s.id)?.entity ?? '';
            const levelIndex = this.levels.findIndex(t => t.id === s.id);

            // Level without branch (excepted the highest position e.g. HQ and the lowest e.g. gift users)
            if (s.position > 0 && levelIndex > 0 && this.branches.every(t => s.id !== t.levelId)) {
                this.levelsWithoutBranch.push({...s, levelEntity: s['levelEntity'], filterValue: 'levelsWithoutBranch'});
            }
            if (levelIndex > 0 && this.giftUsers.every(t => s.id !== t.levelId)) {
                // Hierarchical level without user (excepted the highest position e.g. HQ)
                this.levelsWithoutUser.push({...s, levelEntity: s['levelEntity'], filterValue: 'levelsWithoutUser'});
            }
        }
    }

    private removeDuplicatedElement<T>(value: T): T {
        let index: number;
        return (value as any[]).reduce((acc, curr) => {
            index = acc.findIndex((s: any) => s.email === curr.email && s.externalId === curr.externalId);
            if (index > -1) acc[index].filterValue += curr.filterValue;
            else acc.push(curr);
            return acc;
        }, []);
    }

    private getGiftUsersByBranchCode(branchCode:string): GiftUser[] {
        return this.giftUsers.filter(s => s.branchList.includes(parseInt(branchCode)));
    }

    private getGiftUserNotFoundBranchCodes(user: GiftUser): number[] {
        return user.branchList.filter(c => this.branches.some(t => parseInt(t.externalId) !== c));
    }
}
