import {Component, OnInit} from '@angular/core';
import {MsServicesOperationsService, Operation, UserService} from '@isifid/core';
import {GiftService} from '../../../../shared/services/gift.service';

@Component({
    selector: 'app-automation-operations-list',
    templateUrl: './list.component.html'
})
export class AutomationOperationsListComponent implements OnInit {
    loading = true;
    operations: Array<Operation> = [];
    displayedColumns = ['name', 'startDate', 'endDate', 'status', 'actions'];

    constructor(
        private readonly msServicesOperationsService: MsServicesOperationsService,
        private readonly giftService: GiftService,
        public readonly userService: UserService
    ) {
    }

    ngOnInit() {
        this.msServicesOperationsService.getOperations([], {clientId: this.giftService.client.id, budget: 1})
            .subscribe((operations) => {
                this.operations = operations;
                this.loading = false;
            });
    }

    getStatus(status): string {
        switch (status) {
            case 'active':
                return 'En cours';
            case 'expired':
                return 'Expiré';
            case 'draft':
                return 'Non actif';
            default:
                return '';
        }
    }
}
