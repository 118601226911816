<div class="container-fluid p-5">
    @if (rule) {
        <div class="row my-3 text-center">
            @if (createMode) {
                <h2>Création d'une règle pour l'opération {{operation.name}}</h2>
            } @else {
                <h2>Mise à jour d'une règle pour l'opération {{operation.name}}</h2>
            }
        </div>
        <div class="row my-3">
            <form [formGroup]="form" class="mt-5 ps-4">
                <div class="row">
                    <div class="col-sm-5 col-form-label">Evénement</div>
                    <div class="col-sm-7">
                        <mat-form-field appearance="fill">
                            <mat-select formControlName="event">
                                @for (event of events; track event) {
                                    <mat-option [value]="event">{{event}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-5 col-form-label check-box">Rôles cibles</div>
                    <div class="col-sm-7" formArrayName="rolesTargeted">
                        @for (role of roles; track role) {
                            <mat-checkbox formControlName="{{role}}">{{role}}</mat-checkbox>
                            <br />
                        }
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-5 col-form-label check-box">Positions cibles</div>
                    <div class="col-sm-7" formArrayName="positionsTargeted">
                        @for (level of this.giftService.hierarchicalLevels; track level) {
                            <mat-checkbox formControlName="{{level.position}}">{{level.title}}</mat-checkbox>
                            <br />
                        }
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-5 col-form-label">ID Utilisateurs cibles (séparés par des virgules)</div>
                    <div class="col-sm-7">
                        <input matInput formControlName="giftUsersTargeted" type="text"/>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-sm-5 col-form-label">Template</div>
                    <div class="col-sm-7">
                        <mat-form-field appearance="fill">
                            <mat-select formControlName="templateId">
                                @for (template of templates; track template) {
                                    <mat-option [value]="template.id">{{template.name}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-5 col-form-label">Statut</div>
                    <div class="col-sm-7">
                        <mat-form-field appearance="fill">
                            <mat-select formControlName="status">
                                <mat-option value='draft'>Brouillon</mat-option>
                                <mat-option [disabled]="createMode" value='active'>Actif</mat-option>
                                <mat-option [disabled]="createMode" value='disabled'>Désactivé</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <p class="col-sm-5 col-form-label">Planification</p>
                    <div class="col-sm-7">
                        <mat-form-field appearance="fill">
                            <mat-label>Plafinication</mat-label>
                            <input matInput formControlName="schedule" [matDatepicker]="schedule"/>
                            <mat-datepicker-toggle matIconSuffix [for]="schedule" />
                            <mat-datepicker #schedule />
                        </mat-form-field>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-5">
                        <button class="btn btn-validate" routerLink="/admin/automation/operations/{{operation.id}}">Retour</button>
                    </div>
                    <div class="col-sm-7 text-end">
                        <button (click)="submit()" class="btn btn-validate" type="submit">
                            Valider
                        </button>
                    </div>
                </div>
            </form>
        </div>
    }
</div>
