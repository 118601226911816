@if (isLoading) {
    <h1 class="text-center mt-5">
        <ng-container [ngTemplateOutlet]="isLoadingTemp" />
    </h1>
} @else {
    <div class="container-fluid py-5">
        <div class="row justify-content-center">
            <div class="col-md-10 col-12">
                <div class="my-3">
                    <button class="btn btn-validate" (click)="goBack()">Retour</button>
                </div>
                @if (!createMode) {
                    <h2 class="text-center my-4">
                        Programme de récompense conseillers <br>
                    </h2>
                    <p class="mb-4">
                        Opération concernée :  {{nameOfOperationToRewardFor}}<br>
                        Montant de récompense par conseiller si atteinte des objectifs : {{amountOfOfferToRewardOn | currency: 'EUR':'symbol':'1.0-0':'fr-FR'}} <br>
                        Statut : {{rewardProgramStatus}}
                    </p>
                } @else {
                    <h2 class="text-center my-4">
                        Création d'un programme
                    </h2>
                }

                @if (!internalOperations.length) {
                    <p class="text-center py-5">
                        <ng-container [ngTemplateOutlet]="noInternalOperationsTemp" />
                    </p>
                }

                @if (rewardProgramForm && internalOperations.length) {
                    <form [formGroup]="rewardProgramForm" (ngSubmit)="save()">
                        <div class="row my-2">
                            <div class="col-sm-4 col-form-label">Dates du programme</div>
                            <mat-form-field appearance="fill" class="col-sm-8">
                                <mat-date-range-input [rangePicker]="picker">
                                    <input matStartDate formControlName="startAt">
                                    <input matEndDate formControlName="endAt">
                                </mat-date-range-input>
                                <mat-datepicker-toggle matSuffix [for]="picker" />
                                <mat-date-range-picker #picker />

                                @if (rewardProgramForm.controls.endAt.hasError('endAtError')) {
                                    <mat-error>
                                        La date de début et de fin doivent être différents.
                                    </mat-error>
                                }
                            </mat-form-field>
                        </div>
                        @if (createMode) {
                            <div class="row my-2" [class.align-items-baseline]="!internalOperations.length">
                                <div class="col-sm-4 col-form-label">Récompense</div>
                                <div class="col-sm-8">
                                    @if (internalOperations.length) {
                                        <div class="row align-items-center">
                                            <mat-form-field appearance="fill" class="col-sm-6">
                                                <mat-label>Opération(s) interne(s)</mat-label>
                                                <mat-select formControlName="operationToRewardOn">
                                                    @for (item of internalOperations; track item.id) {
                                                        <mat-option [value]="item.id">
                                                            {{item.name}}
                                                        </mat-option>
                                                    }
                                                </mat-select>
                                            </mat-form-field>
                                            @if (offers.length) {
                                                <mat-form-field appearance="fill" class="col-sm-6">
                                                    <mat-label>Offres</mat-label>
                                                    <mat-select formControlName="offerToRewardOn">
                                                        @for (item of offers; track item.id) {
                                                            <mat-option [value]="item.id">
                                                                {{item.name}} - {{item.amount}}&euro;
                                                            </mat-option>
                                                        }
                                                    </mat-select>
                                                </mat-form-field>
                                            } @else if (rewardProgramForm?.controls.operationToRewardOn.value) {
                                                <p class="col-sm-6">
                                                    Merci de créer une offre pour cette opération
                                                </p>
                                            }
                                        </div>
                                    } @else {
                                        <ng-container [ngTemplateOutlet]="noInternalOperationsTemp" />
                                    }
                                </div>
                            </div>
                        }
                        @if (createMode) {
                            <div class="row my-2" [class.align-items-baseline]="!operations.length">
                                <div class="col-sm-4 col-form-label">Opération</div>
                                @if (operations.length) {
                                    <mat-form-field appearance="fill" class="col-sm-8">
                                        <mat-select formControlName="operationToRewardFor">
                                            @for (item of operations; track item.id) {
                                                <mat-option [value]="item.id">
                                                    {{item.name}}
                                                </mat-option>
                                            }
                                        </mat-select>
                                    </mat-form-field>
                                } @else {
                                    <p>Il n'y a pas d'opération éligible pour le challenge</p>
                                }
                            </div>
                        }
                        @if (rewardProgramForm.controls.operationToRewardFor.value) {
                            <div class="row my-2">
                                <div class="col-sm-4 col-form-label">
                                    Nombre de {{isSponsorshipOperation ? 'parrainages validés' : 'récompenses'}}
                                </div>
                                <mat-form-field appearance="fill" class="col-sm-8">
                                    <input matInput formControlName="threshold1" type="number" min="0">
                                </mat-form-field>
                            </div>
                        }
                        @if (isSponsorshipOperation) {
                            <div class="row my-2">
                                <div class="col-sm-4 col-form-label">Nombre de parrains inscrits</div>
                                <mat-form-field appearance="fill" class="col-sm-8">
                                    <input matInput formControlName="threshold2" type="number" min="0">
                                </mat-form-field>
                            </div>
                        }
                        @if (!createMode) {
                            <div class="row my-2">
                                <div class="col-sm-4 col-form-label">Statut</div>
                                <mat-form-field appearance="fill" class="col-sm-8">
                                    <mat-select formControlName="status">
                                        <mat-option value="active">En cours</mat-option>
                                        <mat-option value="expired">Terminé</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        }

                        <div>
                            <button [disabled]="!rewardProgramForm.controls.offerToRewardOn.value"
                                    class="btn btn-validate float-end"
                                    type="submit"
                                    style="min-width: 145px">
                                @if (!isSaving) { Sauvegarder }
                                @else {
                                    <ng-container [ngTemplateOutlet]="isLoadingTemp" />
                                }
                            </button>
                        </div>
                    </form>
                }
            </div>
        </div>
    </div>
}

<!--Templates-->
<ng-template #isLoadingTemp>
    <em class="fas fa-spinner fa-spin"></em>
</ng-template>
<ng-template #noInternalOperationsTemp>
    <p>Merci de créer une opération au statut Interne afin de créer un programme de récompense conseiller.</p>
</ng-template>
