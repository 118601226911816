<div class="container-fluid min-vh-100">
    @if (loading) {<mat-spinner class="mx-auto" />}
    @else {
        <div class="px-5">

            <h1 class="my-5 text-center">{{client.name | titlecase}}</h1>

            <div class="row justify-content-center">
                <form [formGroup]="clientForm" class="col-lg-6">
                    <mat-form-field appearance="fill" class="my-3">
                        <mat-label>Nom</mat-label>
                        <input matInput
                               formControlName="name"
                               id="name"
                               type="text"/>
                    </mat-form-field>
                    <mat-form-field appearance="fill">
                        <mat-label>Informations légales </mat-label>
                        <textarea matInput formControlName="legalNotices" id="legalNotices" rows="5" type="text"></textarea>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="my-3">
                        <mat-label>URL site récompense</mat-label>
                        <input matInput
                               formControlName="domain"
                               id="domain"
                               type="text"/>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="my-3">
                        <mat-label>Téléphone</mat-label>
                        <input matInput
                               formControlName="phone"
                               id="phone"
                               maxlength="12"
                               type="tel"/>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="my-3">
                        <mat-label>SMS</mat-label>
                        <input matInput
                               formControlName="smsLabel"
                               id="smsLabel"
                               type="text"/>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="my-3">
                        <mat-label>Adresse email client chez Isifid</mat-label>
                        <input matInput
                               formControlName="email"
                               id="email"
                               type="email"/>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="my-3">
                        <mat-label>Nom expéditeur Email</mat-label>
                        <input matInput
                               formControlName="emailSenderName"
                               id="emailSenderName"
                               type="text"/>
                    </mat-form-field>
                    <mat-form-field appearance="fill" class="my-3">
                        <mat-label>Signature Email</mat-label>
                        <input matInput
                               formControlName="emailSignature"
                               id="emailSignature"
                               type="text"/>
                    </mat-form-field>
                    <button (click)="update()" class="btn btn-validate px-4 py-2 mb-5" type="submit"> Sauvegarder</button>
                </form>
            </div>
        </div>
    }
</div>
