import {ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {Budget, MsServicesGiftService, Operation} from '@isifid/core';
import {StatsService} from '../../../../shared/services/stats.service';
import {forkJoin} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {GiftService} from '../../../../shared/services/gift.service';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {GiftNetworkService} from '../../../../shared/services/gift-network.service';

@Component({
    selector: 'app-tracking',
    templateUrl: './tracking.component.html'
})
export class TrackingComponent implements OnInit, OnChanges {
    colNames: any;
    dataSource: MatTableDataSource<any> = new MatTableDataSource<unknown>([]);
    displayedColumns: Array<string> = [];

    @Input() budget: Budget;
    @Input() formattedStat: any;
    @Input() operation: Operation;
    // Show budget data if true
    @Input() showBudgetData: boolean;
    @Input() trackingLevel: string;
    // Trigger download from parent component
    @Output() downloadEvent = new EventEmitter<any>();

    @ViewChild('tableSort') sort: MatSort;
    @ViewChild('paginator') matPaginator: MatPaginator;

    constructor(
        private cdRef: ChangeDetectorRef,
        private readonly giftService: GiftService,
        private readonly giftNetworkService: GiftNetworkService,
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly statsService: StatsService
    ) {
    }

    ngOnInit(): void {
        this.colNames = this.statsService.getTrackingColNames(this.trackingLevel, (this.showBudgetData && !!this.budget));
        this.displayedColumns = this.statsService.getTrackingOperationsDisplayedColumns(this.trackingLevel, (this.showBudgetData && !!this.budget));
        // Init
        if (!this.budget) this.setDataNoBudget();
        else if (this.trackingLevel === 'global') this.setDataGlobal();
        else this.setData();
    }

    ngOnChanges(e): void {
        // OnChanges to detect changes on the input and hide/show the budget data
        if (e.showBudgetData && !e.showBudgetData.firstChange) {
            this.showBudgetData = e.showBudgetData.currentValue;
            this.ngOnInit();
        }
    }

    private setDataGlobal(): void {
        this.dataSource = new MatTableDataSource<unknown>([this.setFirstRow()]);
        this.initTableSortAndPaginator();
    }

    private setData(): void {
        forkJoin([
            this.msServicesGiftService.getCreditsByBudgetId(this.budget.id),
            this.msServicesGiftService.getCreditsHistoryByBudgetId(this.budget.id)
        ]).subscribe({
            next: ([credits, creditsHistory]) => {
                const data: any[] = credits;
                data.forEach((row) => {
                    const branch = this.giftNetworkService.branches.find((b) => b.externalId == this.giftService.convertBranchCodeToString(row.entityId));
                    row.entityExternalId = row.entityId;
                    row.entityName = branch?.name;

                    // Get all creditHistory where amount < 0 with id = credit.id
                    const creditsHistoryNegatives = creditsHistory.filter((ch) => ch.amount < 0 && ch.creditId == row.id);
                    // Get all creditHistory where amount > 0 with id = credit.id
                    const creditsHistoryPositives = creditsHistory.filter((ch) => ch.amount > 0 && ch.creditId == row.id);

                    row.amountGranted = creditsHistoryPositives.reduce((acc, ch) => acc + ch.amount, 0);
                    row.amountExpended = Math.abs(creditsHistoryNegatives.reduce((acc, ch) => acc + ch.amount, 0));
                    row.amountExpendedPercent = (row.amountExpended / row.amountGranted) * 100;
                    row.amountRemaining = row.amountGranted - row.amountExpended;
                    // Number of rewards
                    row.export = row.amountExpended ? creditsHistoryNegatives.length : 0;
                });
                // First row
                data.unshift(this.setFirstRow('Toutes les agences'));
                this.dataSource = new MatTableDataSource<unknown>(data);
                this.initTableSortAndPaginator();
            }
        });
    }

    private setDataNoBudget() {
        this.dataSource = new MatTableDataSource<unknown>([{
            // We add the total amount from each stats
            amountExpended: this.formattedStat.reduce((acc, stat) => acc + stat.amount, 0) || 0,
            export: this.formattedStat.length || 0
        }]);
    }
    private initTableSortAndPaginator(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.matPaginator;
        this.cdRef.detectChanges();
    }

    private setFirstRow(entityName?: string): any {
        return {
            entityName: entityName || '',
            amountGranted: this.budget.amountTotal,
            amountExpended: (this.budget.amountTotal - this.budget.amountRemaining),
            amountExpendedPercent: ((this.budget.amountTotal - this.budget.amountRemaining) / this.budget.amountTotal) * 100,
            amountRemaining: this.budget.amountRemaining,
            export: this.formattedStat.length
        };
    }
}
