import {ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {Branch, Budget, Credits, CreditsHistory, HierarchicalLevel, MsDataService, MsServicesGiftService, Operation} from '@isifid/core';
import {GiftService} from '../../../../shared/services/gift.service';
import {OperationsService} from '../../../../shared/services/operations.service';
import {Router} from '@angular/router';
import {forkJoin} from 'rxjs';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';

@Component({
    selector: 'app-budgets-tracking',
    templateUrl: './tracking.component.html'

})
export class BudgetTrackingComponent {
    loading: boolean;
    budget: Budget;
    credits: Array<Credits>;
    creditsHistory: Array<CreditsHistory>;
    dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
    branches: Array<Branch>;
    displayedColumns: Array<string>;
    operation: Operation;
    rows: Array<any>;
    level: HierarchicalLevel;

    constructor(
        private cdRef: ChangeDetectorRef,
        private giftService: GiftService,
        private operationsService: OperationsService,
        private msServicesGiftService: MsServicesGiftService,
        private msDataService: MsDataService,
        private router: Router
    ) {
        this.loading = true;
        const urlParams = new URLSearchParams(window.location.search);
        const budgetId = urlParams.get('id');
        if (!budgetId) this.router.navigate(['/admin/budgets/list']).then();

        forkJoin([
            this.msServicesGiftService.getBudget(budgetId),
            this.msServicesGiftService.getCreditsByBudgetId(budgetId),
            this.msServicesGiftService.getCreditsHistoryByBudgetId(budgetId),
            this.msDataService.getBranchesByClientId(this.giftService.client.id)
        ]).subscribe({
            next: ([budget, credits, creditsHistory, branches]) => {
                this.budget = budget;
                this.credits = credits;
                this.creditsHistory = creditsHistory;
                this.branches = branches;

                this.level = this.giftService.hierarchicalLevels.find(l => l.id === this.budget.levelId);
                if (this.level.position == 0) {
                    this.displayedColumns = ['entityId', 'amountGranted', 'amountRemaining', 'amountUsed', 'updatedAt'];
                } else {
                    this.displayedColumns = ['entityId', 'branch', 'amountGranted', 'amountRemaining', 'amountUsed', 'updatedAt'];
                }

                const operations = this.operationsService.getOperations(false, false).filter(o => o.id === this.budget.operationId);
                if (!operations.length) this.router.navigate(['/admin/budgets/list']).then();

                this.operation = operations[0];
                this.buildRows();
                this.loading = false;
            }
        });
    }

    @ViewChild(MatSort) set content(sort: MatSort) {
        this.dataSource.sort = sort;
    }

    buildRows() {
        this.rows = [];
        const creditHistoryAdded = this.creditsHistory.filter((ch) => ch.amount > 0);

        for (const credit of this.credits) {
            const tmp = {
                entityId: credit.entityId,
                branch: '',
                amountGranted: 0,
                amountRemaining: credit.amountRemaining,
                amountUsed: 0,
                amountUsedP: 0,
                updatedAt: credit.updatedAt
            };
            const creditHistory = creditHistoryAdded.find((ch) => ch.creditId === credit.id);
            if (!creditHistory) continue;

            tmp.amountGranted = creditHistory.amount;
            tmp.amountUsed = tmp.amountGranted - tmp.amountRemaining;
            tmp.amountUsedP = Math.round((tmp.amountGranted - tmp.amountRemaining) / tmp.amountGranted * 100);

            const branch = this.branches.find((b) => b.externalId == tmp.entityId);
            if (branch) tmp.branch = branch.name;

            this.rows.push(tmp);
        }
        this.dataSource = new MatTableDataSource<unknown>(this.rows);
        this.cdRef.detectChanges();
    }

    getNumberOfMonth() {
        const start = new Date(this.budget.startAt);
        const end = new Date(this.budget.endAt);
        return (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
    }

    getElapsedTime() {
        const start = new Date(this.budget.startAt);
        const end = new Date(this.budget.endAt);
        const now = new Date();
        let string = Math.round((now.getTime() - start.getTime()) / (end.getTime() - start.getTime()) * 100) + '%';
        if (start.getTime() > now.getTime()) string = string + ' (l\'opération n\'a pas encore commencé)';
        return string;
    }
}
