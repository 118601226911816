<div class="container-fluid mt-4 py-3 py-md-5">

    <div class="row justify-content-between align-items-end">
        <div class="col-md-6 col-lg-auto">
            <p>Entité avec plus d'un manager : {{branchesWithMultipleManagers.length}}</p>
            <p>Entité sans manager : {{branchesWithoutManager.length}}</p>
            <p>Entités sans utilisateur : {{branchesWithoutUser.length}}</p>
            <p>Utilisateurs sans entité existante : {{usersWithoutBranch.length}}</p>
            <p>Échelon sans entité : {{levelsWithoutBranch.length}}</p>
            <p>Échelon sans utilisateur : {{levelsWithoutUser.length}}</p>
        </div>
        <div class="col-md-6 col-lg-4 mt-4 mt-md-0">
            <mat-form-field appearance="fill">
                <mat-label>Filter</mat-label>
                <mat-select [formControl]="filterControl">
                    <mat-option value="branchesWithMultipleManagers">Entité avec plus d'un manager</mat-option>
                    <mat-option value="branchesWithoutManager">Entité sans manager</mat-option>
                    <mat-option value="branchesWithoutUser">Entités sans utilisateur</mat-option>
                    <mat-option value="usersWithoutBranch">Utilisateurs sans entité existante</mat-option>
                    <mat-option value="levelsWithoutBranch">Échelons sans entité</mat-option>
                    <mat-option value="levelsWithoutUser">Échelons sans utilisateur</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="responsive-table">
        <div class="responsive-table-body">
            <table [dataSource]="dataSource" aria-describedby="monitoring" class="mb-1"
                   mat-table matSort>
                <ng-container class="py-5" matColumnDef="id">
                    <th *matHeaderCellDef id="id" mat-header-cell mat-sort-header>
                        @switch (filterControl.value) {
                            @case('branchesWithMultipleManager') {ID entité}
                            @case('branchesWithoutManager') {ID entité}
                            @case('branchesWithoutUser') {ID entité}
                            @case('levelsWithoutBranch') {ID échelon}
                            @case('levelsWithoutUser') {ID échelon}
                            @case('usersWithoutBranch') {ID d'utilisateur gift}
                            @default {ID}
                        }
                    </th>
                    <td *matCellDef="let network" mat-cell>
                        {{network.id}}
                    </td>
                </ng-container>

                <ng-container class="py-5" matColumnDef="externalId">
                    <th *matHeaderCellDef id="externalId" mat-header-cell mat-sort-header>Code entité</th>
                    <td *matCellDef="let network" mat-cell>
                        {{network.externalId}}
                    </td>
                </ng-container>

                <ng-container class="py-5" matColumnDef="uuid">
                    <th *matHeaderCellDef id="userId" mat-header-cell mat-sort-header>UUID</th>
                    <td *matCellDef="let network" mat-cell>
                        {{network.uuid}}
                    </td>
                </ng-container>

                <ng-container class="py-5" matColumnDef="name">
                    <th *matHeaderCellDef id="name" mat-header-cell mat-sort-header>Nom entité</th>
                    <td *matCellDef="let network" mat-cell>
                        {{network.name}}
                    </td>
                </ng-container>

                <ng-container class="py-5" matColumnDef="email">
                    <th *matHeaderCellDef id="email" mat-header-cell mat-sort-header>Email</th>
                    <td *matCellDef="let network" mat-cell>
                        {{network.email}}
                    </td>
                </ng-container>

                <ng-container class="py-5" matColumnDef="levelEntity">
                    <th *matHeaderCellDef id="levelEntity" mat-header-cell mat-sort-header>Entité de l'échelon</th>
                    <td *matCellDef="let network" mat-cell>
                        {{network.levelEntity}}
                    </td>
                </ng-container>

                <ng-container class="py-5" matColumnDef="notFoundBranchCodes">
                    <th *matHeaderCellDef id="notFoundBranchCodes" mat-header-cell mat-sort-header>Code(s) entité inexistant</th>
                    <td *matCellDef="let network" mat-cell>
                        {{network.notFoundBranchCodes}}
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                <tr *matNoDataRow>
                    <td [colSpan]="displayedColumns.length" class="text-center py-4">Aucune données</td>
                </tr>
            </table>
        </div>
        <mat-paginator
                [hidden]="dataSource?.filteredData?.length < 10"
                [pageSizeOptions]="[10, 25, 50, 100]"
                aria-label="Sélectionner la page des réseau"
                showFirstLastButtons />
    </div>
</div>
