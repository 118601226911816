<div class="container-fluid p-5">
    @if (budget) {
        <div class="row my-3 text-center">
            @if (!budget.id) {
                <h1>Lancement d'une opération <br>avec budget agence</h1>
            } @else {
                <h1>Mise à jour d'un budget agence</h1>
            }
        </div>
        <div class="row my-3">

            <div class="col p-5">
                <h2>Paramètres</h2>
                <form [formGroup]="form" class="mt-5 ps-4">
                    <div class="row">
                        <div class="col-sm-5 col-form-label check-box">Opération</div>
                        <div class="col-sm-7">
                            <mat-form-field appearance="fill">
                                <mat-select formControlName="operationId">
                                    @for (operation of operations; track operation.id) {
                                        <mat-option [value]="operation.id">{{operation.name}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <p class="col-sm-5 col-form-label">Plafond (en valeur faciale)</p>
                        <div class="col-sm-7">
                            <mat-form-field appearance="fill">
                                <mat-label>Plafond global (en valeur faciale)</mat-label>
                                <input matInput
                                       formControlName="amountTotal"
                                       id="amountTotal"
                                       min="0"
                                       step="1000"
                                       type="number"
                                       (blur)="updateAmountInEuro()"
                                />
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <p class="col-sm-12 text-end">Soit {{amountTotalInEur}} en euros</p>
                    </div>

                    <div class="row">
                        <div class="col-sm-5 col-form-label check-box">Échelon</div>
                        <div class="col-sm-7">
                            <mat-form-field appearance="fill">
                                <mat-select formControlName="levelId">
                                    <mat-option [value]="">Tout les utilisateurs</mat-option>
                                    @for (level of levels; track level.id) {
                                        <mat-option [value]="level.id">{{level.entity}}</mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-5 col-form-label check-box">Méthode d'attribution</div>
                        <div class="col-sm-7">
                            <mat-form-field appearance="fill">
                                <mat-select formControlName="attributionMethod">
                                    <mat-option value="auto">Automatique</mat-option>
                                    <mat-option value="manual">Manuel</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <p class="col-sm-5 col-form-label">Montant par entité (en valeur faciale)</p>
                        <div class="col-sm-7">
                            <mat-form-field appearance="fill">
                                <mat-label>Montant par entité (en valeur faciale)</mat-label>
                                <input matInput
                                       formControlName="amountPerUser"
                                       id="amountPerUser"
                                       min="0"
                                       step="10"
                                       type="number"/>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <p class="col-sm-5 col-form-label">Démarre le</p>
                        <div class="col-sm-7">
                            <mat-form-field appearance="fill">
                                <mat-label>Démarre le</mat-label>
                                <input matInput formControlName="startAt" id="startAt" [matDatepicker]="startAt"/>
                                <mat-datepicker-toggle matIconSuffix [for]="startAt" />
                                <mat-datepicker #startAt />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <p class="col-sm-5 col-form-label">Se termine le</p>
                        <div class="col-sm-7">
                            <mat-form-field appearance="fill">
                                <mat-label>Se termine le</mat-label>
                                <input matInput formControlName="endAt" id="endAt" [matDatepicker]="endAt"/>
                                <mat-datepicker-toggle matIconSuffix [for]="endAt" />
                                <mat-datepicker #endAt />
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-form-label check-box"> Statut </div>
                        <div class="col-sm-7">
                            <mat-form-field appearance="fill">
                                <mat-select formControlName="status">
                                    <mat-option value="draft">Non actif</mat-option>
                                    <mat-option value="active">En cours</mat-option>
                                    <mat-option value="expired">Expiré</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-sm-7 offset-sm-5">
                            <button (click)="submit()" class="btn btn-validate d-block mt-2" type="submit">
                                Valider
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            <div class="col p-5">
                <h2>Projections</h2>
                <p class="mt-5 ps-4">
                    Vous avez défini un plafond de
                    {{amountTotalInEur | currency:'EUR':'symbol':'1.0-0':'fr-FR' }}
                    pour distribuer
                    {{form.get('amountTotal').value | currency:'EUR':'symbol':'1.0-0':'fr-FR' }}
                    de valeur faciale.<br><br>
                    @if (form.get('amountPerUser').value && form.get('attributionMethod').value === 'auto') {
                        <span>
                            Chaque entité disposera d'un budget de
                            {{form.get('amountPerUser').value | currency:'EUR':'symbol':'1.0-0':'fr-FR' }}
                            de valeur faciale à distribuer.
                        </span>
                    }
                </p>
            </div>

        </div>
    }
</div>
