import {Injectable} from '@angular/core';
import {MsCoreService, MsDataService} from '@isifid/core';
import {GiftService} from './gift.service';
import {forkJoin} from 'rxjs';
import {GiftUserService} from './gift-user.service';

@Injectable({
    providedIn: 'root'
})
export class GiftNetworkService {
    branches: Array<any> = [];
    branchHierarchy = [];
    giftUsers: any[];

    constructor(
        private readonly giftService: GiftService,
        private readonly giftUserService: GiftUserService,
        private readonly msCoreService: MsCoreService,
        private readonly msDataService: MsDataService,
    ) {
        this.initBranches();
    }

    public initGiftUsers() {
        this.giftUsers = [];
        forkJoin([
            this.giftUserService.getGiftUsersByClientId(this.giftService.settings.clientId),
            this.msCoreService.getUsers([], {clientId: this.giftService.settings.clientId})
        ]).subscribe({
            next: ([giftUsers, users]) => {
                giftUsers.forEach((u) => {
                    const user = users.find((user) => user.uuid === u.uuid);
                    if (!user) return;
                    const row = {
                        uuid: u.uuid,
                        email: user.email,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        levelId: u.levelId,
                        level: this.giftService.hierarchicalLevels.find(l => l.id === u.levelId),
                        giftUserId: u.id,
                        branchList: u.branchList
                    };
                    this.giftUsers.push(row);
                });
            }, error: () => console.error('error while getting giftUsers ' + this.giftService.settings.clientId)
        });
    }

    initBranches() {
        this.msDataService.getBranchesByClientId(this.giftService.client.id).subscribe({
            next: (branches) => this.branches = branches,
            error: (e) => {
                // If the api returns a 404, it means that the client has no branches
                if (e && e.status === 404) this.branches = [];
                this.initGiftUsers();
            }, complete: () => {
                this.initGiftUsers();
                this.initHierarchy();
            }
        });
    }

    private initHierarchy() {
        this.branchHierarchy = [];
        // Get branch hierarchy for each branch
        if (this.branches.length) this.branches.forEach((branch) => this.setBranchHierarchy(branch.externalId));
        else this.branchHierarchy = [];
    }

    // Get branch hierarchy from branch code
    public setBranchHierarchy(branchCode: number) {
        // Check if branch code is empty
        if (!branchCode) return;
        // Get branch with branch code
        const branch = this.branches.find(b => b.externalId === branchCode);
        if (!branch) return;
        else {
            const usersByBranchCode = this.giftUsers.filter(g => {
                const branchCodeAsNumber = Number(branchCode);
                return g.branchList?.includes(branchCodeAsNumber);
            });
            const branchUsers = usersByBranchCode.map(u => ({
                uuid: u.uuid,
                email: u.email,
                levelId: u.levelId,
                level: u.level,
                giftUserId: u.giftUserId,
                branchList: u.branchList,
                branchName: branch.name,
                branchCode
            }));
            // Sort branchUsers by level.position
            branchUsers.sort((a, b) => a.level?.position - b.level?.position);
            // Save branchUsers hierarchy
            this.branchHierarchy.push({branchCode: branchCode, branchUsers: branchUsers});
        }
    }

    public getTitle(giftUserEmail, users) {
        const user = users.find(a => a.email === giftUserEmail);
        const level = this.giftService.hierarchicalLevels.find(l => l.id === user?.levelId);
        if (!level) return '';
        return level.title;
    }
}
