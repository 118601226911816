<div class="container-fluid min-vh-100">

    <div class="col col-md-6 mx-auto">

        <h1 class="my-5">Création d’une opération de Recommandation/ Parrainage</h1>

        <form [formGroup]="formGroup" (ngSubmit)="submit()" class="mt-3 mb-5">
            <!--Sponsor-->
            <div>
                <h2>Personne qui recommande (Parrain) </h2>
                <mat-divider class="my-4" />

                <section class="px-3 my-4">
                    <h3 class="mb-4">Profil</h3>
                    <span>Les restrictions sur le critère de l’âge nuisent considérablement aux performances du programme</span>
                    <mat-form-field class="mt-3 mb-2" appearance="fill">
                        <mat-label>Option d'âge</mat-label>
                        <mat-select formControlName="sponsorAgeOption">
                            @for (item of ageOptions; track item.value) {
                                <mat-option [value]="item.value">
                                    {{item.label}}
                                </mat-option>
                            }
                        </mat-select>

                        <mat-error>
                            Option d'âge est obligatoire.
                        </mat-error>
                    </mat-form-field>

                    @if (formGroup.controls.sponsorAgeOption.value !== 0 && formGroup.controls.sponsorAgeOption.valid) {
                        <mat-form-field appearance="fill"
                                        class="mb-2">
                            <mat-label>Âge</mat-label>
                            <input matInput formControlName="sponsorAge" type="number" min="0">
                            <mat-error>
                                {{formGroup.controls.sponsorAge.hasError('required') ? "Âge est obligatoire." : "L'âge doit être supérieur à 0."}}
                            </mat-error>
                        </mat-form-field>
                    }

                    <mat-form-field class="mt-3" appearance="fill">
                        <mat-label>Statut</mat-label>
                        <mat-select formControlName="sponsorStatus">
                            @for (item of statuses; track item.value) {
                                <mat-option [value]="item.value">
                                    {{item.label}}
                                </mat-option>
                            }
                        </mat-select>
                        <mat-error>
                            Statut est obligatoire.
                        </mat-error>
                    </mat-form-field>
                </section>
                <mat-divider class="mt-2 mb-4" />

                <section class="px-3 my-4">
                    <h3 class="mb-4">Récompense</h3>
                    <mat-form-field class="mt-3 mb-2" appearance="fill">
                        <mat-label>Nature</mat-label>
                        <mat-select formControlName="sponsorNature">
                            @for (item of natureOptions; track item.value) {
                                <mat-option [value]="item.value">
                                    {{item.label}}
                                </mat-option>
                            }
                        </mat-select>
                        <mat-error>
                            Nature est obligatoire.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="mt-3 mb-2" appearance="fill">
                        <mat-label>Option de montant</mat-label>
                        <mat-select formControlName="sponsorAmountOption">
                            @for (item of amountOptions; track item.value) {
                                <mat-option [value]="item.value">
                                    {{item.label}} €
                                </mat-option>
                            }
                        </mat-select>
                        <mat-error>
                            Option de montant est obligatoire.
                        </mat-error>
                    </mat-form-field>

                    @if (formGroup.controls.sponsorAmountOption.value === 0) {
                        <mat-form-field appearance="fill" class="mb-2">
                            <mat-label>Montant</mat-label>
                            <input matInput formControlName="sponsorAmount" type="number" min="0">
                            <mat-error>
                                {{formGroup.controls.sponsorAmount.hasError('required') ? "Montant est obligatoire." : "Le montant doit être supérieur à 0."}}
                            </mat-error>
                        </mat-form-field>
                    }

                    <span>Plafonnement déconseillé car le risque est inexistant</span>
                    <mat-form-field class="mt-3 mb-2" appearance="fill">
                        <mat-label>Option de plafonnement du nombre de filleuls</mat-label>
                        <mat-select formControlName="sponsorMaxReferralsOption">
                            @for (item of maxReferralsOptions; track item.value) {
                                <mat-option [value]="item.value">
                                    {{item.label}}
                                </mat-option>
                            }
                        </mat-select>
                        <mat-error>
                            Option de plafonnement du nombre de filleuls est obligatoire.
                        </mat-error>
                    </mat-form-field>

                    @if (formGroup.controls.sponsorMaxReferralsOption.value === 0) {
                        <mat-form-field appearance="fill">
                            <mat-label>Plafonnement du nombre de filleuls</mat-label>
                            <input matInput formControlName="sponsorMaxReferrals" type="number" min="0">
                            <mat-error>
                                {{
                                    formGroup.controls.sponsorMaxReferrals.hasError('required') ?
                                        "Plafonnement du nombre de filleuls est obligatoire." :
                                        "Plafonnement du nombre de filleuls doit être supérieur à 0."
                                }}
                            </mat-error>
                        </mat-form-field>
                    }
                </section>
                <mat-divider class="mt-2 mb-4" />

                <section class="px-3 my-4">
                    <h3 class="mb-4">Inscription sur la plateforme de recommandation</h3>
                    <span>Plusieurs réponses possibles</span>
                    <mat-form-field class="mt-3" appearance="fill">
                        <mat-label>Inscription sur la plateforme de recommandation</mat-label>
                        <mat-select multiple formControlName="sponsorRegistrationTypes">
                            @for (item of registrationTypes; track item.value) {
                                <mat-option [value]="item.value">
                                    {{item.label}}
                                </mat-option>
                            }
                        </mat-select>
                        <mat-error>
                            Inscription sur la plateforme de recommandation est obligatoire.
                        </mat-error>
                    </mat-form-field>
                </section>
                <mat-divider class="mt-2 mb-4" />

                <section class="px-3 my-4">
                    <h3 class="mb-4">Outils de diffusion de la recommandation</h3>
                    <span class="mb-2">La diversité des canaux de diffusion disponibles incite les parrains à recommander leurs proches</span>
                    <mat-form-field class="mt-3" appearance="fill">
                        <mat-label>Outils de diffusion de la recommandation</mat-label>
                        <mat-select multiple formControlName="sponsorDisseminationTools">
                            @for (item of disseminationTools; track item.value) {
                                <mat-option [value]="item.value">
                                    {{item.label}}
                                </mat-option>
                            }
                        </mat-select>
                        <mat-error>
                            Outils de diffusion de la recommandation est obligatoire.
                        </mat-error>
                    </mat-form-field>
                </section>
                <mat-divider class="mt-2 mb-4" />

                <section class="px-3 my-4">
                    <h3 class="mb-4">Animation des personnes inscrites sur la plateforme de recommandation</h3>
                    <mat-checkbox formControlName="sponsorQuarterlyEmailing" class="check-box">
                        Emailing trimestriel faisant part de bonnes pratiques et/ou d’évolutions du programme (récompenses majorées, nouveaux cadeaux, nouvelles approches de recommandation…)
                    </mat-checkbox>
                </section>
            </div>

            <!--Sponsored-->
            <div class="mt-5">
                <h2>Personne qui ouvre un compte suite à recommandation (Filleul)</h2>
                <mat-divider class="my-4" />

                <section class="px-3 my-4">
                    <h3 class="mb-4">Profil</h3>
                    <span>Les restrictions sur le critère de l’âge nuisent considérablement aux performances du programme</span>
                    <mat-form-field class="mt-3 mb-2" appearance="fill">
                        <mat-label>Option d'âge</mat-label>
                        <mat-select formControlName="sponsoredAgeOption">
                            @for (item of ageOptions; track item.value) {
                                <mat-option [value]="item.value">
                                    {{item.label}}
                                </mat-option>
                            }
                        </mat-select>
                        <mat-error>
                            Option d'âge est obligatoire.
                        </mat-error>
                    </mat-form-field>

                    @if (formGroup.controls.sponsoredAgeOption.value !== 0 && formGroup.controls.sponsoredAgeOption.valid) {
                        <mat-form-field appearance="fill">
                            <mat-label>Âge</mat-label>
                            <input matInput formControlName="sponsoredAge" type="number" min="0">
                            <mat-error>
                                {{formGroup.controls.sponsoredAge.hasError('required') ? "Âge est obligatoire." : "L'âge doit être supérieur à 0."}}
                            </mat-error>
                        </mat-form-field>
                    }
                </section>
                <mat-divider class="mt-2 mb-4" />

                <section class="px-3 my-4">
                    <h3 class="mb-4">Récompense</h3>
                    <mat-form-field class="mt-3 mb-2" appearance="fill">
                        <mat-label>Nature</mat-label>
                        <mat-select formControlName="sponsoredNature">
                            @for (item of natureOptions; track item.value) {
                                <mat-option [value]="item.value">
                                    {{item.label}}
                                </mat-option>
                            }
                        </mat-select>
                        <mat-error>
                            Nature est obligatoire.
                        </mat-error>
                    </mat-form-field>

                    <mat-form-field class="mt-3 mb-2" appearance="fill">
                        <mat-label>Option de montant</mat-label>
                        <mat-select formControlName="sponsoredAmountOption">
                            @for (item of amountOptions; track item.value) {
                                <mat-option [value]="item.value">
                                    {{item.label}} €
                                </mat-option>
                            }
                        </mat-select>
                        <mat-error>
                            Option de montant est obligatoire.
                        </mat-error>
                    </mat-form-field>

                    @if (formGroup.controls.sponsoredAmountOption.value === 0) {
                        <mat-form-field appearance="fill">
                            <mat-label>Montant</mat-label>
                            <input matInput formControlName="sponsoredAmount" type="number" min="0">
                            <mat-error>
                                {{formGroup.controls.sponsoredAmount.hasError('required') ? "Montant est obligatoire." : "Le montant doit être supérieur à 0."}}
                            </mat-error>
                        </mat-form-field>
                    }
                </section>
                <mat-divider class="mt-2 mb-4" />

                <section class="px-3 my-4">
                    <h3 class="mb-4">Prise de rendez-vous Filleul</h3>

                    <mat-form-field class="mt-3" appearance="fill">
                        <mat-label>Prise de rendez-vous Filleul</mat-label>
                        <mat-select formControlName="sponsoredAppointmentReferralType">
                            @for (item of appointmentReferralTypes; track item.value) {
                                <mat-option [value]="item.value">
                                    {{item.label}}
                                </mat-option>
                            }
                        </mat-select>
                        <mat-error>
                            Prise de rendez-vous Filleul est obligatoire.
                        </mat-error>
                    </mat-form-field>
                </section>
            </div>

            <!--Additional Information-->
            <div class="mt-5">
                <h2>Informations complémentaires</h2>
                <mat-divider class="my-4" />

                <section class="px-3 my-4">
                    <h3 class="mb-4">Animation Réseau</h3>
                    <span>La réussite des programmes repose toujours sur le recours à ces 3 axes d’animation (plusieurs réponses possibles)</span>
                    <mat-form-field class="mt-3" appearance="fill">
                        <mat-label>Animation Réseau</mat-label>
                        <mat-select multiple formControlName="networkAnimationTypes">
                            @for (item of networkAnimationTypes; track item.value) {
                                <mat-option [value]="item.value">
                                    {{item.label}}
                                </mat-option>
                            }
                        </mat-select>
                        <mat-error>
                            Animation Réseau est obligatoire.
                        </mat-error>
                    </mat-form-field>
                </section>
                <mat-divider class="mt-2 mb-4" />

                <section class="px-3 my-4">
                    <h3 class="mb-4">Spécificités éventuelles</h3>
                    <mat-form-field class="mt-3" appearance="fill">
                        <mat-label>Spécificités éventuelles</mat-label>
                        <textarea matInput formControlName="possibleSpecificities" rows="4"></textarea>
                        <mat-error>
                            Spécificités éventuelles est obligatoire.
                        </mat-error>
                    </mat-form-field>
                </section>
                <mat-divider class="mt-2 mb-4" />

                <section class="px-3 my-4">
                    <h3 class="mb-4">Conditions financières</h3>
                    <ul>
                        <li>Frais de set-up : 5 000 € HT</li>
                        <li>Remise accordée aux clients actifs* : 1 500 € HT</li>
                        <li>*l’établissement recourant à d’autres opérations avec Isifid durant l’année civile en cours.</li>
                    </ul>
                </section>
                <mat-divider class="mt-2 mb-4" />

                <section class="px-3 pb-3">
                    <h3 class="mb-4">Planning</h3>
                    <span>Délai minimum de mise en place : 1 mois</span>

                    <mat-form-field class="mt-3" appearance="fill">
                        <mat-label>Date de lancement souhaitée</mat-label>
                        <input matInput [matDatepicker]="publication_at" [min]="minDateForPublication" formControlName="publication_at">
                        <mat-datepicker-toggle matIconSuffix [for]="publication_at" />
                        <mat-datepicker #publication_at />
                        <mat-error>
                            {{ formGroup.controls.publication_at.hasError('required') ? 'Planning est obligatoire.' : 'Date invalide.' }}
                        </mat-error>
                    </mat-form-field>
                </section>

                <button class="btn btn-validate" type="submit" style="min-width: 105px">
                    @if (!loading) {Valider}
                    @else {
                        <em class="fas fa-spinner fa-spin"></em>
                    }
                </button>

                <div class="mt-3">
                    <span class="small">
                        Dès validation de votre formulaire Recommandation/Parrainage, notre Responsable Recommandation/Parrainage prendra contact avec vous pour planifier la personnalisation de votre programme.
                    </span>
                </div>
            </div>
        </form>

    </div>
</div>

