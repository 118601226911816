<div class="container-fluid min-vh-100 pt-5">
    <!--    sponsorship -->
    @if (!!sponsorshipService.operation && !loading) {
        <div class="my-5">
            <h1>Modification du parrainage online</h1>
        </div>

        <h2 class="p-5 text-center">
            <span aria-hidden="true" class="fas fa-exclamation-triangle mx-2"></span>
            Attention les modifications sont immédiates
            <span aria-hidden="true" class="fas fa-exclamation-triangle mx-2"></span>
        </h2>
        <div class="px-5 py-3 text-center">
            <a [href]="url" target="_blank">
                <button class="btn btn-validate px-4 py-2" type="button">Site Parrainage</button>
            </a>
        </div>
        @if (loading) {<mat-spinner class="mx-auto" />}
        @else {
            <div class="mt-5">
                <p>Cliquer sur une variable pour la copier et pouvoir la coller dans le formulaire</p>
                <p>Survoler sur une variable pour prévisualiser sa valeur</p>
                <mat-chip-listbox aria-label="variable">
                    @for (variable of allVariables; track variable.var) {
                        <mat-chip-option [cdkCopyToClipboard]="variable.var"
                                         [matTooltip]="variable.value"
                                         [value]="variable">
                            {{variable.infos}}
                        </mat-chip-option>
                    }
                </mat-chip-listbox>

                <form (ngSubmit)="submit()"
                      [formGroup]="sponsorshipForm"
                      class="container mt-3 mb-5 px-5 mx-auto">

                    <div class="row g-0 mt-5 mb-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Titre principal</mat-label>
                            <input matInput
                                   formControlName="explanationsTitle1"
                                   id="explanationsTitle1"
                                   type="text"/>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Titre principal</mat-label>
                            <input matInput
                                   disabled
                                   [value]="getPreview(sponsorshipForm.get('explanationsTitle1').value)"
                                   type="text"/>
                        </mat-form-field>
                    </div>
                    <hr>

                    <div class="row g-0 my-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Encadré - Titre</mat-label>
                            <input matInput
                                   formControlName="explanationsTitle2"
                                   id="explanationsTitle2"
                                   type="text"/>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Encadré - Titre</mat-label>
                            <input matInput
                                   disabled
                                   [value]="getPreview(sponsorshipForm.get('explanationsTitle2').value)"
                                   type="text"/>
                        </mat-form-field>
                    </div>

                    <div class="row g-0 my-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Encadré- Texte paragraphe 1</mat-label>
                            <textarea matInput
                                      formControlName="explanations1"
                                      rows="5">
                            </textarea>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Encadré- Texte paragraphe 1</mat-label>
                            <textarea matInput
                                      disabled
                                      [value]="getPreview(sponsorshipForm.get('explanations1').value)"
                                      rows="5">
                            </textarea>
                        </mat-form-field>
                    </div>

                    <div class="row g-0 my-2">
                        <div class="col mx-2 h-100">
                            <p>Encadré- Texte paragraphe 2</p>
                            <quill-editor [modules]="modules"
                                          class="d-block"
                                          debug="error"
                                          formControlName="explanations2"
                                          format="html"
                                          [sanitize]="true">
                            </quill-editor>
                        </div>
                        <div class="col mx-2 h-100">
                            <p>Aperçu : Encadré- Texte paragraphe 2</p>
                            <p [innerHTML]="getPreview(sponsorshipForm.get('explanations2').value)"
                               class="border p-2 mt-3"></p>
                        </div>
                    </div>

                    <div class="row g-0 my-2">
                        <div class="col mx-2 h-100">
                            <p>Encadré- Texte paragraphe 3</p>
                            <quill-editor [modules]="modules"
                                          class="d-block"
                                          debug="error"
                                          formControlName="explanations3"
                                          format="html"
                                          [sanitize]="true">
                            </quill-editor>
                        </div>
                        <div class="col mx-2 h-100">
                            <p>Aperçu : Encadré- Texte paragraphe 3</p>
                            <p [innerHTML]="getPreview(sponsorshipForm.get('explanations3').value)"
                               class="border p-2 mt-3"></p>
                        </div>
                    </div>

                    <hr>
                    <div class="row g-0 mt-5 mb-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Titre des avantages</mat-label>
                            <input matInput
                                   formControlName="explanationsTitle3"
                                   id="explanationsTitle3"
                                   type="text"/>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Titre des avantages</mat-label>
                            <input matInput
                                   disabled
                                   [value]="getPreview(sponsorshipForm.get('explanationsTitle3').value)"
                                   type="text"/>
                        </mat-form-field>
                    </div>

                    <div class="row g-0 my-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Texte avantage 1</mat-label>
                            <textarea matInput
                                      formControlName="explanationsAdvantages1"
                                      rows="5">
                            </textarea>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Texte avantage 1</mat-label>
                            <textarea matInput
                                      disabled
                                      [value]="getPreview(sponsorshipForm.get('explanationsAdvantages1').value)"
                                      rows="5">
                            </textarea>
                        </mat-form-field>
                    </div>

                    <div class="row g-0 my-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Texte avantage 2</mat-label>
                            <textarea matInput
                                      formControlName="explanationsAdvantages2"
                                      rows="5">
                            </textarea>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Texte avantage 2</mat-label>
                            <textarea matInput
                                      disabled
                                      [value]="getPreview(sponsorshipForm.get('explanationsAdvantages2').value)"
                                      rows="5">
                            </textarea>
                        </mat-form-field>
                    </div>

                    <div class="row g-0 my-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Texte avantage 3</mat-label>
                            <textarea matInput
                                      formControlName="explanationsAdvantages3"
                                      rows="5">
                            </textarea>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Texte avantage 3</mat-label>
                            <textarea matInput
                                      disabled
                                      [value]="getPreview(sponsorshipForm.get('explanationsAdvantages3').value)"
                                      rows="5">
                            </textarea>
                        </mat-form-field>
                    </div>

                    <div class="row g-0 my-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Texte avantage 4</mat-label>
                            <textarea matInput
                                      formControlName="explanationsAdvantages4"
                                      rows="5">
                            </textarea>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Texte avantage 4</mat-label>
                            <textarea matInput
                                      disabled
                                      [value]="getPreview(sponsorshipForm.get('explanationsAdvantages4').value)"
                                      rows="5">
                            </textarea>
                        </mat-form-field>
                    </div>
                    <hr>

                    <div class="row g-0 my-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Titre comment ça marche</mat-label>
                            <input matInput
                                   formControlName="explanationsTitle4"
                                   id="explanationsTitle4"
                                   type="text"/>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Titre comment ça marche</mat-label>
                            <input matInput
                                   disabled
                                   [value]="getPreview(sponsorshipForm.get('explanationsTitle4').value)"
                                   type="text"/>
                        </mat-form-field>
                    </div>

                    <div class="row g-0 my-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Titre étape 1</mat-label>
                            <input matInput
                                   formControlName="explanationsHowitworksTitle1"
                                   id="explanationsHowitworksTitle1"
                                   type="text"/>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Titre étape 1</mat-label>
                            <input matInput
                                   disabled
                                   [value]="getPreview(sponsorshipForm.get('explanationsHowitworksTitle1').value)"
                                   type="text"/>
                        </mat-form-field>
                    </div>

                    <div class="row g-0 my-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Texte étape 1</mat-label>
                            <textarea matInput
                                      formControlName="explanationsHowitworks1"
                                      rows="5">
                            </textarea>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Texte étape 1</mat-label>
                            <textarea matInput
                                      disabled
                                      [value]="getPreview(sponsorshipForm.get('explanationsHowitworks1').value)"
                                      rows="5">
                            </textarea>
                        </mat-form-field>
                    </div>

                    <div class="row g-0 my-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Titre étape 2</mat-label>
                            <input matInput
                                   formControlName="explanationsHowitworksTitle2"
                                   id="explanationsHowitworksTitle2"
                                   type="text"/>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Titre étape 2</mat-label>
                            <input matInput
                                   disabled
                                   [value]="getPreview(sponsorshipForm.get('explanationsHowitworksTitle2').value)"
                                   type="text"/>
                        </mat-form-field>
                    </div>

                    <div class="row g-0 my-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Texte étape 2</mat-label>
                            <textarea matInput
                                      formControlName="explanationsHowitworks2"
                                      rows="5">
                            </textarea>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Texte étape 2</mat-label>
                            <textarea matInput
                                      disabled
                                      [value]="getPreview(sponsorshipForm.get('explanationsHowitworks2').value)"
                                      rows="5">
                            </textarea>
                        </mat-form-field>
                    </div>

                    <div class="row g-0 my-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Titre étape 3</mat-label>
                            <input matInput
                                   formControlName="explanationsHowitworksTitle3"
                                   id="explanationsHowitworksTitle3"
                                   type="text"/>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Titre étape 3</mat-label>
                            <input matInput
                                   disabled
                                   [value]="getPreview(sponsorshipForm.get('explanationsHowitworksTitle3').value)"
                                   type="text"/>
                        </mat-form-field>
                    </div>

                    <div class="row g-0 my-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Texte étape 3</mat-label>
                            <textarea matInput
                                      formControlName="explanationsHowitworks3"
                                      rows="5">
                            </textarea>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Texte étape 3</mat-label>
                            <textarea matInput
                                      disabled
                                      [value]="getPreview(sponsorshipForm.get('explanationsHowitworks3').value)"
                                      rows="5">
                            </textarea>
                        </mat-form-field>
                    </div>

                    <div class="row g-0 my-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Titre Alors conquis ?</mat-label>
                            <input matInput
                                   formControlName="explanationsTitle5"
                                   id="explanationsTitle5"
                                   type="text"/>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Titre Alors conquis ?</mat-label>
                            <input matInput
                                   disabled
                                   [value]="getPreview(sponsorshipForm.get('explanationsTitle5').value)"
                                   type="text"/>
                        </mat-form-field>
                    </div>

                    <div class="row g-0 my-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Ecran accueil - Bouton</mat-label>
                            <input matInput
                                   formControlName="sponsorshipButton"
                                   id="sponsorshipButton"
                                   type="text"/>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Ecran accueil - Bouton</mat-label>
                            <input matInput
                                   disabled
                                   [value]="getPreview(sponsorshipForm.get('sponsorshipButton').value)"
                                   type="text"/>
                        </mat-form-field>
                    </div>

                    <div class="row g-0 my-2">
                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Mentions spécifiques</mat-label>
                            <textarea matInput
                                      formControlName="legalNoticeSpecificHomepage"
                                      rows="5">
                            </textarea>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col mx-2">
                            <mat-label>Aperçu : Mentions spécifiques</mat-label>
                            <textarea matInput
                                      disabled
                                      [value]="getPreview(sponsorshipForm.get('legalNoticeSpecificHomepage').value)"
                                      rows="5">
                            </textarea>
                        </mat-form-field>
                    </div>

                    <div class="d-flex justify-content-center mt-4">
                        <div>
                            <button class="btn btn-validate px-4 py-2" type="submit">Mettre à jour</button>
                        </div>
                    </div>
                </form>

            </div>
        }
    }
    <!--  no sponsorship -->
    @if (!this.sponsorshipService.operation) {
        <h1 class="my-5">Parrainage online</h1>

        <h2 class="my-5"> Contact </h2>

        <div class="row mb-5">
            <div class="col">
                <a href="mailto:victoire@isifid.com?subject=Gift Parrainage" target="_blank">
                    <button class="btn btn-validate px-4 py-2"> Email</button>
                </a>
            </div>
        </div>
    }
</div>
