<div class="container-fluid min-vh-100 pt-5">

    @if (loading) {<mat-spinner class="mx-auto" />}
    @else {
        <div class="px-5">
            <h1>Suivi du budget agence de l'opération <br> {{operation.name}}</h1>
            <div class="row mt-5 pt-5">
                <div class="col stats-card">
                    <p>Budget</p>
                    <h3 class="pt-3">
                        {{budget.amountTotal | currency:'EUR':'symbol':'1.0-0':'fr-FR' }}
                    </h3>
                </div>
                @if (!isFirstPeriodOver()) {
                    <div class="col stats-card">
                        <p>Durée totale</p>
                        <h3>{{getNumberOfMonth()}} mois ({{getNumberOfMonth() / 2}} mois reconductibles sous réserve)</h3>
                    </div>
                } @else {
                    <div class="col stats-card">
                        <p>Durée totale</p>
                        <h3 class="pt-3">
                            {{getNumberOfMonth()}} mois (en deux périodes {{getNumberOfMonthFirstPeriod()}} mois
                            puis {{getNumberOfMonthSecondPeriod()}} mois)
                        </h3>
                    </div>
                }
                <div class="col stats-card">
                    <p>Budget consommé</p>
                    <h3 class="pt-3">
                        {{budget.amountTotal - budget.amountRemaining | currency:'EUR':'symbol':'1.0-0':'fr-FR' }} /
                        {{(budget.amountTotal - budget.amountRemaining) / budget.amountTotal * 100 | number:'1.0-0'}}%
                    </h3>
                </div>
                <div class="col stats-card">
                    <p>Durée écoulée</p>
                    <h3 class="pt-3">{{getElapsedTime()}}</h3>
                </div>
            </div>
        </div>
    }
</div>
