import {Component, OnInit} from '@angular/core';
import {
    Budget,
    CreditsHistory,
    HierarchicalLevel,
    MsServicesGiftService,
    Offer,
    Operation
} from '@isifid/core';
import {ActivatedRoute, Router} from '@angular/router';
import {OperationsService} from '../../../../shared/services/operations.service';
import {GiftService} from '../../../../shared/services/gift.service';
import {UIService} from '../../../../shared/services/ui.service';

@Component({
    selector: 'app-operations-manage',
    templateUrl: './manage.component.html'
})
export class OperationsManageComponent implements OnInit {
    budget: Budget = null;
    creditsHistory: Array<CreditsHistory> = [];
    hierarchicalLevel: HierarchicalLevel[];
    loading = true;
    offers: Array<Offer> = [];
    operation: Operation;
    trackingLevels: { id: number; value: string; name: string }[] = [
        {id: null, value: 'global', name: 'Global'},
        {id: 2, value: 'sector', name: 'Par secteur'},
        {id: 1, value: 'groupAgency', name: 'Par groupe d’agences'},
        {id: 0, value: 'agency', name: 'Par agence'}
    ];


    constructor(
        public readonly giftService: GiftService,
        public readonly router: Router,
        public readonly uiService: UIService,
        private readonly activatedRoute: ActivatedRoute,
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly operationsService: OperationsService
    ) {
    }

    ngOnInit(): void {
        this.operation = null;
        this.hierarchicalLevel = this.giftService.hierarchicalLevels;
        const id = Number(this.activatedRoute.snapshot.paramMap.get('id'));
        if (id) {
            this.operation = this.operationsService.getOperations(true, false, false)
                .find((operation) => operation.id === id) || null;
        }

        // If operation is not found, redirect to admin dashboard
        if (!this.operation) this.router.navigate(['/admin/dashboard']).then().catch();

        this.initBudgets();
        this.initOffers();
        this.loading = false;
    }

    getLevelEntityName(levelId: any): string {
        return this.giftService.hierarchicalLevels.find((level) => level.id === levelId).entity;
    }

    getElapsedTime() {
        const start = new Date(this.budget.startAt);
        const end = new Date(this.budget.endAt);
        let string = Math.round((new Date().getTime() - start.getTime()) / (end.getTime() - start.getTime()) * 100) + '%';
        if (start.getTime() > new Date().getTime()) string = string + ' (l\'opération n\'a pas encore commencé)';
        return string;
    }

    groupOffersByAmount(offers: Offer[]): Offer[] {
        return offers.reduce((groups, offer) => {
            const group = groups.find((group) => group.amount === offer.amount);
            if (!group) groups.push(offer);
            return groups;
        }, []);
    }

    getOfferCount(offerAmount: number) {
        return this.creditsHistory.filter((credit) => credit.amount === -offerAmount).length;
    }

    getNumberReward(creditsHistory: Array<CreditsHistory>) {
        return creditsHistory.filter((credit) => credit.amount < 0).length;
    }

    checkLevel(number: number) {
        if (number === null) return true;
        const level = this.giftService.hierarchicalLevels.find((level) => level.id === this.budget?.levelId);
        return level?.position === number;
    }

    private initBudgets(): void {
        this.msServicesGiftService.getBudgetsByClientId(this.giftService.client.id).subscribe({
            next: (budgets) => {
                this.budget = budgets.find((b) => b.operationId === this.operation.id);
                if (this.budget) {
                    this.msServicesGiftService.getCreditsHistoryByBudgetId(this.budget.id).subscribe({
                        next: (creditsHistory) => this.creditsHistory = creditsHistory
                    });
                }
            }
        });
    }

    private initOffers(): void {
        this.operationsService.getOffersByOperationId(this.operation.id).subscribe((offers) => {
            this.operation.offers = offers.filter((offer) => offer.status === 'active');
        });
    }
}
