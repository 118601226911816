import {Component, OnInit} from '@angular/core';
import {ApiService, Client, EmailVariablesSet} from '@isifid/core';
import {environment} from '../../../../../environments/environment';
import {FormBuilder, FormGroup} from '@angular/forms';
import {GiftService} from '../../../../shared/services/gift.service';

@Component({
    selector: 'app-email',
    templateUrl: './email.component.html'
})
export class EmailComponent implements OnInit {
    variablesSetForm: FormGroup;
    private emailVariablesSets: Array<EmailVariablesSet> = [];
    private readonly endpoint = '/utils/email';
    private client: Client;

    constructor(
        private readonly apiService: ApiService,
        private readonly giftService: GiftService,
        private readonly formBuilder: FormBuilder
    ) {
        this.client = this.giftService.client;
    }

    ngOnInit(): void {
        this.getAllVariablesSetsPerEntityId(this.client.id, 'client').subscribe({
            next: (variableSets) => {
                variableSets.forEach(variablesSet => {
                    const vs = new EmailVariablesSet();
                    Object.assign(vs, variablesSet);
                    this.emailVariablesSets.push(vs);
                });
            }
        });
    }

    selectEmail(type: any) {
        // For sponsorship professional we get de non pro one
        const defaultVariablesSetType = type.replace('_pro', '');
        // No default for the client, we get the default for all clients
        this.getAllVariablesSetsPerEntityIdAndPerType(0, 'client', defaultVariablesSetType).subscribe({
            next: variablesSetList => {
                const variablesSet = new EmailVariablesSet();
                if (variablesSetList.length !== 0) {
                    delete variablesSetList[0].id;
                    Object.assign(variablesSet, variablesSetList[0]);
                }
                variablesSet.type = type;

                this.emailVariablesSets.push(variablesSet);
            }
        });
    }

    getAllVariablesSetsPerEntityIdAndPerType(entityId, entityTypeId, type) {
        entityTypeId = this.getEntityTypeId(entityTypeId);
        return this.apiService.get(`${this.endpoint}/variables-sets?entityTypeId=${entityTypeId}&entityId=${entityId}&type=${type}&status=active`);
    }

    getEntityTypeId(type) {
        switch (type) {
        case 'client':
            return 1;
        case 'operation':
            return 2;
        case 'grant':
            if (environment.production) {
                return 5;
            } else {
                return 9;
            }
        default:
            return 0;
        }
    }

    populateEmailVariablesSetForm(event) {
        const emailVariablesSet = this.emailVariablesSets.find(evs => evs.type === event.value);
        if (typeof emailVariablesSet === 'undefined') {
            return;
        }
        this.variablesSetForm = this.formBuilder.group(emailVariablesSet);
    }

    getAllVariablesSetsPerEntityId(entityId, entityTypeId) {
        entityTypeId = this.getEntityTypeId(entityTypeId);
        return this.apiService.get(`${this.endpoint}/variables-sets?entityTypeId=${entityTypeId}&entityId=${entityId}&status=active`);
    }

    emailVariablesSetExists(type) {
        const emailVariablesSet = this.emailVariablesSets.find(evs => evs.type === type);
        return emailVariablesSet !== undefined;
    }
}
