<div class="container-fluid mt-4 py-3 py-md-5">

    <div class="row justify-content-between align-items-end">
        <div class="col-md-6 col-lg-auto">
            <p>Utilisateurs dont l'échelon est introuvable : {{giftUsersWithoutLevel.length}}</p>
            <p>Utilisateurs sans entité (null ou vide) : {{giftUsersWithoutBranch.length}}</p>
            <p>Utilisateurs dont l'échelon ne correspond pas au rôle : {{giftUsersWithNoMatchingRole.length}}</p>
        </div>
        <div class="col-md-6 col-lg-4 mt-4 mt-md-0">
            <mat-form-field appearance="fill">
                <mat-label>Filter</mat-label>
                <mat-select [formControl]="filterControl">
                    <mat-option value="giftUsersWithoutLevel">Utilisateurs dont l'échelon est introuvable</mat-option>
                    <mat-option value="giftUsersWithoutBranch">Utilisateurs sans entité (null ou vide)</mat-option>
                    <mat-option value="giftUsersWithNoMatchingRole">Utilisateurs dont l'échelon ne correspond pas aux rôles</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="responsive-table">
        <div class="responsive-table-body">
            <table [dataSource]="dataSource" aria-describedby="monitoring" class="mb-1"
                   mat-table matSort>

                <ng-container class="py-5" matColumnDef="id">
                    <th *matHeaderCellDef id="id" mat-header-cell mat-sort-header>ID d'utilisateur gift</th>
                    <td *matCellDef="let giftUser" mat-cell>
                        {{giftUser.id}}
                    </td>
                </ng-container>

                <ng-container class="py-5" matColumnDef="email">
                    <th *matHeaderCellDef id="email" mat-header-cell mat-sort-header>Email</th>
                    <td *matCellDef="let giftUser" mat-cell>
                        {{getUserEmail(giftUser.uuid)}}
                    </td>
                </ng-container>

                <ng-container class="py-5" matColumnDef="uuid">
                    <th *matHeaderCellDef id="uuid" mat-header-cell mat-sort-header>UUID</th>
                    <td *matCellDef="let giftUser" mat-cell>
                        {{giftUser.uuid}}
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                <tr *matNoDataRow>
                    <td [colSpan]="displayedColumns.length" class="text-center py-4">Aucun Utilisateur</td>
                </tr>
            </table>
        </div>
        <mat-paginator
                [hidden]="dataSource?.filteredData.length < 10"
                [pageSizeOptions]="[10, 25, 50, 100]"
                aria-label="Sélectionner la page des utilisateurs"
                showFirstLastButtons />
    </div>
</div>
