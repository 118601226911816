<div>
    <h2> {{ operation.name | titlecase }} </h2>

    <div class="responsive-table mt-5" [hidden]="!dataSource || !trackingLevel">
        <div class="responsive-table-body">
            <table #tableSort="matSort" [dataSource]="dataSource" mat-table matSort>
                @for (col of displayedColumns; track col; let index = $index) {
                    <ng-container [matColumnDef]="col">
                        <th *matHeaderCellDef [innerHTML]="colNames[index]" class="p-2 text-center clickable" mat-header-cell
                            mat-sort-header></th>
                        <td *matCellDef="let element" class="text-center" mat-cell>
                            @if (col === 'entityExternalId' || col === 'entityName') {<span>{{element[col]}}</span>}
                            @if (col === 'amountGranted' || col === 'amountExpended' || col === 'amountRemaining') {
                                <span>
                                    {{element[col] | currency: 'EUR':'symbol':'1.0-0':'fr-FR'}}
                                </span>
                            }
                            @if (col === 'amountExpendedPercent') {<span>{{element[col] | number:'1.0-1'}} %</span>}
                            @if (col === 'export') {
                                <span (click)="downloadEvent.emit(element)" class="clickable text-purple">
                                    {{element[col]}}
                                </span>
                            }
                        </td>
                    </ng-container>
                }

                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
            </table>
        </div>
        <mat-paginator #paginator [hidden]="trackingLevel === 'global'" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons />
    </div>
</div>
