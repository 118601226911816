<div class="container-fluid min-vh-100 pt-5">

    @if (loading) {<mat-spinner class="mx-auto" />}
    @else {

        <div class="px-5">
            <h1 class="text-center">Liste des factures</h1>
            <div class="row justify-content-center">
                @if (!paymentHistory.length) {
                    <p class="text-center">
                        Aucun historique pour le moment.
                    </p>
                }
                <table [dataSource]="dataSource" [hidden]="paymentHistory.length === 0"
                       aria-describedby="history"
                       class="my-3 w-100"
                       mat-table matSort>

                    <ng-container class="py-5" matColumnDef="createdAt">
                        <th *matHeaderCellDef id="createdAt" mat-header-cell mat-sort-header>Commandé le</th>
                        <td *matCellDef="let payment" class="text-grey" mat-cell>
                            {{payment.createdAt | date:'dd/MM/yyyy':'undefined':'fr-FR' }}
                        </td>
                    </ng-container>

                    <ng-container class="py-5" matColumnDef="amount">
                        <th *matHeaderCellDef id="amount" mat-header-cell mat-sort-header> Montant</th>
                        <td *matCellDef="let payment" class="text-secondary" mat-cell>
                            {{payment.amount}}
                        </td>
                    </ng-container>

                    <ng-container class="py-5" matColumnDef="paymentMethod">
                        <th *matHeaderCellDef id="paymentMethod" mat-header-cell mat-sort-header>Moyen de paiement</th>
                        <td *matCellDef="let payment" class="text-grey" mat-cell>
                            {{getPaymentMethodToString(payment.paymentMethod)}}
                        </td>
                    </ng-container>

                    <ng-container class="py-5" matColumnDef="paidAt">
                        <th *matHeaderCellDef id="paidAt" mat-header-cell mat-sort-header>Payé le</th>
                        <td *matCellDef="let payment" class="text-grey" mat-cell>
                            {{payment.paidAt | date:'dd/MM/yyyy':'undefined':'fr-FR' }}
                        </td>
                    </ng-container>

                    <ng-container class="py-5" matColumnDef="invoice">
                        <th *matHeaderCellDef id="invoice" mat-header-cell> Facture</th>
                        <td *matCellDef="let payment" class="text-secondary" mat-cell>
                            @if (payment.invoiceId) {
                                <button (click)="downloadPdf(payment)"
                                        class="btn btn-validate">
                                    Télécharger <em class="fas fa-file-download btn-font-awesome"></em>
                                </button>
                            } @else if (payment.paymentMethod === 0 && !payment.invoiceId) {
                                <p>En cours de création</p>
                            }
                        </td>
                    </ng-container>

                    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                </table>
            </div>
        </div>
    }
</div>
