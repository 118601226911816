<div class="container-fluid min-vh-100 pt-5">
    @if (loading) {<mat-spinner class="mx-auto" />}
    @else {
        <div class="px-5">
            <h1>Liste des budgets agences</h1>
            <div class="row justify-content-center mt-5">
                @if (budgets.length === 0) {
                    <p class="text-center">
                        Aucun budget agence défini pour le moment.
                    </p>
                }
                <p class="text-center">
                    @if (userService.hasRole('TECH_ADMIN')) {
                        <button class="btn btn-validate" routerLink="/admin/budgets/manage">
                            Créer un budget
                        </button>
                    }
                </p>
                <table [dataSource]="budgets" [hidden]="budgets.length === 0" aria-describedby="budgets" class="my-3 w-100"
                       mat-table matSort>

                    <ng-container class="py-5" matColumnDef="operation">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>Opération</th>
                        <td *matCellDef="let budget" class="text-grey" mat-cell>
                            {{getOperationName(budget.operationId)}}
                        </td>
                    </ng-container>
                    <ng-container class="py-5" matColumnDef="amountTotal">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>Plafond</th>
                        <td *matCellDef="let budget" class="text-grey" mat-cell>
                            {{budget.amountTotal}}€
                        </td>
                    </ng-container>
                    <ng-container class="py-5" matColumnDef="amountRemaining">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>Consommation</th>
                        <td *matCellDef="let budget" class="text-grey" mat-cell>
                            {{budget.amountTotal - budget.amountRemaining}}€ /
                            {{(budget.amountTotal - budget.amountRemaining) / budget.amountTotal * 100 | number:'1.0-0'}}%
                        </td>
                    </ng-container>
                    <ng-container class="py-5" matColumnDef="status">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>Statut</th>
                        <td *matCellDef="let budget" class="text-grey" mat-cell>
                            {{getStatus(budget.status)}}
                        </td>
                    </ng-container>
                    <ng-container class="py-5" matColumnDef="actions">
                        <th *matHeaderCellDef mat-header-cell>Actions</th>
                        <td *matCellDef="let budget" class="text-grey" mat-cell>
                            @if (userService.hasRole('TECH_ADMIN')) {
                                <button [queryParams]="{id: budget.id}"
                                        class="btn btn-validate ms-2"
                                        routerLink="/admin/budgets/manage">
                                    Modifier
                                </button>
                            }
                            @if (userService.hasRole('GIFT_ISIFID') && budget.attributionMethod === 'manual' && budget.amountRemaining > 0) {
                                <button [queryParams]="{id: budget.id}" class="btn btn-validate ms-2"
                                        routerLink="/admin/budgets/spread">
                                    Distribuer du budget
                                </button>
                            }
                            @if (userService.hasRole('GIFT_HQ')) {
                                <button [queryParams]="{id: budget.id}"
                                        class="btn btn-validate ms-2"
                                        routerLink="/admin/budgets/tracking">
                                    Suivi du budget
                                </button>
                            }
                        </td>
                    </ng-container>

                    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                    <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
                </table>
            </div>
        </div>
    }
</div>
