<div class="container-fluid min-vh-100 pt-5">
    @if (loading) {<mat-spinner class="mx-auto" />}
    @else {
        <div class="px-5">
            <h1>Marketing automation</h1>
            @if (operation) {
                <h2 class="text-center">Liste des règles de l'opération {{operation.name}}</h2>
            }
            <div class="row justify-content-center mt-5">
                <p class="text-center">
                    @if (userService.hasRole('GIFT_ISIFID')) {
                        <button class="btn btn-validate me-2" routerLink="/admin/automation/operations">Retour</button>
                        <button class="btn btn-validate" routerLink="/admin/automation/operations/{{operation.id}}/rule">
                            Créer une règle
                        </button>
                    }
                </p>
                @if (rules.length === 0) {
                    <p class="text-center">
                        Aucune règle définie pour le moment.
                    </p>
                }
                <table [dataSource]="rules" [hidden]="rules.length === 0" aria-describedby="rules" class="my-3 w-100"
                       mat-table matSort>

                    <ng-container class="py-5" matColumnDef="event">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>Evénement</th>
                        <td *matCellDef="let rule" class="text-grey" mat-cell>
                            {{rule.event}}
                        </td>
                    </ng-container>
                    <ng-container class="py-5" matColumnDef="rolesTargeted">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>Rôles cibles</th>
                        <td *matCellDef="let rule" class="text-grey" mat-cell>
                            @for (role of rule.rolesTargeted; track role) {
                                {{ role }}
                            }
                        </td>
                    </ng-container>
                    <ng-container class="py-5" matColumnDef="positionsTargeted">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>Positions cibles</th>
                        <td *matCellDef="let rule" class="text-grey" mat-cell>
                            @for (position of rule.positionsTargeted; track position) {
                                {{ position }}
                            }
                        </td>
                    </ng-container>
                    <ng-container class="py-5" matColumnDef="giftUsersTargeted">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>Utilisateurs cibles</th>
                        <td *matCellDef="let rule" class="text-grey" mat-cell>
                            @for (giftUserId of rule.giftUsersTargeted; track giftUserId) {
                                {{ giftUserId }}
                            }
                        </td>
                    </ng-container>
                    <ng-container class="py-5" matColumnDef="templateId">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>Template ID</th>
                        <td *matCellDef="let rule" class="text-grey" mat-cell>
                            {{rule.templateId}}
                        </td>
                    </ng-container>
                    <ng-container class="py-5" matColumnDef="schedule">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>Planification</th>
                        <td *matCellDef="let rule" class="text-grey" mat-cell>
                            {{rule.schedule}}
                        </td>
                    </ng-container>
                    <ng-container class="py-5" matColumnDef="status">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>Statut</th>
                        <td *matCellDef="let rule" class="text-grey" mat-cell>
                            {{rule.status}}
                        </td>
                    </ng-container>
                    <ng-container class="py-5" matColumnDef="actions">
                        <th *matHeaderCellDef mat-header-cell>Actions</th>
                        <td *matCellDef="let rule" class="text-grey" mat-cell>
                            @if (userService.hasRole('GIFT_ISIFID')) {
                                <button class="btn btn-validate ms-2"
                                        routerLink="/admin/automation/operations/{{operation.id}}/rule/{{rule.id}}">
                                    Editer
                                </button>
                            }
                        </td>
                    </ng-container>

                    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                    <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
                </table>
            </div>
        </div>
    }
</div>
