<div class="container-fluid min-vh-100">
    @if (loading) {<mat-spinner class="mx-auto" />}
    @else {
        <div class="px-5">
            <h1 class="my-5 text-center">Notification de réallocation de budget</h1>

            @if (!mailHasBeenSent) {
                <div class="col-10 col-lg-8 mx-auto">
                    <p>
                        Ci-dessous le mail d'information que nous nous proposons d'envoyer à vos conseillers.
                        <br />Si celui-ci vous convient, merci de valider.
                    </p>
                    <button class="mb-5 btn btn-validate" (click)="validate()">
                        Je valide
                    </button>
                    <p>Objet: Augmentation de votre Budget Agences</p>
                    <p>
                        Votre agence fait partie de celles qui ont utilisé leur Budget pour récompenser les ouvertures de
                        comptes avec mobilité bancaire, ce qui était le but de cette opération, tant mieux !
                        Afin d'éviter tout risque de rupture de stock, vous récupérez aujourd'hui un budget complémentaire
                        de {{reallocatedCreditHistory.amount}}&euro; dont vous ferez certainement  le meilleur usage.
                        <br />Vous constaterez en vous rendant sur votre assistant que votre budget a d'ores et déjà été mis à jour.
                    </p>
                </div>
            } @else {
                <div class="col-10 col-lg-8 mx-auto">
                    <p>
                        Le mail d'information a bien été envoyé.
                    </p>
                </div>
            }
        </div>
    }
</div>
