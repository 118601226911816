import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {Budget, HierarchicalLevel, MsServicesGiftService} from '@isifid/core';
import {GiftService} from '../../../../shared/services/gift.service';

@Component({
    selector: 'app-budgets-spread',
    templateUrl: './spread.component.html'
})
export class BudgetsSpreadComponent implements OnInit {
    addCreditForm: FormGroup;
    dataSource = new MatTableDataSource<any>([]);
    level: HierarchicalLevel;
    budget: Budget;

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly giftService: GiftService,
        public readonly msServicesGiftService: MsServicesGiftService
    ) {
        const urlParams = new URLSearchParams(window.location.search);
        this.msServicesGiftService.getBudget(urlParams.get('id')).subscribe({
            next: (budget) => {
                this.budget = budget;
                this.level = this.giftService.hierarchicalLevels.find(l => l.id = budget.levelId);
            }
        });
    }

    ngOnInit() {
        this.addCreditForm = this.formBuilder.group({
            data: ['', [Validators.required, Validators.minLength(1)]]
        });
    }

    addCredit() {
        this.dataSource = new MatTableDataSource<any>([]);
        this.parseData();
    }

    private parseData() {
        const rawData = this.addCreditForm.get('data').value.toLowerCase().split(/\r?\n/);

        // Return if no data are provided
        if (rawData.length === 0) return;

        for (const line of rawData) {
            const columns = line.replace(/\s+|\t/g, ' ').split(' ');

            // Only keep lines with more than 2 elements
            if (columns.length < 2) continue;

            const credit = {entityId: columns[0], budgetId: this.budget.id, amountRemaining: columns[1]};

            this.msServicesGiftService.createCredits(credit).subscribe();
        }
    }
}
