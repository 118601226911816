<div class="p-5">
    <div class="justify-content-center" mat-dialog-actions>
        @if (data.branch) {
            <p>Êtes vous sûr de vouloir supprimer l'entité {{data.branch.name}} ?</p>
        }
        <button (click)="deleteBranch()" class="btn btn-validate px-5 py-3" type="button"> Oui</button>
        <button class="btn btn-validate ms-3 px-5 py-3" mat-dialog-close="false" mat-raised-button type="button">
            Non
        </button>
    </div>
</div>
