import {Component, OnInit} from '@angular/core';
import {Budget, Credits, CreditsHistory, GiftUser, MsServicesGiftService, UserService} from '@isifid/core';
import {filter} from 'rxjs/operators';
import {GiftUserService} from '../../../../shared/services/gift-user.service';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-budgets-reallocation-emailing',
    templateUrl: './reallocation-emailing.component.html'
})
export class BudgetsReallocationEmailingComponent implements OnInit {
    loading = true;
    mailHasBeenSent = false;
    giftUser: GiftUser;
    budget: Budget;
    credit: Credits;
    creditsHistory: CreditsHistory[];
    reallocatedCreditHistory: CreditsHistory;

    constructor(public readonly userService: UserService,
                private readonly giftUserService: GiftUserService,
                private readonly msServicesGiftService: MsServicesGiftService,
                private readonly route: ActivatedRoute
    ) {
        this.giftUserService.getGiftUser().pipe(filter(s => !!s)).subscribe(s => this.giftUser = s);
    }

    ngOnInit() {
        const creditId = Number(this.route.snapshot.paramMap.get('creditId'));

        this.msServicesGiftService.getCredits(creditId).subscribe({
            next: (credit) => {
                this.credit = credit;
                this.msServicesGiftService.getCreditsHistoryByBudgetId(this.credit.budgetId).subscribe({
                    next: (creditsHistory) => {
                        this.creditsHistory = creditsHistory.filter(c => c.creditId === creditId);
                        this.reallocatedCreditHistory = this.creditsHistory.find(c => c.giftUserId == null && c.amount > 0);
                        this.loading = false;
                    }
                });
            }
        });
    }

    validate() {
        this.loading = true;
        this.msServicesGiftService.notifyReallocation(this.reallocatedCreditHistory.id).subscribe({
            next: () => {
                this.mailHasBeenSent = true;
                this.loading = false;
            }, error: () => this.loading = false
        });
    }
}
