import {AfterViewInit, Component, Input, OnInit, ViewChild} from '@angular/core';
import {GiftUser, HierarchicalLevel, User} from '@isifid/core';
import {FormControl} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';

@Component({
    selector: 'app-admin-network-control-users',
    templateUrl: './users.component.html'
})
export class NetworkControlUsersComponent implements OnInit, AfterViewInit {
    filterControl = new FormControl('giftUsersWithoutLevel');
    displayedColumns: string[] = ['id', 'email', 'uuid'];
    dataSource: MatTableDataSource<any>;
    giftUsersWithoutLevel: Array<GiftUser & { filterValue: string }> = [];
    giftUsersWithoutBranch: Array<GiftUser & { filterValue: string }> = [];
    giftUsersWithNoMatchingRole: Array<GiftUser & { filterValue: string }> = [];
    private filteredGiftUsers: GiftUser[] = [];
    @Input() private giftUsers: GiftUser[];
    @Input() private users: User[];
    @Input() private levels: HierarchicalLevel[];
    @ViewChild(MatSort) private sort: MatSort;
    @ViewChild(MatPaginator) private paginator: MatPaginator;


    ngOnInit(): void {
        for (const s of this.giftUsers) {
            // Users without a hierarchical level
            if (s.uuid && !s.levelId) this.giftUsersWithoutLevel.push({...s, filterValue: 'giftUsersWithoutLevel'});
            // GiftUsers without a branch (null or empty)
            if (s.uuid && !s.branchList?.length) this.giftUsersWithoutBranch.push({...s, filterValue: 'giftUsersWithoutBranch'});
            // GiftUsers with a hierarchical level not matching roles
            if (s.uuid && this.giftUserForLevel(s)) this.giftUsersWithNoMatchingRole.push({...s, filterValue: 'giftUsersWithNoMatchingRole'});
        }

        this.filteredGiftUsers = this.removeDuplicatedElement([...this.giftUsersWithoutLevel, ...this.giftUsersWithoutBranch, ...this.giftUsersWithNoMatchingRole]);
        this.dataSource = new MatTableDataSource(this.filteredGiftUsers);
        this.dataSource.filter = this.filterControl.value;
        this.filterControl.valueChanges.subscribe(s => this.dataSource.filter = s);
    }

    ngAfterViewInit(): void {
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    getUserEmail(uuid: string): string {
        return this.users.find(s => s.uuid === uuid)?.email;
    }

    private giftUserForLevel(giftUser: GiftUser): boolean {
        const level = this.levels.find(s => s.id === giftUser.levelId);
        // Check user doesn't have the matching role with the hierarchical level
        return this.users.find(s => s.uuid === giftUser.uuid)?.roles.every(s => s.name !== level?.role);
    }

    private removeDuplicatedElement<T>(value: T): T {
        let index: number;
        return (value as any[]).reduce((acc, curr) => {
            index = acc.findIndex((s: any) => s.uuid === curr.uuid);
            if (index > -1) acc[index].filterValue += curr.filterValue;
            else acc.push(curr);
            return acc;
        }, []);
    }
}
