<div class="container-fluid min-vh-100 pt-5">

    <div class="text-center">

        <h1 class="my-5">Mes emails</h1>

        <div class="row my-5">
            <div class="col">
                <h2>Créer un email personnalisé :</h2>
            </div>
        </div>
        <div class="row my-5">
            <div class="col">
                <span>Email</span>
                <select (change)="populateEmailVariablesSetForm($event.target['value'])"
                        class="form-select form-select-lg">
                    <option [value]="'access'">access</option>
                    <option [value]="'reminder'">reminder</option>
                    <option [value]="'reminder_last'">reminder_last</option>
                    <option [value]="'order'">order</option>
                </select>
            </div>
        </div>

        @if (variablesSetForm) {
            <form [formGroup]="variablesSetForm" class="mt-5">
                <div class="row">
                    <div class="col-sm-2 col-form-label">Titre</div>
                    <div class="col-sm-10">
                        <input class="form-control" formControlName="subject" type="text">
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-2 col-form-label">Bloc 1</div>
                    <div class="col-sm-10">
                        <textarea class="form-control" formControlName="block1" rows="3"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Bloc 2</div>
                    <div class="col-sm-10">
                        <textarea class="form-control" formControlName="block2" rows="3"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Bloc 3</div>
                    <div class="col-sm-10">
                        <textarea class="form-control" formControlName="block3" rows="3"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Bloc 4</div>
                    <div class="col-sm-10">
                        <textarea class="form-control" formControlName="block4" rows="3"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Bloc 5</div>
                    <div class="col-sm-10">
                        <textarea class="form-control" formControlName="block5" rows="3"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Bloc 6</div>
                    <div class="col-sm-10">
                        <textarea class="form-control" formControlName="block6" rows="3"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Bloc 7</div>
                    <div class="col-sm-10">
                        <textarea class="form-control" formControlName="block7" rows="3"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Bloc 8</div>
                    <div class="col-sm-10">
                        <textarea class="form-control" formControlName="block8" rows="3"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Bloc 9</div>
                    <div class="col-sm-10">
                        <textarea class="form-control" formControlName="block9" rows="3"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Bloc 10</div>
                    <div class="col-sm-10">
                        <textarea class="form-control" formControlName="block10" rows="3"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-2 col-form-label">Bloc Logo</div>
                    <div class="col-sm-10">
                        <textarea class="form-control" formControlName="blockLogo" rows="3"></textarea>
                    </div>
                </div>
            </form>
        }
    </div>
</div>
