<div class="container-fluid min-vh-100 pt-5">
    @if (loading) {
        <div class="p-5">
            <mat-spinner class="mx-auto" />
        </div>
    } @else {
        <div>
            <h1 class="my-5 text-center"> Récompenser </h1>
            @if (!selectedOperation) {
                <div>
                    <p>Avant de pouvoir attribuer des dotations, vous devez créer une opération.</p>
                </div>
            }
            @if (operations && operations.length > 1) {
                <div class="row">
                    <div class="col px-5">
                        <span>Choisir l'opération pour laquelle récompenser :</span>
                        <select (change)="updateSelectedOperation($event.target['value'])" class="form-select form-select-lg">
                            @for (operation of operations; track operation.id) {
                                <option [value]="operation.id">{{operation.name}}</option>
                            }
                        </select>
                    </div>
                </div>
            }

            @if (selectedOperation) {
                <div class="row px-4">
                    <div class="col mx-auto py-2 px-5">
                        <p>
                            Les offres liées à l'opération sélectionnée s'affichent ci-dessous. Vous ne pouvez
                            récompenser que pour les montants ci-dessous.
                            <br><br>
                            <span class="color-primary">Offre(s) disponible(s) : </span>
                        </p>
                        <ul>
                            @for (offer of getOffersForSelectedOperation(); track offer.id) {
                                <li>{{offer.amount}}€ - {{offer.name}}</li>
                            }
                        </ul>
                    </div>
                </div>

                <div class="row px-4 justify-content-center">
                    <div class="row">
                        <div class="col-6 mb-5">
                            Vous pouvez télécharger le
                            <a [href]="'https://cdn.isifid.com/common/Modèle-import-client.xlsx'"
                               class="text-purple"
                               rel="noopener noreferrer" target="_blank">
                                modèle de fichier
                            </a>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col">
                            <p>Transmettre un fichier : </p>
                        </div>
                        <div class="col">
                            <label class="d-none" for="file">fichier :</label>
                            <input (change)="fillFormData($event.target)" class="form-control" id="file" type="file">
                        </div>
                    </div>

                    @if (consumersToRewardFromFile !== null) {
                        <div class="row mt-2">
                            @if (!fileWithConsumersToRewardLoading) {
                                <div class="col-3 me-2 mb-5">
                                    <button (click)="openDialog()" [disabled]="consumersToRewardFromFileStats.valid < 1"
                                            class="btn btn-validate"
                                            type="button">Récompenser
                                    </button>
                                </div>
                            }
                        </div>
                    }

                    @if (selectedOperation) {
                        <div class="col-11">
                            @if (consumersToRewardFromFile !== null) {
                                <div>
                                    <hr>
                                    <form [formGroup]="consumersToRewardFromFileFiltersForm" class="p-0">
                                        <div class="row">
                                            @if (rewardBatchProgress) {
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <label class="input-group-text" for="statusAfterReward"> Statut </label>
                                                    </div>
                                                    <select class="form-select" formControlName="statusAfterReward"
                                                            id="statusAfterReward">
                                                        <option value="all"> Tous</option>
                                                        <option value="rewarded"> Intégré</option>
                                                        <option value="warning"> Warning</option>
                                                        <option value="error"> Erreur</option>
                                                    </select>
                                                </div>
                                            }
                                        </div>
                                    </form>

                                    <div class="row">
                                        <div class="col">Lignes valides / total : {{consumersToRewardFromFileStats.valid}}
                                            / {{consumersToRewardFromFileStats.total}}</div>
                                        <div class="col">Email(s) invalide(s)
                                            : {{consumersToRewardFromFileStats.invalidEmails}}</div>
                                        <div class="col">Tel invalide(s)
                                            : {{consumersToRewardFromFileStats.invalidMobiles}}</div>
                                    </div>

                                    @if (fileWithConsumersToRewardLoading && rewardBatchProgress) {
                                        <h1 class="text-center my-3">
                                            Récompense en cours... {{rewardBatchProgress.rewarded}} / {{rewardBatchProgress.toReward}}
                                            <em class="fas fa-spinner fa-spin"></em>
                                        </h1>
                                    }

                                    <table class="table table-sm mt-3 small">
                                        <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">ID Client</th>
                                            <th scope="col"> Civ</th>
                                            <th scope="col"> Nom</th>
                                            <th scope="col"> Prénom</th>
                                            <th scope="col"> Email</th>
                                            <th scope="col"> Num</th>
                                            <th scope="col"> Montant</th>
                                            <th scope="col"> Statut</th>
                                            <th scope="col"> Erreur</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            @for (consumer of consumers; track i; let i = $index) {
                                                <tr [ngClass]="{'table-error': consumer.rewardError}">
                                                    <td>{{ consumer.index - 1 }}</td>
                                                    <td>{{ consumer.externalId }}</td>
                                                    <td>{{ consumer.genderSmall }}</td>
                                                    <td>{{ consumer.lastName }}</td>
                                                    <td>{{ consumer.firstName }}</td>
                                                    <td [ngClass]="{'bg-warning': !consumer.emailValid}">{{ consumer.email }}</td>
                                                    <td [ngClass]="{'bg-warning': !consumer.mobileValid}">{{ consumer.mobile }}</td>
                                                    <td [ngClass]="{'bg-warning': !consumer.amountValid}">{{ consumer.amountToReward }}</td>
                                                    <td>
                                                        @if (consumer.id) {
                                                            <a>{{consumer.status}}</a>
                                                        } @else {
                                                            <span>{{consumer.status}}</span>
                                                        }
                                                    </td>
                                                    <td>
                                                        @if (consumer.rewardError) {
                                                            <span>{{consumer.rewardError}}</span>
                                                        }
                                                    </td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            }
                        </div>
                    }
                </div>
            }
        </div>
    }
</div>
