<div class="container-fluid py-5">
    <div>
        <h1 class="my-5 px-5 text-center">Programme récompenses conseillers</h1>
        <div class="text-center mb-5">
            <a class="btn btn-validate text-decoration-none" routerLink="create">
                Créer un programme
            </a>
        </div>

        @if (isLoading) {
            <div class="text-center mt-5">
                <em class="fas fa-spinner fa-spin fs-2"></em>
            </div>
        } @else {
            <p [hidden]="dataSource.data.length || dataSource2.data.length" class="text-center">Aucun programme récompense n'a été mis en place</p>
            <mat-tab-group [hidden]="!dataSource.data.length && !dataSource2.data.length">
                <mat-tab label="Opérations de parrainage">
                    <div class="responsive-table">
                        <div class="responsive-table-body">
                            <p [hidden]="dataSource.data.length" class="text-center py-5">Aucun programme récompense n'a été mis en place</p>
                            <table [dataSource]="dataSource" [hidden]="!dataSource.data.length" aria-describedby="Opérations de parrainage"
                                   class="mat-elevation-z8 w-100" mat-table matSort>
                                @for (column of displayedColumns[0]; track i; let i = $index; let last = $last) {
                                    <ng-container [matColumnDef]="column" [stickyEnd]="last">
                                        <th *matHeaderCellDef mat-header-cell scope="col">
                                            {{colNames[0][i]}}
                                        </th>
                                        <td *matCellDef="let element" mat-cell class="text-nowrap">
                                            @switch (column) {
                                                @case ('action') {
                                                    <a class="clickable text-purple text-nowrap text-decoration-none" [routerLink]="element.id">
                                                        Modifier&nbsp;>
                                                    </a>
                                                }
                                                @case ('startAt') {
                                                    {{element[column] | date: 'dd/MM/yyyy'}}
                                                }
                                                @case ('endAt') {
                                                    {{element[column] | date: 'dd/MM/yyyy'}}
                                                }
                                                @default {
                                                    {{element[column]}}
                                                }
                                            }
                                        </td>
                                    </ng-container>
                                }

                                <tr *matHeaderRowDef="displayedColumns[0]" mat-header-row></tr>
                                <tr *matRowDef="let row; columns: displayedColumns[0];" mat-row></tr>
                            </table>
                        </div>

                        <mat-paginator
                                [hidden]="dataSource.data.length <= 10"
                                [pageSizeOptions]="[10, 20, 50, 100]"
                                showFirstLastButtons
                                #paginator />
                    </div>
                </mat-tab>
                <mat-tab label="Opérations autres">
                    <<div class="responsive-table">
                        <div class="responsive-table-body">
                            <p [hidden]="dataSource2.data.length" class="text-center py-5">Aucun programme récompense n'a été mis en place</p>
                            <table [dataSource]="dataSource2" [hidden]="!dataSource2.data.length" aria-describedby="Opérations de parrainage"
                                   class="mat-elevation-z8 w-100" mat-table matSort>
                                @for (column of displayedColumns[1]; track i; let i = $index; let last = $last) {
                                    <ng-container [matColumnDef]="column" [stickyEnd]="last">
                                        <th *matHeaderCellDef mat-header-cell scope="col">
                                            {{colNames[1][i]}}
                                        </th>
                                        <td *matCellDef="let element" mat-cell class="text-nowrap">
                                            @switch (column) {
                                                @case ('action') {
                                                    <a class="clickable text-purple text-nowrap text-decoration-none" [routerLink]="element.id">
                                                        Modifier&nbsp;>
                                                    </a>
                                                }
                                                @case ('startAt') {
                                                    {{element[column] | date: 'dd/MM/yyyy'}}
                                                }
                                                @case ('endAt') {
                                                    {{element[column] | date: 'dd/MM/yyyy'}}
                                                }
                                                @default {
                                                    {{element[column]}}
                                                }
                                            }
                                        </td>
                                    </ng-container>
                                }

                                <tr *matHeaderRowDef="displayedColumns[1]" mat-header-row></tr>
                                <tr *matRowDef="let row; columns: displayedColumns[1];" mat-row></tr>
                            </table>
                        </div>

                        <mat-paginator
                                [hidden]="dataSource2.data.length <= 10"
                                [pageSizeOptions]="[10, 20, 50, 100]"
                                showFirstLastButtons
                                #paginator2 />
                    </div>
                </mat-tab>
            </mat-tab-group>
        }
    </div>
</div>
