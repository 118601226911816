<div class="container-fluid">
    <h1 class="text-center my-5">Liste des bannières</h1>
    <div class="col-10 mx-auto">

        <table [dataSource]="dataSource" aria-describedby="news" class="my-3 w-100"
               mat-table matSort matSortDisableClear matSortActive="theme" matSortDirection="desc">

            <ng-container class="py-5" matColumnDef="theme">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Thème</th>
                <td *matCellDef="let banner" mat-cell>
                    {{banner.theme}}
                </td>
            </ng-container>
            <ng-container class="py-5" matColumnDef="name">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Nom</th>
                <td *matCellDef="let banner" mat-cell>
                    {{banner.name}}
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Actions</th>
                <td *matCellDef="let banner" mat-cell>
                    @if (!checkBannerBeingEdited(banner.id)) {
                        @if (banner.status === 'publishable') {
                            <a class="clickable px-2" title="Voir" target="_blank" [href]="banner.srcUrl">
                                Voir
                            </a>
                            <a class="clickable mt-2 px-2" title="Publier" (click)="publishBanner(banner)">
                                Publier
                            </a>
                        }
                        @if (banner.status === 'active') {
                            <a class="clickable px-2" title="Voir" target="_blank" [href]="banner.srcUrl">
                                Voir
                            </a>
                            <a class="clickable mt-2 px-2" title="Depublier" (click)="unPublishBanner(banner)">
                                Dépublier
                            </a>
                        }
                    } @else {
                        <span class="px-2">
                            <em class="fas fa-spinner fa-spin"></em>
                        </span>
                    }
                </td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

            <tr *matNoDataRow>
                <td [colSpan]="displayedColumns.length">
                    @if (!loading) {
                        <div class="text-center my-5">Pas de données</div>
                    } @else {
                        <div class="text-center my-5 fs-2"><em class="fas fa-spinner fa-spin"></em></div>
                    }
                </td>
            </tr>
        </table>
    </div>
</div>
