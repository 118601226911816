<div class="container-fluid py-5">

    <div class="my-5">
        <h1>Échelons hiérarchiques</h1>
    </div>

    <div class="row">

        <div class="col px-5">
            <p>Représentation des échelons du réseau</p>
            @if (!levels.length) {<p>Aucun échelon n'a été ajouté</p>}
            @else {

                <div class="list-group">
                    @for (level of levels; track level.id) {
                        <div class="list-group-item list-group-item-action">
                            <div>
                                {{level.entity}} - {{level.title}}<br>
                                Type d'accès : {{getRole(level.role)}} - position {{level.position}}
                            </div>
                            <div class="text-end">
                                <button (click)="initForm(level)" class="btn btn-validate mt-2">Editer</button>
                                @if (this.userService.hasRole('TECH_ADMIN')) {
                                    <button (click)="delete(level.id)" class="btn btn-validate ms-2 mt-2">
                                        Supprimer
                                    </button>
                                }
                            </div>
                        </div>
                    }
                </div>
            }
        </div>

        <div class="col px-5">
            <button (click)="initForm()" class="btn btn-validate d-block mx-auto mt-2">Ajouter un nouvel échelon
            </button>
            @if (form) {
                <form [formGroup]="form" class="mt-5">
                    <div class="row">
                        <div class="col-sm-5 col-form-label">Entité</div>
                        <div class="col-sm-7">
                            <input class="form-control" formControlName="entity" placeholder="Agence" type="text">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-5 col-form-label">Fonction</div>
                        <div class="col-sm-7">
                            <input class="form-control" formControlName="title" placeholder="Directeur d'agence"
                                   type="text">
                        </div>
                    </div>
                    @if (levels.length) {
                        <div class="row">
                            <div class="col-sm-5 col-form-label">Échelon</div>
                            <div class="col-sm-7">
                                <select class="form-select" formControlName="position">
                                    @for (level of levels | slice:1; track level.id) {
                                        <option [value]="level.position + 1">
                                            Au dessus de {{level.entity}}</option>
                                        <option value="0">Le plus bas</option>
                                    }
                                </select>
                            </div>
                        </div>
                    }
                    <div class="row">
                        <div class="col-sm-5 col-form-label">Type d'accès</div>
                        <div class="col-sm-7">
                            <select class="form-select" formControlName="role">
                                <option value="GIFT_ADVISOR">{{getRole('GIFT_ADVISOR')}}</option>
                                <option value="GIFT_MANAGER">{{getRole('GIFT_MANAGER')}}</option>
                                <option value="GIFT_DIRECTOR">{{getRole('GIFT_DIRECTOR')}}</option>
                                <option value="GIFT_HQ">{{getRole('GIFT_HQ')}}</option>
                            </select>
                        </div>
                    </div>

                    <button (click)="submit()" class="btn btn-validate" type="submit">
                        Valider
                    </button>
                </form>
            }
        </div>
    </div>

</div>
