import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {forkJoin} from 'rxjs';
import {Branch, MsCoreService, MsDataService} from '@isifid/core';

import {MatSort} from '@angular/material/sort';

import {GiftService} from '../../../../../shared/services/gift.service';
import {StatsService} from '../../../../../shared/services/stats.service';
import {CustomValidator} from '../../../../../shared/helpers/custom.validator';
import {UIService} from '../../../../../shared/services/ui.service';
import {ExcelService} from '@isifid/reward';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSnackBar} from '@angular/material/snack-bar';
import {GiftUserService} from '../../../../../shared/services/gift-user.service';

@Component({
    selector: 'app-operations',
    templateUrl: './list.component.html'
})
export class UsersListComponent implements OnInit {
    dataSource = new MatTableDataSource<any>();
    displayedColumns: Array<string>;
    filterUsersForm: FormGroup;
    loading = true;
    loadingUsers: boolean;
    users: Array<any>;

    private branches: Branch[];
    @ViewChild(MatSort) private sort: MatSort;
    @ViewChild(MatPaginator) private paginator: MatPaginator;

    constructor(
        private cdRef: ChangeDetectorRef,
        readonly giftService: GiftService,
        readonly uiService: UIService,
        private readonly excelService: ExcelService,
        private readonly formBuilder: FormBuilder,
        private readonly giftUserService: GiftUserService,
        private readonly msCoreService: MsCoreService,
        private readonly msDataService: MsDataService,
        private readonly statsService: StatsService,
        private readonly _snackBar: MatSnackBar
    ) {
        this.displayedColumns = ['level', 'branchName', 'firstName', 'lastName', 'email', 'lastLoggedInAt', 'action'];
    }

    ngOnInit() {
        this.loadUsers();

        // Form to filter users
        this.filterUsersForm = this.formBuilder.group({
            email: this.giftService.settings.emailAddressRoot,
            branchCode: ['', [Validators.maxLength(5), CustomValidator.isBranchCode]],
            levelId: ''
        });
        this.loading = false;
    }

    loadUsers(): void {
        this.loadingUsers = true;

        this.users = [];
        forkJoin([
            this.giftUserService.getGiftUsersByClientId(this.giftService.settings.clientId),
            this.msCoreService.getUsers([], {clientId: this.giftService.settings.clientId}),
            this.msDataService.getBranchesByClientId(this.giftService.settings.clientId)
        ]).subscribe({
            next: ([giftUsers, users, branches]) => {
                this.branches = branches;
                users.forEach((user) => {
                    // Only keep users with access to Gift
                    if (user.roles.find(role => !role.name.startsWith('GIFT_'))) return;

                    // Only keep a list with an giftUser entity matching an uuid
                    const giftUser = giftUsers.find((a) => a.uuid === user.uuid);
                    if (!giftUser) return;

                    const row = {
                        uuid: user.uuid,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        phone: user.phone,
                        email: user.email,
                        lastLoggedInAt: user.lastLoggedInAt,
                        branchCode: this.giftService.convertBranchCodeToString(giftUser.branchList[0]),
                        branchCodes: giftUser.branchList,
                        branchName: this.getBranchName(giftUser.branchList[0]),
                        levelId: giftUser.levelId
                    };
                    this.users.push(row);
                });
                this.dataSource = new MatTableDataSource<unknown>(this.users);
                this.initDataSource();
                this.filterUsers();
            }, error: () => {
                console.error('error while getting giftUsers and/or getting list for ' + this.giftService.settings.clientId);
                this._snackBar.open('Une erreur inconnue s\'est produite. Merci de contacter notre assistance.', 'X');
            }, complete: () => this.loadingUsers = false
        });
    }

    extractUsers() {
        const aoa = [[]];
        aoa[0].push(['Échelon', 'Entité', 'Nom', 'Prénom', 'Email', 'Dernière connexion']);
        this.users.forEach((user) => {
            aoa[0].push([this.getTitle(user.levelId), user.branchName, user.lastName, user.firstName, user.email,
                this.statsService.convertDateForSpreadsheet(user.lastLoggedInAt)]);
        });
        this.excelService.exportAsExcelFileAoA(aoa, 'utilisateurs-gift', ['Utilisateurs Gift']);
    }

    filterUsers() {
        if (this.filterUsersForm.invalid) return;
        this.dataSource = new MatTableDataSource<unknown>(this.users);

        // Filter by email
        let email = this.filterUsersForm.get('email').value;
        if (email) {
            email = email.toLowerCase();
            this.dataSource.data = this.dataSource.data.filter((u: any) => u.email.indexOf(email) !== -1);
        }

        // Filter by branchCode
        const branchCode = this.filterUsersForm.get('branchCode').value.trim();
        if (branchCode) this.dataSource.data = this.dataSource.data.filter((u: any) => u.branchCode.indexOf(branchCode) !== -1);

        // Filter by level
        const levelId = +this.filterUsersForm.get('levelId').value;
        if (levelId) this.dataSource.data = this.dataSource.data.filter((u: any) => u.levelId === levelId);

        this.initDataSource();
    }

    userIsAdvisor(user) {
        // Allow level 0 to be considered as Conseiller
        if (user.levelId === 0) return true;

        const level = this.giftService.hierarchicalLevels.find(l => l.id === user.levelId);
        if (!level) return false;
        return level.role === 'GIFT_ADVISOR';
    }

    userIsManager(user) {
        const level = this.giftService.hierarchicalLevels.find(l => l.id === user.levelId);
        if (!level) return false;
        return level.role === 'GIFT_MANAGER';
    }

    userIsDirector(user) {
        const level = this.giftService.hierarchicalLevels.find(l => l.id === user.levelId);
        if (!level) return false;
        return level.role === 'GIFT_DIRECTOR';
    }

    getTitle(levelId) {
        const level = this.giftService.hierarchicalLevels.find(l => l.id === levelId);
        if (!level) return 'Conseiller';
        return level.title;
    }

    private initDataSource() {
        this.cdRef.detectChanges();
        this.dataSource.sort = this.sort;
        // custom paginator
        this.paginator._intl.itemsPerPageLabel = 'Utilisateurs par page';
        this.dataSource.paginator = this.paginator;
    }

    private getBranchName(branchCode: number): string {
        // Find branch based on comparing externalId and GiftUser's branchCode
        return this.branches.find(s => s.externalId === this.giftService.convertBranchCodeToString(branchCode))?.name;
    }
}
