<div class="container-fluid m-0 py-3 py-md-5">

    <div class="my-5">
        <h1>Gestion des entités</h1>
    </div>

    <form [formGroup]="filterForm">
        <div class="row justify-content-center">
            <mat-form-field appearance="fill" class="col-lg-3">
                <mat-label>Filtrer par code entité</mat-label>
                <input matInput
                       data-cy="externalId"
                       formControlName="externalId"
                       id="externalIdForm"
                       maxlength="5"
                       type="text"/>
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-lg-3">
                <mat-label>Filtrer par nom</mat-label>
                <input matInput
                       data-cy="name"
                       formControlName="name"
                       id="nameForm"
                       maxlength="60"
                       type="text"/>
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-lg-3">
                <mat-label>Filtrer par code entité responsable</mat-label>
                <input matInput
                       data-cy="parentBranchExternalId"
                       formControlName="parentBranchExternalId"
                       id="parentBranchExternalIdForm"
                       type="text"/>
            </mat-form-field>
            <mat-form-field appearance="fill" class="col-lg-3">
                <mat-label>Filtrer par échelon</mat-label>
                <mat-select formControlName="levelId">
                    <mat-option [value]="0">Tout les utilisateurs</mat-option>
                    @for (level of hierarchicalLevels; track level.id) {
                        <mat-option [value]="level.id">{{ level.entity }}</mat-option>
                    }
                </mat-select>
            </mat-form-field>
        </div>
        <div class="row mt-2">
            <div class="col">
                <button (click)="filterBranches()"
                        [disabled]="loading"
                        class="btn btn-validate col-2 "
                        type="button">
                    Filtrer
                    @if (loading) {
                        <em aria-hidden="true"
                            class="fas fa-spinner fa-spin"></em>
                    }
                </button>
            </div>
            @if (!loading) {
                <div class="col">
                    <mat-checkbox [(ngModel)]="displayAllColumns" [ngModelOptions]="{standalone: true}">
                        Afficher les adresses et contacts
                    </mat-checkbox>
                </div>
            }
            <div class="col text-end">
                <button [disabled]="loading"
                        class="btn btn-validate col-2 me-3"
                        routerLink="/admin/network/branches/create"
                        type="button">
                    Créer
                    @if (loading) {
                        <em aria-hidden="true"
                            class="fas fa-spinner fa-spin"></em>
                    }
                </button>
                <button [disabled]="loading"
                        class="btn btn-validate"
                        routerLink="/admin/network/branches/import"
                        type="button">
                    Importer
                    @if (loading) {
                        <em aria-hidden="true"
                            class="fas fa-spinner fa-spin"></em>
                    }
                </button>
            </div>
        </div>
    </form>

    <div class="text-center py-5">
        @if (loading) {
            <em [style.font-size]="'30px'" aria-hidden="true" class="fas fa-spinner fa-spin"></em>
        }
    </div>

    @if (!loading) {

        <table [dataSource]="dataSource" [hidden]="!branches" aria-describedby="monitoring"
               class="my-5 w-100"
               mat-table matSort>

            <ng-container class="py-5" matColumnDef="externalId">
                <th *matHeaderCellDef id="externalId" mat-header-cell mat-sort-header>Code entité</th>
                <td *matCellDef="let branch" class="text-secondary" mat-cell>
                    {{ branch.externalId }}
                </td>
            </ng-container>

            <ng-container class="py-5" matColumnDef="name">
                <th *matHeaderCellDef id="name" mat-header-cell mat-sort-header>Nom entité</th>
                <td *matCellDef="let branch" class="text-secondary" mat-cell>
                    {{ branch.name }}
                </td>
            </ng-container>

            <ng-container class="py-5" matColumnDef="parentBranchExternalId">
                <th *matHeaderCellDef id="parentBranchExternalId" mat-header-cell mat-sort-header>Code entité supérieure
                </th>
                <td *matCellDef="let branch" class="text-secondary" mat-cell>
                    {{ branch.parentBranchExternalId }}
                </td>
            </ng-container>

            <ng-container class="py-5" matColumnDef="address1">
                <th *matHeaderCellDef id="address1" mat-header-cell mat-sort-header>Adresse 1</th>
                <td *matCellDef="let branch" class="text-secondary" mat-cell>
                    {{ branch.address1 }}
                </td>
            </ng-container>

            <ng-container class="py-5" matColumnDef="address2">
                <th *matHeaderCellDef id="address2" mat-header-cell mat-sort-header>Adresse 2</th>
                <td *matCellDef="let branch" class="text-secondary" mat-cell>
                    {{ branch.address2 }}
                </td>
            </ng-container>

            <ng-container class="py-5" matColumnDef="zipCode">
                <th *matHeaderCellDef id="zipCode" mat-header-cell mat-sort-header>Code postal</th>
                <td *matCellDef="let branch" class="text-secondary" mat-cell>
                    {{ branch.zipCode }}
                </td>
            </ng-container>

            <ng-container class="py-5" matColumnDef="city">
                <th *matHeaderCellDef id="city" mat-header-cell mat-sort-header>Ville</th>
                <td *matCellDef="let branch" class="text-secondary" mat-cell>
                    {{ branch.city }}
                </td>
            </ng-container>

            <ng-container class="py-5" matColumnDef="email">
                <th *matHeaderCellDef id="email" mat-header-cell mat-sort-header>Email</th>
                <td *matCellDef="let branch" class="text-secondary" mat-cell>
                    {{ branch.email }}
                </td>
            </ng-container>

            <ng-container class="py-5" matColumnDef="phone">
                <th *matHeaderCellDef id="phone" mat-header-cell mat-sort-header>Téléphone</th>
                <td *matCellDef="let branch" class="text-secondary" mat-cell>
                    {{ branch.phone }}
                </td>
            </ng-container>

            <ng-container class="py-5" matColumnDef="echelon">
                <th *matHeaderCellDef id="echelon" mat-header-cell mat-sort-header>Échelon</th>
                <td *matCellDef="let branch" class="text-secondary" mat-cell>
                    {{ getLevelName(branch.levelId) }}
                </td>
            </ng-container>

            <ng-container class="py-5" matColumnDef="action">
                <th *matHeaderCellDef id="action" mat-header-cell mat-sort-header></th>
                <td *matCellDef="let branch" class="text-secondary" mat-cell>
                        <span [routerLink]="'/admin/network/branches/' + branch.id" class="mx-2 text-purple clickable">
                            Editer&nbsp;>
                        </span>
                    @if (userService.hasRole('GIFT_ISIFID')) {
                        <span (click)="openDeleteBranchDialog(branch)" class="mx-2 text-purple clickable">
                            Supprimer&nbsp;>
                        </span>
                    }
                </td>
            </ng-container>

            <tr *matHeaderRowDef="displayAllColumns ? displayedColumns : displayedSimpleColumns" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayAllColumns ? displayedColumns : displayedSimpleColumns;"
                mat-row></tr>
        </table>
    }

    <mat-paginator
            [hidden]="!branches || dataSource.data.length < 25"
            [pageSizeOptions]="[25, 50, 100, 200]"
            aria-label="Select page of branches"
            showFirstLastButtons/>
</div>
