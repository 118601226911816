import {Component, OnInit} from '@angular/core';
import {Client, MsConsumersService} from '@isifid/core';
import {GiftService} from '../../../../../shared/services/gift.service';

@Component({
    selector: 'app-preview',
    templateUrl: './preview.component.html'
})
export class PreviewComponent implements OnInit {
    loading: any;
    type: string;
    private client: Client;
    private readonly PARTNER_LOGO_PLACEHOLDER_URL = 'https://cdn.isifid.com/static/b7bd7750-161a-466e-af06-045bae188735.png';

    constructor(
        private readonly msConsumersService: MsConsumersService,
        private readonly giftService: GiftService
    ) {
        this.type = 'access';
    }

    ngOnInit(): void {
        this.client = this.giftService.client;
    }

    previewEmail(value: string): void {
        this.type = value;
        const emailPreviewRequest = {
            clientId: this.client.id,
            type: this.type
        };
        this.msConsumersService.createEmailPreview(emailPreviewRequest).subscribe({
            next: (content) => {
                const w = window.open('', '_blank');
                if (w) {
                    content = content.replaceAll('${partnerLogoUrl}', this.PARTNER_LOGO_PLACEHOLDER_URL);
                    content = content.replaceAll('${consumerGender}', 'M.');
                    content = content.replaceAll('${consumerFirstName}', 'Prénom');
                    content = content.replaceAll('${consumerLastName}', 'Nom');
                    content = content.replaceAll('${rewardAmount}', '50');
                    content = content.replaceAll('${rewardExpireAt}', '31/12/2099');
                    content = content.replaceAll('${orderAmount}', '50');
                    content = content.replaceAll('${partnerName}', 'Nom du partenaire');
                    content = content.replaceAll('${partnerSalesConditions}', 'Conditions du partenaire');
                    content = content.replaceAll('${consumerBalance}', '50');
                    w.document.write(content);
                }
            }
        });
    }
}
