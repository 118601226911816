@if (!loading && giftService.hierarchicalLevels) {
    <div class="container-fluid">
        <section class="px-md-5">
            <div class="row">
                <div class="col-12 col-md-6 col-xl mx-auto my-5">
                    <h1 (click)="togglePanel('drive')" class="clickable">
                        Piloter <i class="fas fa-caret-down text-purple"></i>
                    </h1>
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="panel.drive" hideToggle>
                            <div class="pt-2"></div>
                            <app-list-operations />
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>

                <div class="col-12 col-md-6 col-xl mx-auto my-5">
                    <h1 (click)="togglePanel('create')" class="clickable">
                        Créer <i class="fas fa-caret-down text-purple"></i>
                    </h1>
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="panel.create" hideToggle>
                            <p class="pt-2 my-4">
                                <a routerLink="/admin/operations/request">Opération attributive de récompenses</a>
                            </p>
                            <p class="my-4">
                                @if (!hasSponsorship()) {
                                    <a routerLink="/admin/sponsorship/request">
                                        Programme de recommandation/parrainage
                                    </a>
                                }
                            </p>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>

                <div class="col-12 col-md-6 col-xl mx-auto my-5">
                    <h1 (click)="togglePanel('animate')" class="clickable">
                        Animer <i class="fas fa-caret-down text-purple"></i>
                    </h1>
                    <mat-accordion>
                        <mat-expansion-panel [expanded]="panel.animate" hideToggle>
                            <p class="pt-2 my-4">
                                <a routerLink="/admin/clients/news">Actualités</a>
                            </p>
                            <p class="my-4">
                                <a routerLink="/admin/clients/banners">Bannières</a>
                            </p>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </section>

        @if (userService.hasRole('GIFT_ISIFID')) {
            <section class="px-md-5 mt-5">
                <h1 class="text-center">Visible par ISIFID uniquement</h1>

                <div class="row mt-5">
                    <div class="col mx-auto">
                        <h2>Gestion</h2>
                        <p class="my-4">
                            <a routerLink="/admin/budgets/list">Gérer les Budgets Agence&nbsp;&nbsp;&nbsp;&nbsp;></a>
                        </p>
                        <p class="my-4">
                            <a routerLink="/admin/operations/custom-fields">Champs
                                personnalisés&nbsp;&nbsp;&nbsp;&nbsp;></a>
                        </p>
                        <p class="my-4">
                            <a routerLink="/admin/clients/parameters">Mes paramètres&nbsp;&nbsp;&nbsp;&nbsp;></a>
                        </p>
                        <p class="my-4">
                            <a routerLink="/admin/billing">Liste des factures&nbsp;&nbsp;&nbsp;&nbsp;></a>
                        </p>
                        <p class="my-4">
                            <a routerLink="/admin/reward-programs">Programme récompenses conseillers&nbsp;&nbsp;></a>
                        </p>
                        <p class="my-4">
                            <a routerLink="/admin/automation/operations">Marketing Automation&nbsp;&nbsp;&nbsp;&nbsp;></a>
                        </p>
                    </div>

                    <div class="col mx-auto">
                            @if (hasSponsorship()) {
                                <h2>Programme de parrainage</h2>
                                <div class="text-start mb-4">
                                    <p class="my-4">
                                        <a routerLink="/admin/stats/sponsorship">Reporting</a>
                                    </p>
                                    <p class="my-4">
                                        <a routerLink="/admin/sponsorship/settings">Paramètres Parrainage&nbsp;&nbsp;&nbsp;&nbsp;></a>
                                    </p>
                                </div>
                            }
                        <div class="text-start">
                            <h2>Récompenser</h2>
                            <p class="my-4">
                                <a routerLink="/admin/reward">Récompenser avec fichier&nbsp;&nbsp;&nbsp;&nbsp;></a>
                            </p>
                        </div>
                    </div>

                    <div class="col mx-auto">
                        <h2>Réseau</h2>
                        <p class="my-4">
                            <a routerLink="/admin/network/hierarchical-levels">Échelons
                                hiérarchiques&nbsp;&nbsp;&nbsp;&nbsp;></a>
                        </p>
                        @if (!giftService.hierarchicalLevels.length) {
                            <p >
                                Vous devez créer les différents échelons hiérarchiques avant de pouvoir utiliser les autres
                                fonctionnalités liées à la gestion du réseau.
                            </p>
                        } @else if (giftService.hierarchicalLevels?.length) {
                            <div>
                                <p class="my-4">
                                    <a routerLink="/admin/network/branches">Entités&nbsp;&nbsp;&nbsp;&nbsp;></a>
                                </p>
                                <p class="my-4">
                                    <a routerLink="/admin/network/users/manage">
                                        Gestion des utilisateurs&nbsp;&nbsp;&nbsp;&nbsp;></a>
                                </p>
                                <p class="my-4">
                                    <a routerLink="/admin/network/control">Contrôle de cohérence&nbsp;&nbsp;&nbsp;&nbsp;></a>
                                </p>
                            </div>
                        }
                    </div>
                </div>
            </section>
        }
    </div>
}
