import {Component, OnInit} from '@angular/core';
import {MsServicesSponsorshipService, SponsorshipContent} from '@isifid/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UIService} from '../../../../shared/services/ui.service';
import {environment} from '../../../../../environments/environment';
import {SponsorshipService} from '../../../../shared/services/sponsorship.service';

@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html'
})
export class SettingsComponent implements OnInit {
    allVariables: any;
    loading: boolean = true;
    modules: any;
    sponsorshipForm: FormGroup;
    url: string;
    private toolbarOptions = [
        ['bold', 'underline'],
        [{header: [1, 2, 3, 4, 5, 6, false]}],
        [{align: []}]
    ];

    constructor(
        public readonly sponsorshipService: SponsorshipService,
        public readonly uiService: UIService,
        private readonly formBuilder: FormBuilder,
        private readonly msServicesSponsorshipService: MsServicesSponsorshipService
    ) {
        this.modules = {toolbar: {container: this.toolbarOptions}};
    }

    ngOnInit(): void {
        // url for sponsorship
        this.url = this.getUrl();
        this.allVariables = this.uiService.getVariables(this.sponsorshipService.settings);

        this.msServicesSponsorshipService.getContent(this.sponsorshipService.settings.id).subscribe({
            next: (contentData) => {
                const content = new SponsorshipContent();
                Object.assign(content, contentData);
                this.sponsorshipForm = this.formBuilder.group(content);
                this.loading = false;
            }
        });
    }

    submit() {
        if (this.sponsorshipForm.invalid || this.sponsorshipForm.pristine) {
            return;
        }
        this.loading = true;

        this.msServicesSponsorshipService.putUpdateContent(this.sponsorshipService.settings.id, this.sponsorshipForm.getRawValue()).subscribe({
            next: () => this.loading = false,
            error: () => console.error('Error while updating sponsorship content')
        });
    }

    getUrl(): string {
        const str = `${this.sponsorshipService.settings.loginEmail},${this.sponsorshipService.settings.loginPassword},${this.sponsorshipService.settings.id}`;
        let url = this.sponsorshipService.settings.domain;
        if (!url) url = environment.domain;
        if (!url.startsWith('http')) url = `https://` + url;
        return `${url}/auth/${btoa(str).replace('=', '')}`;
    }

    getPreview(value: any) {
        this.allVariables.forEach((variable) => {
            value = value.toString().replaceAll(variable.var, variable.value);
        });
        return value;
    }
}
