@if (budget && level) {

    <div class="container-fluid m-0 px-5">

        <div class="row">
            <div class="col">
                <h1>Distribution de budget par {{level.entity}}</h1>
            </div>
        </div>

        <div class="row">
            <p class="col">
                Page d'ajout de credit 2 colonnes séparées par des espaces : PDV ou ID de l'utilisateur et montant<br>
            </p>
        </div>

        <form (submit)="addCredit()" [formGroup]="addCreditForm">

            <div class="row">
                <div class="col">
                    <p>Ajout de credit, 2 colonnes avec : PDV ou ID de l'utilisateur, montant</p>
                    <textarea class="w-100" data-cy="textarea" formControlName="data" id="data" rows="15"
                              type="text"></textarea>

                </div>
            </div>

        </form>

        <div class="row">
            <div class="col">
                <button (click)="addCredit()" class="btn btn-validate">Distribuer</button>
            </div>
        </div>

    </div>
}
