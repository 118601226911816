<div class="container-fluid">
    <h1 class="text-center my-5">Liste des Actualités</h1>
    <div class="col-10 mx-auto">

        <a class="btn btn-validate float-end mb-3" routerLink="0">Créer une Actualité</a>

        <table [dataSource]="dataSource" aria-describedby="news" class="my-3 w-100"
               mat-table matSort>

            <ng-container class="py-5" matColumnDef="title">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Titre</th>
                <td *matCellDef="let news" mat-cell>
                    {{news.title}}
                </td>
            </ng-container>
            <ng-container class="py-5" matColumnDef="content">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Contenu</th>
                <td *matCellDef="let news" mat-cell>
                    <div [innerHTML]="news.content" class="border m-1 p-2"></div>
                </td>
            </ng-container>
            <ng-container class="py-5" matColumnDef="status">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Statut</th>
                <td *matCellDef="let news" mat-cell>
                    {{getStatus(news.status)}}
                </td>
            </ng-container>
            <ng-container class="py-5" matColumnDef="publicationAt">
                <th *matHeaderCellDef mat-header-cell mat-sort-header class="text-nowrap">Publié le</th>
                <td *matCellDef="let news" mat-cell>
                    {{news.publicationAt | date:'dd/MM/yyyy':'undefined':'fr-FR' }}
                </td>
            </ng-container>
            <ng-container matColumnDef="actions">
                <th *matHeaderCellDef mat-header-cell mat-sort-header>Actions</th>
                <td *matCellDef="let news" mat-cell>
                    @if (!editing) {
                        <a (click)="editNews(news)"
                           class="clickable px-2"
                           title="Modifier l' actualité">
                            <i class="fas fa-edit"></i>
                        </a>
                    } @else {
                        <span class="px-2">
                            <em class="fas fa-spinner fa-spin"></em>
                        </span>
                    }
                    <a (click)="confirmDeleteNews(news)" class="clickable mt-2 px-2"
                       title="Supprimer l' actualité">
                        <i class="fas fa-trash-alt"></i>
                    </a>
                </td>
            </ng-container>

            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
            <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>

            <tr *matNoDataRow>
                <td [colSpan]="displayedColumns.length">
                    @if (!loading) {
                        <div class="text-center my-5">Pas de données</div>
                    } @else {
                        <div class="text-center my-5 fs-2"><em class="fas fa-spinner fa-spin"></em></div>
                    }
                </td>
            </tr>
        </table>
    </div>
</div>
