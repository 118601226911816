@if (!loading) {
    <div class="container-fluid m-0 px-5">
        <div class="my-5">
            <h1>Liste des utilisateurs de Gift</h1>
        </div>

        <div class="row">
            @if (users) {
                <form (ngSubmit)="filterUsers()" [formGroup]="filterUsersForm">
                    <div class="row">
                        <mat-form-field appearance="fill" class="col-lg-4">
                            <mat-label>Filtrer par échelon</mat-label>
                            <mat-select formControlName="levelId">
                                <mat-option value="">Tout les utilisateurs</mat-option>
                                @for (level of giftService.hierarchicalLevels; track level.id) {
                                    <mat-option [value]="+level.id">{{level.title}}</mat-option>
                                }
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="fill" class="col-lg-4">
                            <mat-label>Filtrer par code agence ou point de vente</mat-label>
                            <input matInput
                                   formControlName="branchCode"
                                   id="branchCodeInput"
                                   maxlength="5"
                                   type="text"/>
                        </mat-form-field>

                        <mat-form-field appearance="fill" class="col-lg-4">
                            <mat-label>Filtrer par Email</mat-label>
                            <input matInput
                                   formControlName="email"
                                   id="emailInput"
                                   type="email"/>
                        </mat-form-field>
                    </div>
                    <div class="row mt-2 justify-content-between">
                        <div class="col-auto">
                            <button [disabled]="loadingUsers"
                                    class="btn btn-validate"
                                    type="submit">
                                Filtrer
                                @if (loadingUsers) {
                                    <em aria-hidden="true" class="fas fa-spinner fa-spin"></em>
                                }
                            </button>
                        </div>
                        <div class="col-auto">
                            @if (users) {
                                <button (click)="extractUsers()" [disabled]="loadingUsers"
                                        class="btn btn-black ">
                                    <i aria-hidden="true" class="fas fa-file-excel mx-2"></i> Export Excel
                                    @if (loadingUsers) {
                                        <em aria-hidden="true" class="fas fa-spinner fa-spin"></em>
                                    }
                                </button>
                            }
                        </div>
                    </div>
                </form>
            }
        </div>
        <div class="responsive-table">
            <div class="responsive-table-body">
                <table [dataSource]="dataSource" [hidden]="!users" aria-describedby="monitoring" class="my-5 w-100"
                       mat-table matSort>
                    <ng-container class="py-5" matColumnDef="lastName">
                        <th *matHeaderCellDef id="lastName" mat-header-cell mat-sort-header>Nom</th>
                        <td *matCellDef="let user" class="text-grey" mat-cell>
                            {{user.lastName}}
                        </td>
                    </ng-container>

                    <ng-container class="py-5" matColumnDef="firstName">
                        <th *matHeaderCellDef id="firstName" mat-header-cell mat-sort-header>Prénom</th>
                        <td *matCellDef="let user" class="text-grey" mat-cell>
                            {{user.firstName}}
                        </td>
                    </ng-container>

                    <ng-container class="py-5" matColumnDef="phone">
                        <th *matHeaderCellDef id="phone" mat-header-cell mat-sort-header>N° de portable</th>
                        <td *matCellDef="let user" class="text-grey" mat-cell>
                            {{user.phone}}
                        </td>
                    </ng-container>

                    <ng-container class="py-5" matColumnDef="email">
                        <th *matHeaderCellDef id="email" mat-header-cell mat-sort-header>Email</th>
                        <td *matCellDef="let user" class="text-grey" mat-cell>
                            {{user.email}}
                        </td>
                    </ng-container>

                    <ng-container class="py-5" matColumnDef="branchName">
                        <th *matHeaderCellDef id="branchName" mat-header-cell mat-sort-header>Entité</th>
                        <td *matCellDef="let user" class="text-grey" mat-cell>
                            @if (userIsAdvisor(user) && user.branchCode) {
                                <span>{{user.branchName}} ({{user.branchCode}})</span>
                            } @else {
                                <span>{{user.branchCodes}}</span>
                            }
                        </td>
                    </ng-container>

                    <ng-container class="py-5" matColumnDef="lastLoggedInAt">
                        <th *matHeaderCellDef id="lastLoggedInAt" mat-header-cell mat-sort-header>Dernière connexion</th>
                        <td *matCellDef="let user" class="text-grey" mat-cell>
                            {{user.lastLoggedInAt | date: 'shortDate':'+2':'fr'}}
                        </td>
                    </ng-container>

                    <ng-container class="py-5" matColumnDef="level">
                        <th *matHeaderCellDef id="leve" mat-header-cell mat-sort-header>Échelon</th>
                        <td *matCellDef="let user" class="text-grey" mat-cell>
                            {{getTitle(user.levelId)}}
                        </td>
                    </ng-container>

                    <ng-container class="py-5" matColumnDef="action" stickyEnd>
                        <th *matHeaderCellDef id="action" mat-header-cell></th>
                        <td *matCellDef="let user" class="text-grey" mat-cell>
                            <a [queryParams]="{uuid: user.uuid}" [routerLink]="'/account/user'"
                                  class="mx-2 text-purple clickable text-decoration-none">
                                Compte&nbsp;>
                            </a>
                        </td>
                    </ng-container>


                    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                    <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                </table>
            </div>
            <mat-paginator
                    [hidden]="!users || dataSource.data.length < 25"
                    [pageSizeOptions]="[25, 50, 100]"
                    aria-label="Select page of user"
                    showFirstLastButtons />
        </div>
    </div>
}
