<div class="container-fluid min-vh-100 pt-5">

    <h1 class="my-5 text-center">Création d'une opération</h1>

    <div class="col-6 offset-3 mb-5">
        <form (submit)="submit()" [formGroup]="operationForm" class="mb-5">
            <div>
                <h2>Nature de l'opération</h2>

                <div class="row my-2">
                    <div>
                        <input value="event" formControlName="eventType" id="event" type="radio">
                        <label class="check-box ms-3" for="event">Opération relative à un évènement (ex:
                            Anniversaire)</label>
                    </div>
                    <div>
                        <input value="action" formControlName="eventType" id="behaviourType" type="radio">
                        <label class="check-box ms-3" for="behaviourType">
                            Opération relative à une action (ex: Parrainage)</label>
                    </div>
                    <div>
                        <input value="neither" formControlName="eventType" id="neither" type="radio">
                        <label class="check-box ms-3" for="neither">Ni l'un ni l'autre</label>
                    </div>

                </div>

                <!--      event          -->
                @if (operationForm.get('eventType').value === 'event') {
                    <div class="row my-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Lequel ?</mat-label>
                            <mat-select formControlName="eventCustomName">
                                <mat-option value="Anniversaire">Anniversaire</mat-option>
                                <mat-option value="Diplôme">Diplôme</mat-option>
                                <mat-option value="Naissance">Naissance</mat-option>
                                <mat-option value="Mariage">Mariage</mat-option>
                                <mat-option value="Retraite">Retraite</mat-option>
                                <mat-option value="custom">Autre</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                }

                @if (operationForm.get('eventCustomName').value === 'custom') {
                    <div class="row my-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Merci de préciser le nom du produit ou service :</mat-label>
                            <input matInput
                                   formControlName="eventName"
                                   id="eventNameCustom"
                                   type="text"/>
                        </mat-form-field>
                    </div>
                }

                <!--      action          -->
                <div [hidden]="operationForm.get('eventType').value !== 'action' && operationForm.get('eventType').value"
                     class="row my-2">
                    <mat-form-field appearance="fill">
                        <mat-label>Laquelle ?</mat-label>
                        <mat-select formControlName="behaviourType">
                            <mat-option value="Parrainage / Recommandation">Parrainage / Recommandation</mat-option>
                            <mat-option value="Entrée en relation">Entrée en relation</mat-option>
                            <mat-option value="RDV Agence">RDV Agence</mat-option>
                            <mat-option value="Multi-détention">Multi-détention</mat-option>
                            <mat-option value="Sociétaire">Sociétaire</mat-option>
                            <mat-option value="Geste commercial">Geste commercial</mat-option>
                            <mat-option value="custom">Autre</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                @if (operationForm.get('behaviourType').value === 'custom') {
                    <div class="row my-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Merci de préciser :</mat-label>
                            <input matInput
                                   formControlName="behaviourCustomName"
                                   id="behaviourCustomName"
                                   type="text"/>
                        </mat-form-field>
                    </div>
                }

                @if (isEventValid()) {
                    <div class="row my-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Récompense conditionnée à la souscription d'un produit ou service financier</mat-label>
                            <mat-select formControlName="subscription" (selectionChange)="updateSubscription($event)">
                                <mat-option [value]="true">OUI</mat-option>
                                <mat-option [value]="false">NON</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                }

                @if (isEventValid() && operationForm.get('subscription').value) {
                    <div class="row my-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Lequel ?</mat-label>
                            <mat-select formControlName="subscriptionType" (selectionChange)="checkIfOperationValid()">
                                <mat-option value="Moyen de paiement">Moyen de paiement</mat-option>
                                <mat-option value="Mobilité bancaire">Mobilité bancaire</mat-option>
                                <mat-option value="Épargne">Épargne</mat-option>
                                <mat-option value="Crédit">Crédit</mat-option>
                                <mat-option value="Assurance">Assurance</mat-option>
                                <mat-option value="custom">Autre</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                }

                @if (
                    isEventValid() && operationForm.get('subscription').value &&
                    operationForm.get('subscriptionType').value !== '-' && operationForm.get('subscriptionType').value !== 'Mobilité bancaire'
                ) {
                    <div class="row my-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Merci de préciser le nom du produit ou service :</mat-label>
                            <input matInput
                                   (change)="checkIfOperationValid()"
                                   formControlName="subscriptionCustomName"
                                   id="subscriptionCustomName"
                                   type="text"/>
                        </mat-form-field>
                    </div>
                }

                <!--     offers           -->
                @if (operationForm.get('behaviourType').value !== 'Parrainage / Recommandation' && operationForm.get('subscriptionType').value !== '0') {
                    <div>
                        @if (isEventValid() && isBehaviourValid() && isSubscriptionValid()) {
                            <div class="row my-2">
                                <mat-form-field appearance="fill">
                                    <mat-label>Opération comportant plusieurs offres (ex : Mention AB/B/TB) ?</mat-label>
                                    <mat-select formControlName="multipleOffers" (selectionChange)="checkIfOperationValid()">
                                        <mat-option value="1">OUI</mat-option>
                                        <mat-option value="0">NON</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        }

                        @if (operationForm.get('multipleOffers').value === '1') {
                            <div class="row my-2">
                                <h2>Offres</h2>

                                @if (offers) {
                                    <ng-container formArrayName="offers">
                                        @for (offerForm of offers.controls; track i; let i = $index) {
                                            <div [formGroup]="offerForm" class="my-3">
                                                <mat-form-field appearance="fill">
                                                    <mat-label>
                                                        @if (offers.length === 1) {Nom de l'offre}
                                                        @else if (offers.length > 1) {Nom de l'offre  {{i + 1}}}
                                                    </mat-label>
                                                    <input matInput
                                                           [id]="'name' + i"
                                                           formControlName="name"
                                                           type="text"/>
                                                </mat-form-field>
                                            </div>
                                        }
                                    </ng-container>
                                }

                                <div class="mb-5">
                                    <button (click)="addOffer()"
                                            class="btn btn-primary" type="button">
                                        Ajouter une offre
                                    </button>
                                    @if (offers.length > 2) {
                                        <button (click)="removeLastOffer()"
                                                class="btn btn-danger ms-5" type="button">
                                            Annuler la dernière offre
                                        </button>
                                    }
                                </div>

                            </div>
                        }
                    </div>
                }
            </div>

            <!--    reward        -->
            @if (isOperationValid) {
                <div class="mt-5">
                    <h2>Nature de la récompense</h2>

                    <div class="row my-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Récompense en cartes cadeaux Isifid ?</mat-label>
                            <mat-select formControlName="isIsifidReward" (selectionChange)="checkIfRewardValid()">
                                <mat-option value="1">OUI</mat-option>
                                <mat-option value="0">NON</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    @if (operationForm.get('isIsifidReward').value === '0') {
                        <div class="row my-2">
                            <mat-form-field appearance="fill">
                                <mat-label>Quelle récompense ?</mat-label>
                                <mat-select formControlName="rewardTypeId" (selectionChange)="checkIfRewardValid()">
                                    <mat-option value="10">Cash</mat-option>
                                    <mat-option value="11">Remise sur vos produits</mat-option>
                                    <mat-option value="12">Autres cartes cadeaux</mat-option>
                                    <mat-option value="13">Autre</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    }

                    @if (operationForm.get('rewardTypeId').value === '13') {
                        <div class="row my-2">
                            <mat-form-field appearance="fill">
                                <mat-label>Merci de préciser :</mat-label>
                                <input matInput
                                       (change)="checkIfRewardValid()"
                                       formControlName="rewardTypeCustom"
                                       id="rewardTypeCustom"
                                       type="text"/>
                            </mat-form-field>
                        </div>
                    }

                    <div [hidden]="operationForm.get('isIsifidReward').value !== '1' && operationForm.get('isIsifidReward').value"
                         class="row my-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Quelle récompense ?</mat-label>
                            <mat-select formControlName="rewardTypeId" (selectionChange)="checkIfRewardValid()">
                                <mat-option value="0">Regular</mat-option>
                                <mat-option value="2">Premium</mat-option>
                                <mat-option value="3">Platinium</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            }

            @if (isOperationValid && isRewardValid && operationForm.get('behaviourType').value !== 'Parrainage / Recommandation') {
                <div class="mt-5">
                    <h2>Montant de la récompense</h2>

                    <ng-container formArrayName="offers">
                        @for (offerForm of offers.controls; track i; let i = $index) {
                            <div [formGroup]="offerForm">
                                <mat-form-field appearance="fill">
                                    <mat-label>Montant de l'offre {{i + 1}}
                                        : {{offerForm.value.name}}</mat-label>
                                    <input matInput
                                           [id]="'price' + i"
                                           formControlName="price"
                                           min="0"
                                           step="10"
                                           type="number"/>
                                </mat-form-field>
                            </div>
                        }
                    </ng-container>
                </div>
            }

            <!--     sponsorship       -->
            @if (isOperationValid && isRewardValid && operationForm.get('behaviourType').value === 'Parrainage / Recommandation') {
                <div class="mt-5">
                    <h2>Montant de la récompense Parrain / Filleul</h2>
                    <div class="d-flex gap-1">
                        <mat-form-field appearance="fill">
                            <mat-label>Montant Parrain</mat-label>
                            <input matInput
                                   formControlName="sponsorAmount"
                                   id="sponsorAmount"
                                   min="0"
                                   step="10"
                                   type="number"/>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                            <mat-label>Nombre maximum de filleuls</mat-label>
                            <input matInput
                                   formControlName="maxSponsored"
                                   id="maxSponsored"
                                   min="0"
                                   step="5"
                                   type="number"/>
                        </mat-form-field>
                    </div>
                    <div class="d-flex">
                        <mat-form-field appearance="fill">
                            <mat-label>Montant Filleul</mat-label>
                            <input matInput
                                   formControlName="sponsoredAmount"
                                   id="sponsoredAmount"
                                   min="0"
                                   step="10"
                                   type="number"/>
                        </mat-form-field>
                        <div class="mx-2 w-100"></div>
                    </div>
                </div>
            }

            <!--    dates        -->
            @if (isOperationValid && isRewardValid) {
                <div class="mt-5">
                    <h2>Durée de l'opération</h2>

                    <div class="row my-2">
                        <mat-form-field appearance="fill">
                            <mat-label>Date de début</mat-label>
                            <input matInput formControlName="startAt" id="startAt" [matDatepicker]="startAt"/>
                            <mat-datepicker-toggle matIconSuffix [for]="startAt" />
                            <mat-datepicker #startAt />
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Date de fin</mat-label>
                            <input matInput formControlName="endAt" id="endAt" [matDatepicker]="endAt"/>
                            <mat-datepicker-toggle matIconSuffix [for]="endAt" />
                            <mat-datepicker #endAt />
                        </mat-form-field>

                        <p>Merci d'utiliser le format jour/mois/année – jour/mois/année</p>

                        @if (operationForm.get('startAt').hasError('wrongTimeFrame')) {
                            <p>
                                La date de début doit être inférieure à la date de fin.
                            </p>
                        }
                    </div>
                </div>
            }

            <!--    reward process        -->
            @if (isOperationValid && isRewardValid && isTimeFrameValid) {
                <div class="mt-5">
                    <h2>Mode d'attribution des récompenses</h2>

                    <div class="row my-2">
                        <p>
                            Souhaitez-vous que tous les clients ayant accompli l’acte éligible à récompense soient
                            récompensés (attribution systématique) ou uniquement ceux que les conseillers ont choisi
                            de récompenser (attribution sélective) ?
                        </p>
                        <p>Mode d'attribution des récompenses</p>
                        <mat-form-field appearance="fill">
                            <mat-select formControlName="rewardProcess">
                                <mat-option value="always">Attribution systématique</mat-option>
                                <mat-option value="on-demand">Attribution sélective</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    @if (operationForm.get('rewardProcess').value !== '') {
                        <div class="row my-2">
                            <mat-form-field appearance="fill">
                                <mat-label>De quelle façon ?</mat-label>
                                <mat-select formControlName="consumerCreationProcess">
                                    @if (operationForm.get('rewardProcess').value === 'always') {
                                        <mat-option value="file">
                                            Par transmission de fichier en fin d'opération
                                        </mat-option>
                                        <mat-option value="platform">
                                            Par saisie de chaque bénéficiaire par les conseillers
                                        </mat-option>
                                    } @else if (operationForm.get('rewardProcess').value === 'on-demand') {
                                        <mat-option value="platform">
                                            À l'initiative de chaque conseiller, sans contrainte budgétaire
                                        </mat-option>
                                        <mat-option value="platform-credit">
                                            À l'initiative de chaque conseiller, avec contrainte budgétaire
                                        </mat-option>
                                    }
                                </mat-select>
                            </mat-form-field>
                        </div>
                    }
                    @if (operationForm.get('consumerCreationProcess').value === 'platform-credit') {
                        <div class="row my-2" formGroupName="budget">
                            <p>Plafond de votre engagement HT (en euros)</p>
                            <mat-form-field appearance="fill">
                                <mat-label>Plafond de votre engagement HT (en euros)</mat-label>
                                <input matInput
                                       formControlName="budgetAmount"
                                       id="budgetAmount"
                                       step="1000"
                                       type="number"/>
                            </mat-form-field>
                        </div>

                        <mat-form-field appearance="fill">
                            <mat-label>Valeur totale des récompenses :</mat-label>
                            <input matInput disabled
                                   [value]="totalReward()"
                                   id="totalReward"
                                   type="number"/>
                        </mat-form-field>

                        <mat-form-field appearance="fill">
                            <mat-label>Souhaitez-vous que le montant total des récompenses soient réparti par :</mat-label>
                            <mat-select formControlName="rewardLevel">
                                <mat-option value="direction d\'agences">Direction d'agences</mat-option>
                                <mat-option value="agences">Agences</mat-option>
                                <mat-option value="conseiller">Conseiller</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div>
                            <p>Allocation par {{operationForm.get('rewardLevel').value}} :</p>
                            <div class="row my-2" formGroupName="budget">
                                <mat-form-field appearance="fill">
                                    <mat-label>Montant par conseiller</mat-label>
                                    <input matInput
                                           [value]="rewardByAdvisor()"
                                           id="rewardByAdvisor"
                                           type="number"/>
                                </mat-form-field>
                            </div>
                        </div>
                    }

                    <div class="mt-5">
                        <h2>Mentions légales spécifiques à l'opération</h2>

                        <p>
                            Mentions légales qui portent non sur la récompense mais sur les éventuelles conditions
                            d’attribution spécifiques à l’opération
                        </p>

                        <mat-form-field>
                            <mat-label>Mentions légales :</mat-label>
                            <textarea matInput formControlName="legal" rows="5"></textarea>
                        </mat-form-field>
                    </div>

                    <div class="mt-5">
                        <h2>Texte libre</h2>
                        <mat-form-field>
                            <textarea matInput formControlName="text" rows="3"></textarea>
                        </mat-form-field>
                    </div>

                    <div class="mt-5">
                        <h2>Observations éventuelles</h2>

                        <mat-form-field>
                            <textarea matInput formControlName="observation" rows="3"></textarea>
                        </mat-form-field>
                    </div>


                    <button [disabled]="sent || loading" class="btn btn-validate" type="submit">Valider</button>

                    <p class="small mt-3">
                        Une 2ème confirmation après récapitulatif vous sera adressée par Isifid sous 1 jour ouvré
                    </p>
                </div>
            }
        </form>
    </div>

</div>
