import {Component, OnInit, ViewChild} from '@angular/core';
import {Budget, MsServicesGiftService, UserService} from '@isifid/core';
import {GiftService} from '../../../../shared/services/gift.service';
import {MatSort} from '@angular/material/sort';
import {OperationsService} from '../../../../shared/services/operations.service';

@Component({
    selector: 'app-budgets-list',
    templateUrl: './list.component.html'
})
export class BudgetsListComponent implements OnInit {
    loading = true;
    budgets: Array<Budget> = [];
    displayedColumns = ['operation', 'amountTotal', 'amountRemaining', 'status', 'actions'];
    @ViewChild(MatSort) sortBilling: MatSort;

    constructor(
        public readonly userService: UserService,
        private readonly giftService: GiftService,
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly operationsService: OperationsService
    ) {
    }

    ngOnInit() {
        this.msServicesGiftService.getBudgetsByClientId(this.giftService.client.id).subscribe({
            next: (budgets) => {
                this.budgets = budgets;
                this.loading = false;
            }
        });
    }

    getOperationName(operationId): string {
        const operations = this.operationsService.getOperations(false, false).filter((o) => o.id === operationId);
        if (!operations.length) return 'Opération non trouvée';
        return operations[0].name;
    }

    getStatus(status): string {
        switch (status) {
        case 'active':
            return 'En cours';
        case 'expired':
            return 'Expiré';
        case 'draft':
            return 'Non actif';
        default:
            return '';
        }
    }
}
