<div class="container-fluid min-vh-100 pt-5">
    @if (loading) {<mat-spinner class="mx-auto" />}
    @else {
        <div class="px-5">
            <h1>Marketing automation</h1>
            <h2 class="text-center">Liste des opérations avec budgets</h2>
            <div class="row justify-content-center mt-5">
                @if (operations.length === 0) {
                    <p class="text-center">
                        Aucune opération avec budget de définie pour le moment.
                    </p>
                }
                <table [dataSource]="operations" [hidden]="operations.length === 0" aria-describedby="operations" class="my-3 w-100"
                       mat-table matSort>

                    <ng-container class="py-5" matColumnDef="name">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>Opération</th>
                        <td *matCellDef="let operation" class="text-grey" mat-cell>
                            {{operation.name}}
                        </td>
                    </ng-container>
                    <ng-container class="py-5" matColumnDef="startDate">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>Date de début</th>
                        <td *matCellDef="let operation" class="text-grey" mat-cell>
                            {{ operation.startDate | date:'dd/MM/yyyy' }}
                        </td>
                    </ng-container>
                    <ng-container class="py-5" matColumnDef="endDate">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>Date de fin</th>
                        <td *matCellDef="let operation" class="text-grey" mat-cell>
                            {{ operation.endDate | date:'dd/MM/yyyy' }}
                        </td>
                    </ng-container>
                    <ng-container class="py-5" matColumnDef="status">
                        <th *matHeaderCellDef mat-header-cell mat-sort-header>Statut</th>
                        <td *matCellDef="let operation" class="text-grey" mat-cell>
                            {{getStatus(operation.status)}}
                        </td>
                    </ng-container>
                    <ng-container class="py-5" matColumnDef="actions">
                        <th *matHeaderCellDef mat-header-cell>Actions</th>
                        <td *matCellDef="let operation" class="text-grey" mat-cell>
                            @if (userService.hasRole('GIFT_ISIFID')) {
                                <button class="btn btn-validate ms-2"
                                        routerLink="/admin/automation/operations/{{operation.id}}">
                                    Détails
                                </button>
                            }
                        </td>
                    </ng-container>

                    <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                    <tr *matRowDef="let row; columns: displayedColumns" mat-row></tr>
                </table>
            </div>
        </div>
    }
</div>
