<div class="container-fluid min-vh-100 text-center">

    <h1 class="my-5">Operations : champs personnalisés</h1>

    <div class="d-flex flex-row justify-content-start my-5 px-5">
        <mat-form-field appearance="fill" class="mx-2">
            <mat-label>Opérations</mat-label>
            <mat-select (selectionChange)="changeOperation($event)">
                @for (operation of this.operations; track operation.id) {
                    <mat-option [value]="operation.id">
                        {{operation.name}} - id: {{operation.id}} - {{operation.status}}
                    </mat-option>
                }
            </mat-select>
        </mat-form-field>

        <!-- Display offers -->
        @if (offers?.length) {
            <mat-form-field appearance="fill" class="mx-2">
                <mat-label>Offres</mat-label>
                <mat-select (selectionChange)="changeOffer()" [(ngModel)]="selectedOfferId">
                    @for (offer of offers; track offer.id) {
                        <mat-option [value]="offer.id">
                            {{offer.name}} - {{offer.amount}}€ - id: {{offer.id}} - {{offer.status}}
                        </mat-option>
                    }
                </mat-select>
            </mat-form-field>
        }
    </div>

    @if (!selectedOperation) {
        <div>
            <p class="my-5 text-center">Merci de sélectionner une opération</p>
        </div>
    }

    <div>
        @if (isLoading === 'all') {
            <ng-container [ngTemplateOutlet]="isLoadingTemp" />
        }
    </div>

    @if (selectedOperation && isLoading !== 'all') {

        <mat-tab-group>
            <!-- Modify  --------------------------------------------------------------------------------------- -->
            <mat-tab label="Modifier">
                <div class="row justify-content-center w-100 my-5">
                    @if (!selectedOfferId) {
                        <p class="text-center">Merci de sélectionner une offre</p>
                    } @else {
                        <div class="col-md-6">
                            @if (customRewardFormFields.length){
                                <mat-form-field appearance="fill">
                                    <mat-label>Champs perso</mat-label>
                                    <mat-select (selectionChange)="initUpdateCustomFieldForm($event)">
                                        @for (field of customRewardFormFields; track field) {
                                            @if (field['offerId'] === selectedOfferId) {
                                                <mat-option [value]="field">
                                                    OfferId : {{field['offerId']}} {{field.name}}
                                                </mat-option>
                                            }
                                        }
                                    </mat-select>
                                </mat-form-field>
                            } @else {
                                <p class="my-5 text-center">Il n'y a pas de champ</p>
                            }

                            @if (isLoading === 'update') {
                                <ng-container [ngTemplateOutlet]="isLoadingTemp" />
                            } @else if (customFieldSelected) {
                                <form class="my-5 text-center" (ngSubmit)="updateCustomField()" [formGroup]="updateCustomFieldForm">
                                    <mat-form-field appearance="fill" class="mt-4">
                                        <mat-label>Ordre</mat-label>
                                        <input matInput
                                               formControlName="order"
                                               id="orderModify"
                                               type="number"/>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill" class="mt-4">
                                        <mat-label>Nom</mat-label>
                                        <input matInput
                                               formControlName="name"
                                               id="nameModify"
                                               type="text"/>
                                    </mat-form-field>

                                    <div class="mt-4">
                                        <input formControlName="required" id="requiredModify" type="checkbox">
                                        <label class="check-box ms-5" for="requiredModify">Requis</label>
                                    </div>
                                    @if (updateCustomFieldForm.controls.required.invalid && updateCustomFieldForm.touched) {
                                        <mat-error>Requis est obligatoire</mat-error>
                                    }

                                    <div class="mt-4">
                                        <input formControlName="saveInDatabase" id="saveInDatabaseModify" type="checkbox">
                                        <label class="check-box ms-5" for="saveInDatabaseModify">Sauvegarder valeur</label>
                                    </div>
                                    @if (updateCustomFieldForm.controls.saveInDatabase.invalid && updateCustomFieldForm.touched) {
                                        <mat-error>Enregistrer valeur est obligatoire</mat-error>
                                    }
                                    <mat-form-field appearance="fill" class="mt-4">
                                        <mat-label>Type</mat-label>
                                        <mat-select formControlName="type">
                                            <mat-option value="text">Text</mat-option>
                                            <mat-option value="email">Email</mat-option>
                                            <mat-option value="checkbox">Checkbox</mat-option>
                                            <mat-option value="link">Lien</mat-option>
                                            <mat-option value="condition">Condition</mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Valeur par défault</mat-label>
                                        <input matInput
                                               formControlName="defaultValue"
                                               id="defaultValueModify"
                                               type="text"/>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Placeholder</mat-label>
                                        <input matInput
                                               formControlName="placeholder"
                                               id="placeholderModify"
                                               type="text"/>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Message d'aide</mat-label>
                                        <input matInput
                                               formControlName="hint"
                                               id="hintModify"
                                               type="text"/>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Caractères Minimum</mat-label>
                                        <input matInput
                                               formControlName="minLength"
                                               id="minLengthModify"
                                               type="number"/>
                                    </mat-form-field>
                                    <mat-form-field appearance="fill">
                                        <mat-label>Caractères Maximum</mat-label>
                                        <input matInput
                                               formControlName="maxLength"
                                               id="maxLengthModify"
                                               type="number"/>
                                    </mat-form-field>
                                    <button class="my-3 btn btn-validate px-4 py-2" type="submit">
                                        Modifier
                                    </button>
                                </form>
                            }
                        </div>
                    }
                </div>
            </mat-tab>
            <!-- Add  ------------------------------------------------------------------------------------------ -->
            <mat-tab label="Ajouter">
                <div class="row justify-content-center w-100 my-5">
                    @if (!selectedOfferId) {
                        <p class="text-center">Merci de sélectionner une offre</p>
                    }
                    @if (isLoading === 'add') {
                        <ng-container [ngTemplateOutlet]="isLoadingTemp" />
                    } @else if (selectedOfferId) {
                        <form (ngSubmit)="addCustomField()" [formGroup]="addCustomFieldForm" class="col-md-6">
                            <mat-form-field appearance="fill" class="mt-4">
                                <mat-label>Ordre</mat-label>
                                <input matInput
                                       formControlName="order"
                                       id="orderAdd"
                                       type="number">
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="mt-4">
                                <mat-label>Nom</mat-label>
                                <input matInput
                                       formControlName="name"
                                       id="nameAdd"
                                       type="text">
                            </mat-form-field>
                            <div class="mt-4">
                                <input formControlName="required" id="requiredAdd" type="checkbox">
                                <label class="check-box ms-5" for="requiredAdd">Requis</label>
                            </div>
                            @if (addCustomFieldForm.controls.required.invalid && addCustomFieldForm.touched) {
                                <mat-error>Requis est obligatoire</mat-error>
                            }
                            <div class="mt-4">
                                <input formControlName="saveInDatabase" id="saveInDatabaseAdd" type="checkbox">
                                <label class="check-box ms-5" for="saveInDatabaseAdd">Sauvegarder valeur</label>
                            </div>
                            @if (addCustomFieldForm.controls.saveInDatabase.invalid && addCustomFieldForm.touched) {
                                <mat-error>
                                    Enregistrer valeur est obligatoire
                                </mat-error>
                            }
                            <mat-form-field appearance="fill" class="mt-4">
                                <mat-label>Type</mat-label>
                                <mat-select (selectionChange)="updateType($event)" formControlName="type">
                                    <mat-option value="text">Text</mat-option>
                                    <mat-option value="email">Email</mat-option>
                                    <mat-option value="checkbox">Checkbox</mat-option>
                                    <mat-option value="link">Lien</mat-option>
                                    <mat-option value="condition">Condition</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="mt-4">
                                <mat-label>Valeur par défault</mat-label>
                                <input matInput
                                       formControlName="defaultValue"
                                       id="defaultValueAdd"
                                       type="text">
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="mt-4">
                                <mat-label>Placeholder</mat-label>
                                <input matInput
                                       formControlName="placeholder"
                                       id="placeholderAdd"
                                       type="text">
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="mt-4">
                                <mat-label>Message d'aide</mat-label>
                                <input matInput
                                       formControlName="hint"
                                       id="hintAdd"
                                       type="text">
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="mt-4">
                                <mat-label>Caractères Minimum</mat-label>
                                <input matInput
                                       formControlName="minLength"
                                       id="minLengthAdd"
                                       type="number">
                            </mat-form-field>
                            <mat-form-field appearance="fill" class="mt-4">
                                <mat-label>Caractères Maximum</mat-label>
                                <input matInput
                                       formControlName="maxLength"
                                       id="maxLengthAdd"
                                       type="number">
                            </mat-form-field>

                            <button class="my-3 btn btn-validate px-4 py-2" type="submit">Ajouter</button>

                        </form>
                    }
                </div>
            </mat-tab>
            <!-- list  ----------------------------------------------------------------------------------------- -->
            <mat-tab label="Liste">
                <div class="responsive-table my-5">
                    @if (isLoading === 'delete') {
                        <ng-container [ngTemplateOutlet]="isLoadingTemp" />
                    }
                    @if (!dataSource?.data.length) {
                        <p class="text-center">Il n'y a pas de champ</p>
                    }

                    <div class="responsive-table-body">
                        <table [dataSource]="dataSource"
                               [hidden]="!dataSource?.data.length || isLoading === 'delete'"
                               aria-describedby="tracking"
                               class="w-100 text-center"
                               mat-table>
                            <ng-container class="py-5" matColumnDef="offerId">
                                <th *matHeaderCellDef class="text-center" id="offerId" mat-header-cell>Offer Id</th>
                                <td *matCellDef="let customField" class="text-grey" mat-cell>
                                    {{customField.offerId}}
                                </td>
                            </ng-container>

                            <ng-container class="py-5" matColumnDef="order">
                                <th *matHeaderCellDef class="text-center" id="order" mat-header-cell> Ordre</th>
                                <td *matCellDef="let customField" class="text-secondary" mat-cell>
                                    {{customField.order}}
                                </td>
                            </ng-container>

                            <ng-container class="py-5" matColumnDef="name">
                                <th *matHeaderCellDef class="text-center" id="name" mat-header-cell> Nom</th>
                                <td *matCellDef="let customField" class="text-secondary text-nowrap" mat-cell>
                                    {{customField.name}}
                                </td>
                            </ng-container>

                            <ng-container class="py-5" matColumnDef="type">
                                <th *matHeaderCellDef class="text-center" id="type" mat-header-cell> Type</th>
                                <td *matCellDef="let customField" class="text-secondary text-nowrap" mat-cell>
                                    {{customField.type}}
                                </td>
                            </ng-container>

                            <ng-container class="py-5" matColumnDef="saveInDatabase">
                                <th *matHeaderCellDef class="text-center" id="saveInDatabase" mat-header-cell>
                                    Sauvegarder
                                </th>
                                <td *matCellDef="let customField" class="text-grey" mat-cell>
                                    @if (customField.saveInDatabase) {
                                        <i aria-hidden="true" class="fas fa-2x fa-check-circle"></i>
                                    } @else {
                                        <i aria-hidden="true" class="fas fa-2x fa-times-circle"></i>
                                    }
                                </td>
                            </ng-container>

                            <ng-container class="py-5" matColumnDef="required">
                                <th *matHeaderCellDef class="text-center" id="required" mat-header-cell> Requis</th>
                                <td *matCellDef="let customField" class="text-secondary" mat-cell>
                                    @if (customField.required) {
                                        <i aria-hidden="true" class="fas fa-2x fa-check-circle"></i>
                                    } @else {
                                        <i aria-hidden="true" class="fas fa-2x fa-times-circle"></i>
                                    }
                                </td>
                            </ng-container>

                            <ng-container class="py-5" matColumnDef="placeholder">
                                <th *matHeaderCellDef class="text-center" id="placeholder" mat-header-cell>Placeholder
                                </th>
                                <td *matCellDef="let customField" class="text-grey text-nowrap" mat-cell>
                                    {{customField.placeholder}}
                                </td>
                            </ng-container>

                            <ng-container class="py-5" matColumnDef="hint">
                                <th *matHeaderCellDef class="text-center" id="hint" mat-header-cell>Hint
                                </th>
                                <td *matCellDef="let customField" class="text-secondary text-nowrap" mat-cell>
                                    {{customField.hint}}
                                </td>
                            </ng-container>

                            <ng-container class="py-5" matColumnDef="defaultValue">
                                <th *matHeaderCellDef class="text-center" id="defaultValue" mat-header-cell>Valeur par
                                    default
                                </th>
                                <td *matCellDef="let customField" class="text-grey text-nowrap" mat-cell>
                                    {{customField.defaultValue}}
                                </td>
                            </ng-container>

                            <ng-container class="py-5" matColumnDef="maxLength">
                                <th *matHeaderCellDef class="text-center" id="maxLength" mat-header-cell> maxLength</th>
                                <td *matCellDef="let customField" class="text-secondary" mat-cell>
                                    {{customField.maxLength}}
                                </td>
                            </ng-container>

                            <ng-container class="py-5" matColumnDef="minLength">
                                <th *matHeaderCellDef class="text-center" id="minLength" mat-header-cell> minLength</th>
                                <td *matCellDef="let customField" class="text-secondary" mat-cell>
                                    {{customField.minLength}}
                                </td>
                            </ng-container>

                            <ng-container class="py-5" matColumnDef="action" stickyEnd>
                                <th *matHeaderCellDef class="text-center" id="action" mat-header-cell>Actions</th>
                                <td *matCellDef="let customField" mat-cell class="text-center">
                                    @if (customField.offerId) {
                                        <a (click)="deleteCustomField(customField)" class="clickable mt-2 px-2">
                                            <i class="fas fa-trash-alt"></i>
                                        </a>
                                    }
                                </td>
                            </ng-container>

                            <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                            <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                        </table>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    }
</div>

<!--Templates-->
<ng-template #isLoadingTemp>
    <div class="text-center my-5 fs-2"><em class="fas fa-spinner fa-spin"></em></div>
</ng-template>
