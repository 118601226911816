import {Component} from '@angular/core';
import {
    Budget,
    DateService,
    HierarchicalLevel,
    MsServicesGiftService,
    Operation
} from '@isifid/core';
import {GiftService} from '../../../../shared/services/gift.service';
import {OperationsService} from '../../../../shared/services/operations.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
    selector: 'app-budgets-manage',
    templateUrl: './manage.component.html'
})
export class BudgetsManageComponent {
    budget: Budget;
    levels: HierarchicalLevel[];
    form: FormGroup;
    operations: Array<Operation>;
    selectedOperation: Operation;
    amountTotalInEur: number;

    constructor(
        public readonly giftService: GiftService,
        private readonly dateService: DateService,
        private readonly formBuilder: FormBuilder,
        private readonly msServicesGiftService: MsServicesGiftService,
        private readonly operationsService: OperationsService,
        private readonly _snackBar: MatSnackBar
    ) {
        // Filter out Diplomas
        this.operations = this.operationsService.getOperations(false, false)
            .filter(o => o.operationType.name !== 'DIPLOMAS' && o.operationType.name !== 'INTERNAL');
        this.levels = this.giftService.hierarchicalLevels.filter(l => l.position > 0 && l.role !== 'GIFT_HQ');
        this.init();
    }

    init() {
        const urlParams = new URLSearchParams(window.location.search);
        const id = urlParams.get('id');
        if (id) {
            this.msServicesGiftService.getBudget(id).subscribe({
                next: budget => {
                    this.budget = budget;
                    this.selectedOperation = this.operationsService.getOperations().find(o => o.id === this.budget.operationId);
                    this.amountTotalInEur = this.budget.amountTotal * (Number(this.selectedOperation?.chargeRate ?? 40) / 100);
                    this.initForm(id);
                }
            });
        } else {
            this.budget = new Budget();
            this.budget.status = 'draft';
            this.budget.attributionMethod = 'auto';
            this.budget.levelId = this.levels[0].id;
            this.initForm();
        }
    }

    submit() {
        if (this.form.invalid || this.form.pristine) return;

        if (this.budget.id) this.updateBudget();
        else this.createBudget();
    }

    updateBudget() {
        if (this.form.invalid || this.form.pristine) return;

        // Format dates
        Object.assign(this.budget, this.form.getRawValue());
        this.budget.startAt = this.dateService.parseDate(this.convertDateTime(this.form.get('startAt').value));
        this.budget.endAt = this.dateService.parseDate(this.convertDateTime(this.form.get('endAt').value));

        this.msServicesGiftService.updateBudget(this.budget.id, this.budget).subscribe({
            next: (budget) => this.budget = budget,
            error: () => console.error('Error while updating budget : ', this.budget),
            complete: () => this._snackBar.open('Budget mis à jour', 'OK')
        });
    }

    createBudget() {
        Object.assign(this.budget, this.form.getRawValue());
        // Convert operationId to number
        this.budget.operationId = Number(this.budget.operationId);
        // Format dates
        this.budget.startAt = this.dateService.parseDate(this.convertDateTime(this.budget.startAt));
        this.budget.endAt = this.dateService.parseDate(this.convertDateTime(this.budget.endAt));
        // Set clientId
        this.budget.clientId = this.giftService.client.id;
        this.msServicesGiftService.createBudget(this.budget).subscribe({
            next: (budget) => this.budget = budget,
            error: () => console.error('Error while creating budget : ', this.budget),
            complete: () => {
                this.form.disable();
                this._snackBar.open('Budget créé', 'OK');
            }
        });
    }

    private initForm(id?: string): void {
        this.form = this.formBuilder.group({
            operationId: [this.budget.operationId, Validators.required],
            amountTotal: [this.budget.amountTotal, Validators.required],
            amountPerUser: [this.budget.amountPerUser, Validators.required],
            startAt: [this.dateService.computeDate(this.budget.startAt || new Date(), 'yyyy-MM-dd'), Validators.required],
            endAt: [this.dateService.computeDate(this.budget.endAt || new Date(), 'yyyy-MM-dd'), Validators.required],
            status: [this.budget.status, Validators.required],
            levelId: this.budget.levelId,
            attributionMethod: this.budget.attributionMethod
        });

        // Disable all fields except status and endAt if budget is active
        if (id && this.budget.status === 'active') {
            this.form.controls.operationId.disable();
            this.form.controls.amountTotal.disable();
            this.form.controls.amountPerUser.disable();
            this.form.controls.startAt.disable();
            this.form.controls.levelId.disable();
            this.form.controls.attributionMethod.disable();
        }
    }

    updateAmountInEuro() {
        this.amountTotalInEur = this.form.get('amountTotal').value * (Number(this.selectedOperation?.chargeRate ?? 40) / 100);
    }

    private convertDateTime(value: string): string {
        const date = new Date(value);
        date.setMinutes(date.getMinutes() - date.getTimezoneOffset());
        return date as unknown as string;
    }
}
