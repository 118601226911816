<div class="container-fluid m-0 py-3 py-md-5">

    <div class="my-5">
        <h1>Importation d'entités</h1>
    </div>

    @if (loading) {
        <div class="text-center py-5">
            <em [style.font-size]="'30px'" aria-hidden="true" class="fas fa-spinner fa-spin"></em>
        </div>
    } @else {
        <div class="row my-3">
            <div class="col">
                <button class="btn btn-black me-3" routerLink="/admin/network/branches" type="button">
                    Retour à la liste des entités
                </button>
                @if (!fileLoaded) {
                    <button (click)="downloadModel()" class="btn btn-validate me-3" type="button">
                        Télécharger le modèle de fichier
                    </button>
                    <button (click)="fileInput.click()"
                            [disabled]="fileLoaded || saveSuccessful"
                            class="btn btn-validate"
                            type="button">Charger un fichier excel
                    </button>
                }
                <label class="d-none" for="file">Fichier :</label>
                <input #fileInput (change)="onFileChange($event)" hidden id="file" type="file">
            </div>
            @if (fileLoaded) {
                <div class="col text-end">
                    <div>
                        <button (click)="reset()" class="btn btn-black me-3" type="button">Abandonner les changements
                        </button>
                        <button (click)="save()" [disabled]="hasFileErrors() || !this.levelId" class="btn btn-validate"
                                type="button">
                            Valider les changements
                        </button>
                    </div>
                    <div class="my-3">
                        <mat-checkbox [(ngModel)]="displayAllColumns" [ngModelOptions]="{standalone: true}">
                            Afficher les adresses et contacts
                        </mat-checkbox>
                    </div>
                    <div class="d-flex my-3">
                        <span class="ms-auto">Échelon :</span>
                        <select (change)="setLevel($event.target['value'])" class="form-select w-50 ms-5">
                            @for (hierarchicalLevel of hierarchicalLevels; track hierarchicalLevel.id) {
                                <option [value]="hierarchicalLevel.id">
                                    {{hierarchicalLevel.entity}}
                                </option>
                            }
                        </select>
                    </div>
                </div>
            }
        </div>

        @if (loadingFile || savingFile) {
            <div class="text-center py-5">
                <em [style.font-size]="'30px'" aria-hidden="true" class="fas fa-spinner fa-spin"></em>
            </div>
        }
        @if (fileLoaded) {
            <div class="text-center">
                <h2>Prévisualisation des changements</h2>
            </div>
            <div  class="text-center">
                <h3>Vous pouvez corriger les éventuelles erreurs directement dans le tableau</h3>
            </div>
        }
        @if (error) {
            <div [style.color]="colorRed" class="pb-5 text-center">
                <h3>{{ error }}</h3>
            </div>
        }
        @if (saveSuccessful) {
            <div class="pb-5 text-center">
                <h3 [style.color]="colorGreen">La sauvegarde des entités a été effectuée avec succès !</h3>
            </div>
        }

        @if (fileLoaded) {
            <table [dataSource]="dataSource" aria-describedby="monitoring" class="my-5 w-100"
                   mat-table matSort>

                <ng-container matColumnDef="Code entité">
                    <th *matHeaderCellDef id="externalId" mat-header-cell mat-sort-header>Code entité</th>
                    <td *matCellDef="let fileBranch" class="ps-2 pe-1 text-secondary" mat-cell>
                        <input (change)="checkFileBranches()" [(ngModel)]="fileBranch.branch.externalId"
                               class="form-control"
                               type="text">
                    </td>
                </ng-container>

                <ng-container matColumnDef="Nom entité">
                    <th *matHeaderCellDef id="name" mat-header-cell mat-sort-header>Nom entité</th>
                    <td *matCellDef="let fileBranch" class="pe-1 text-secondary" mat-cell>
                        <input (change)="checkFileBranches()" [(ngModel)]="fileBranch.branch.name" class="form-control"
                               type="text">
                    </td>
                </ng-container>

                <ng-container matColumnDef="Code entité supérieure">
                    <th *matHeaderCellDef id="parentBranchExternalId" mat-header-cell mat-sort-header>
                        Code entité responsable
                    </th>
                    <td *matCellDef="let fileBranch" class="pe-1 text-secondary" mat-cell>
                        <input (change)="checkFileBranches()" [(ngModel)]="fileBranch.branch.parentBranchExternalId"
                               class="form-control"
                               type="text">
                    </td>
                </ng-container>

                <ng-container matColumnDef="Adresse 1">
                    <th *matHeaderCellDef id="address1" mat-header-cell mat-sort-header>Adresse 1</th>
                    <td *matCellDef="let fileBranch" class="pe-1 text-secondary" mat-cell>
                        <input (change)="checkFileBranches()" [(ngModel)]="fileBranch.branch.address1" class="form-control"
                               type="text">
                    </td>
                </ng-container>

                <ng-container matColumnDef="Adresse 2">
                    <th *matHeaderCellDef id="address2" mat-header-cell mat-sort-header>Adresse 2</th>
                    <td *matCellDef="let fileBranch" class="pe-1 text-secondary" mat-cell>
                        <input (change)="checkFileBranches()" [(ngModel)]="fileBranch.branch.address2" class="form-control"
                               type="text">
                    </td>
                </ng-container>

                <ng-container matColumnDef="Code postal">
                    <th *matHeaderCellDef id="zipCode" mat-header-cell mat-sort-header>Code postal</th>
                    <td *matCellDef="let fileBranch" class="pe-1 text-secondary" mat-cell>
                        <input (change)="checkFileBranches()" [(ngModel)]="fileBranch.branch.zipCode" class="form-control"
                               type="text">
                    </td>
                </ng-container>

                <ng-container matColumnDef="Ville">
                    <th *matHeaderCellDef id="city" mat-header-cell mat-sort-header>Ville</th>
                    <td *matCellDef="let fileBranch" class="pe-1 text-secondary" mat-cell>
                        <input (change)="checkFileBranches()" [(ngModel)]="fileBranch.branch.city" class="form-control"
                               type="text">
                    </td>
                </ng-container>

                <ng-container matColumnDef="Email">
                    <th *matHeaderCellDef id="email" mat-header-cell mat-sort-header>Email</th>
                    <td *matCellDef="let fileBranch" class="pe-1 text-secondary" mat-cell>
                        <input (change)="checkFileBranches()" [(ngModel)]="fileBranch.branch.email" class="form-control"
                               type="text">
                    </td>
                </ng-container>

                <ng-container matColumnDef="Téléphone">
                    <th *matHeaderCellDef id="phone" mat-header-cell mat-sort-header>Téléphone</th>
                    <td *matCellDef="let fileBranch" class="pe-1 text-secondary" mat-cell>
                        <input (change)="checkFileBranches()" [(ngModel)]="fileBranch.branch.phone" class="form-control"
                               type="text">
                    </td>
                </ng-container>

                <ng-container matColumnDef="Statut">
                    <th *matHeaderCellDef id="status" mat-header-cell mat-sort-header>Statut</th>
                    <td *matCellDef="let fileBranch" class="pe-1 text-secondary" mat-cell>
                        <select (change)="checkFileBranches()" [(ngModel)]="fileBranch.branch.status" class="form-select">
                            <option value="">Aucun</option>
                            <option value="active">Active</option>
                            <option value="merged">Fusionnée</option>
                            <option value="deleted">Supprimée</option>
                        </select>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Infos">
                    <th *matHeaderCellDef [style.width]="'13%'" id="infos" mat-header-cell mat-sort-header>Infos</th>
                    <td *matCellDef="let fileBranch" [style.color]="fileBranch.error ? colorRed : colorGreen" mat-cell>
                        {{fileBranch.error ? fileBranch.error : fileBranch.update ? 'OK Mise à jour' : 'OK Création'}}
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="displayAllColumns ? displayedColumns : displayedSimpleColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayAllColumns ? displayedColumns : displayedSimpleColumns;" mat-row></tr>
            </table>
        }
    }
</div>
