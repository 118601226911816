<div class="container-fluid mt-4 py-3 py-md-5">

    <div class="row justify-content-between align-items-end">
        <div class="col-md-6 col-lg-auto">
            <p>Entités sans échelon : {{notRankedBranches.length}}</p>
            <p>Entités isolées : {{isolatedBranches.length}}</p>
        </div>
        <div class="col-md-6 col-lg-4 mt-4 mt-md-0">
            <mat-form-field appearance="fill">
                <mat-label>Filter</mat-label>
                <mat-select [formControl]="filterControl">
                    <mat-option value="notRankedBranch">Entités sans échelon</mat-option>
                    <mat-option value="isolatedBranch">Entités isolées</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div class="responsive-table">
        <div class="responsive-table-body">
            <table [dataSource]="dataSource" aria-describedby="monitoring" class="mb-1"
                   mat-table matSort>
                <ng-container class="py-5" matColumnDef="externalId">
                    <th *matHeaderCellDef id="externalId" mat-header-cell mat-sort-header>Code entité</th>
                    <td *matCellDef="let branch" mat-cell>
                        {{branch.externalId}}
                    </td>
                </ng-container>

                <ng-container class="py-5" matColumnDef="name">
                    <th *matHeaderCellDef id="name" mat-header-cell mat-sort-header>Nom</th>
                    <td *matCellDef="let branch" mat-cell>
                        {{branch.name}}
                    </td>
                </ng-container>

                <ng-container class="py-5" matColumnDef="parentBranchExternalId">
                    <th *matHeaderCellDef id="parentBranchExternalId" mat-header-cell mat-sort-header>Code entité supérieure</th>
                    <td *matCellDef="let branch" mat-cell>
                        {{branch.parentBranchExternalId}}
                    </td>
                </ng-container>

                <ng-container class="py-5" matColumnDef="levelId">
                    <th *matHeaderCellDef id="levelId" mat-header-cell mat-sort-header>Échelon</th>
                    <td *matCellDef="let branch" mat-cell>
                        {{getLevelEntity(branch.levelId)}}
                    </td>
                </ng-container>

                <ng-container class="py-5" matColumnDef="parentNotFound">
                    <th *matHeaderCellDef id="parentNotFound" mat-header-cell mat-sort-header>Ascendant non trouvé</th>
                    <td *matCellDef="let branch" mat-cell>
                        {{branch.parentNotFound}}
                    </td>
                </ng-container>

                <ng-container class="py-5" matColumnDef="parentMissing">
                    <th *matHeaderCellDef id="parentMissing" mat-header-cell mat-sort-header>Ascendant manquant</th>
                    <td *matCellDef="let branch" mat-cell>
                        {{branch.parentMissing}}
                    </td>
                </ng-container>

                <ng-container class="py-5" matColumnDef="childMissing">
                    <th *matHeaderCellDef id="childMissing" mat-header-cell mat-sort-header>Descendant absent</th>
                    <td *matCellDef="let branch" mat-cell>
                        {{branch.childMissing}}
                    </td>
                </ng-container>

                <tr *matHeaderRowDef="displayedColumns" mat-header-row></tr>
                <tr *matRowDef="let row; columns: displayedColumns;" mat-row></tr>
                <tr *matNoDataRow>
                    <td [colSpan]="displayedColumns.length" class="text-center py-4">Aucune Entité</td>
                </tr>
            </table>
        </div>
        <mat-paginator
                [hidden]="dataSource?.filteredData?.length < 10"
                [pageSizeOptions]="[10, 25, 50, 100]"
                aria-label="Sélectionnez la page des succursales"
                showFirstLastButtons />
    </div>
</div>
